import Prism from 'prismjs';
import 'prismjs/components/prism-json';
import '../../shared/css/code-highlighting.css';
import Glide from '@glidejs/glide';
import '@glidejs/glide/dist/css/glide.core.min.css';
import '@glidejs/glide/dist/css/glide.theme.min.css';

import DOMPurify from 'dompurify';

import { marked } from "marked";

import { humaneDate } from "../../shared/js/humane_date.mjs";
import { ConfirmationDialog } from './confirmation_dialog.js';
import { PowerInput } from './power.js';
import { initChatCard } from './chat_card.js';

import { Wispy } from './wispy.js';

import { display_login_panel } from './login.js';
import { display_membership_panel } from './membership.js';

import { getStepIcon, getStepTitle } from './capability_utils.js';

import '../css/report_dialog.css';

/******************************************************************************/
/***************************** Card UI Support ********************************/
/******************************************************************************/

let gPowerSelector = null;
let gPowerCards = [];

export function initPowerCard(session, power, powerInput) {
    displayPowerSelector(session);

    let existingCard = gPowerCards.find((card) => card.power.powerID === power.powerID);
    if (existingCard) {
        existingCard.powerInput = powerInput;
        existingCard.display_card();
    }
}

export async function closePowerCard(power) {
    if (!power || !power.powerID) {
        console.error('Invalid power object');
        return;
    }

    if (!gPowerCards) {
        console.error('gPowerCards is not initialized');
        return;
    }

    let card = gPowerCards.find((card) => card.power.powerID === power.powerID);
    if (!card) {
        console.error(`No card found with powerID: ${power.powerID}`);
        return;
    }

    if (typeof card.set_card_mode !== 'function') {
        console.error('set_card_mode is not a function');
        return;
    }

    /*
    if (power.powerID === "v2-chat") {
        let chatHistoryElement = document.getElementById("chat-history");
        let result = chatHistoryElement ? chatHistoryElement.innerHTML : null;
        if (result && result.trim() !== "") {
            await card.session.saveChatPowerInvocation(card.power, result);
            card.display_power_history();
        }
        if (card.chatElement) {
            document.body.appendChild(card.chatElement);
        }
    } */

    if (power.powerID === "v2-chat") {
        if (card.chatElement) {
            document.body.appendChild(card.chatElement);
            //card.cardElement = null;
            card.chatElement = document.getElementById("power-chat");
        }
        // Continue to close the card
    }

    console.log("closing card and refreshing history");
    await card.update_power_history();
    card.set_card_mode("power");
}

export function displayPowerSelector(session) {
    if (!gPowerSelector) {

        let cardContainer = document.getElementById("power-cards");
        let powers = session.getAllPowers();

        powers.forEach((power) => {
            let powerCard = new PowerCard(session, power, null);
            let slide = document.createElement("li");
            slide.classList.add("glide__slide");
            let container = document.createElement("div");
            container.classList.add(powerCard.power.powerID);
            container.id = "power-card-container";
            container.dataset.id = powerCard.power.powerID;
            container.appendChild(powerCard.cardElement);
            slide.appendChild(container);
            cardContainer.appendChild(slide);
            gPowerCards.push(powerCard);
        });

        function initializeSlider() {

            // Get the current index of the slider
            const currentIndex = gPowerSelector ? gPowerSelector.index : 0;

            // If a slider already exists, destroy it
            if (gPowerSelector) gPowerSelector.destroy();

            // Calculate the number of slides to show based on container and slide widths
            const containerWidth = document.querySelector('.glide').clientWidth;
            const slideWidth = document.querySelector('.front').clientWidth;
            const perView = containerWidth / slideWidth;

            // Calculate the gap as a percentage of the slide width
            const gap = slideWidth * 0.025; // 2.5% of the slide width

            // Create a new slider with the calculated number of slides
            gPowerSelector = new Glide('.glide', {
                type: 'carousel',
                focusAt: 'center',
                startAt: currentIndex,
                gap: gap,
                perView: perView,
                animationDuration: 200,
                animationTimingFunc: 'ease-in-out',
            });

            // Update the card view when the selector changes
            gPowerSelector.on('run', async () => {
                const powerCard = gPowerCards[gPowerSelector.index];
                let url = `/power/${powerCard.power.powerID}`;
                if (powerCard.powerInput) {
                    const params = powerCard.powerInput.toURLParams();
                    const paramStr = params.toString();
                    if (paramStr.length > 0) {
                        url += `?${paramStr}`;
                    }
                }
                history.replaceState({}, null, url);
                await powerCard.update_power_history();


                //powerCard.set_card_mode("power");
            });

            // If previous card was flipped, flip it back before moving to next card
            gPowerSelector.on('run.before', function () {
                let powerCard = gPowerCards[gPowerSelector.index];
                if (powerCard) {
                    if (powerCard.cardElement.classList.contains('flipped')) {
                        powerCard.cardElement.classList.remove('flipped');
                    }
                }
            });

            // If unfocused cards are clicked then focus on them
            gPowerSelector.on('mount.after', function () {
                const glideTrack = document.querySelector('.glide__track');
                glideTrack.addEventListener("click", (e) => {
                    const cardElement = e.target.closest('#power-card-container');
                    if (cardElement) {
                        const cardId = cardElement.dataset.id;
                        const index = gPowerCards.findIndex((c) => c.power.powerID === cardId);
                        if (index !== -1) {
                            const card = gPowerCards[index];
                            if (gPowerSelector.index !== index) {
                                if (index === 0 && gPowerSelector.index === gPowerCards.length - 1) {
                                    gPowerSelector.go('>');
                                } else if (index === gPowerCards.length - 1 && gPowerSelector.index === 0) {
                                    gPowerSelector.go('<');
                                } else {
                                    gPowerSelector.go("=" + index);
                                }
                            }
                        }
                    }
                });
            });

            // Mount the new slider
            gPowerSelector.mount();


        }

        // Update the slider when the window is resized
        window.addEventListener('resize', initializeSlider);

        // Initialize the slider when the page loads
        initializeSlider();

        // Top-level navigation controls for power selctor
        const glideArrowLeft = document.querySelector('.glide__arrow--left')
        glideArrowLeft.addEventListener('click', function () {
            gPowerSelector.go('<');
        })
        const glideArrowRight = document.querySelector('.glide__arrow--right')
        glideArrowRight.addEventListener('click', function () {
            gPowerSelector.go('>');
        })
    }
}

export function selectPowerCard() {

}
export function setPowerCardRouter(router) {
}

class PowerCard {
    constructor(session, power, powerInput) {
        this.session = session;
        this.power = power;
        this.powerInput = powerInput;
        this.cardElement = document.getElementById("power-card").cloneNode(true);
        this.chatElement = document.getElementById("power-chat"); // XXX
        this.formElement = document.getElementById("power-form").cloneNode(true);
        this.resultElement = document.getElementById("power-result").cloneNode(true);
        this.errorElement = document.getElementById("power-error").cloneNode(true);
        this.progressElement = document.getElementById("power-progress").cloneNode(true);
        this.historyElement = null;
        this.invocationElement = null;
        this.fullScreenResultElement = null;
        this.progressWispy = null;
        this.progressInterval = null;
        this.mode = null;

        this.cardElement.appendChild(this.formElement);
        this.cardElement.appendChild(this.resultElement);
        this.cardElement.appendChild(this.errorElement);
        this.cardElement.appendChild(this.progressElement);

        console.log("[power] initializing power card", this.power, "inputs=", this.powerInput);

        this.cardElement.querySelector("#power-name").innerHTML = this.power.name;
        this.cardElement.querySelector("#power-description").innerHTML = this.power.description;
        this.cardElement.querySelector("#power-back-name").innerHTML = this.power.name;
        this.cardElement.style.display = "block";
        this.cardElement.id = "power-card";

        // Display capability icons preview on front of card
        let powerSteps = this.cardElement.querySelector("#power-steps-preview");
        powerSteps.innerHTML = "";
        this.power.steps.forEach((step, index) => {
            let stepContent = `<img src="${getStepIcon(step)}" class="power-step-icon" title="${getStepTitle(step)}" data-model-info="${getStepTitle(step)}">`;
            powerSteps.innerHTML += stepContent;
        });

        // Create a single info box
        let infoBox = document.createElement("div");
        infoBox.style.display = "none";
        infoBox.classList.add("step-capability-info");
        powerSteps.appendChild(infoBox);

        // Add event listener to powerSteps to handle clicks on img elements
        powerSteps.addEventListener("click", (event) => {
            console.log(event.target);
            if (event.target.tagName === "IMG") {
                if (infoBox.style.display === "block" && infoBox.textContent === event.target.dataset.modelInfo) {
                    infoBox.style.display = "none";
                } else {
                    infoBox.textContent = event.target.dataset.modelInfo + " AI model";
                    infoBox.style.display = "block";
                }
                setTimeout(() => {
                    infoBox.style.display = "none";
                }, 2000);
            }
        });

        let stepContents = '';
        this.power.steps.forEach((step, index) => {
            let stepContent = `
            <div class="power-step">
                <div class="power-step-number">Step ${index + 1} of ${this.power.steps.length}</div>
                <div class="power-step-label icon-white">
                    <img src="${getStepIcon(step)}" class="power-step-icon" title="${getStepTitle(step)}">
                    <div class="power-step-title">${getStepTitle(step)}</div>
                </div>
                <div class="power-step-prompt-template">
                    ${step.prompt ? `<pre><code class="language-json">${DOMPurify.sanitize(JSON.stringify(step, null, 2))}</code></pre>` : "No additional prompt defined for this step."}
                </div>
            </div>
        `;
            stepContents += stepContent;
        });
        let powerDetailedSteps = this.cardElement.querySelector("#power-steps-detailed");
        powerDetailedSteps.innerHTML = stepContents;
        Prism.highlightAll();

        this.formElement.querySelector("#power-form-name").innerHTML = this.power.name;
        let container = this.formElement.querySelector("#power-form-div");
        container.innerHTML = "";

        // XXX Temporarily add per-power specific CSS classes to resize text areas and custom
        // style the form for each power
        this.formElement.classList.add(this.power.powerID);
        this.resultElement.classList.add(this.power.powerID);

        // Add dyanmic Wispy to progress meter
        this.progressWispy = new Wispy('30%');
        const progress_wispy_container = this.progressElement.querySelector("#power-progress-wispy");
        progress_wispy_container.appendChild(this.progressWispy.element);

        //  Card event listeners
        this.cardElement.querySelector("#power-card-invoke-button").addEventListener("click", (e) => {
            e.preventDefault();
            const powerID = this.power.powerID;
            // If it's not the selected card, first select the card
            const index = gPowerCards.findIndex((card) => card.power.powerID === powerID);
            if (gPowerSelector.index !== index) {
                gPowerSelector.go("=" + index);
            }
            setTimeout(() => {
                this.display_power_form();
            }, 100);
        });

        // Be ready for errors:
        this.errorElement.querySelector("#power-error-proceed-button").addEventListener("click", (e) => {
            e.preventDefault();
            this.errorElement.close();
            this.display_power_form();
        });
        // Info button flips the card over and display other side
        this.cardElement.querySelectorAll('.card-toggle').forEach((cardToggle) => {
            cardToggle.addEventListener('click', () => {
                this.cardElement.classList.toggle('flipped');
            });
        });
        // Share button shares the link to the current power
        this.cardElement.querySelector("#power-card-share-power-button").addEventListener("click", (e) => {
            e.preventDefault();
            this.share_power();
        });

        this.formElement.querySelector("#power-form-close-button").addEventListener("click", (e) => {
            e.preventDefault();
            this.powerInput = null;
            closePowerCard(this.power);
        });
        this.formElement.querySelector("#power-form-back-arrow").addEventListener("click", (e) => {
            e.preventDefault();
            this.powerInput = null;
            closePowerCard(this.power);
        });
        this.formElement.querySelector("#power-form-invoke-button").addEventListener("click", (e) => {
            e.preventDefault();
            this.form_invoke_power();
        });
        this.resultElement.querySelector("#power-result-close-button").addEventListener("click", (e) => {
            e.preventDefault();
            this.powerInput = null;
            closePowerCard(this.power);
        });
        this.resultElement.querySelector("#power-result-back-arrow").addEventListener("click", (e) => {
            e.preventDefault();
            this.display_power_form();
        });
        this.resultElement.querySelector("#power-result-reinvoke-button").addEventListener("click", (e) => {
            e.preventDefault();
            this.result = null;
            this.display_power_form({ "resetSeed": true });
        });
        this.resultElement.querySelector("#power-card-clipboard-result-button").addEventListener("click", (e) => {
            e.preventDefault();
            this.copy_results_to_clipboard();
        });
        this.resultElement.querySelector("#power-card-fullscreen-result-button").addEventListener("click", (e) => {
            e.preventDefault();
            let divElement = this.fullScreenResultElement;

            if (divElement.requestFullscreen) {
                divElement.requestFullscreen();
            } else if (divElement.mozRequestFullScreen) { // Firefox
                divElement.mozRequestFullScreen();
            } else if (divElement.webkitRequestFullscreen) { // Chrome, Safari and Opera
                divElement.webkitRequestFullscreen();
            } else if (divElement.msRequestFullscreen) { // IE/Edge
                divElement.msRequestFullscreen();
            }
        });
        this.resultElement.querySelector("#power-card-download-result-button").addEventListener("click", (e) => {
            e.preventDefault();
            let index = Date.now();
            let a = document.createElement("a");
            a.href = this.result?.output?.data?.output_original;
            a.download = `power-result-${index}.png`;
            if (a.href) {
                a.click();
            } else {
                console.error("Unable to generate download href");
            }
        });
        this.resultElement.querySelector("#power-card-share-result-button").addEventListener("click", (e) => {
            e.preventDefault();
            this.share_results();
        });

        this.resultElement.querySelector("#power-card-report-content-button").addEventListener("click", (e) => {
            e.stopPropagation();
            e.preventDefault();

            // Store the currently focused element (to prevent a keyboard bounce on mobile when dialog is closed)
            let focusedElement = document.activeElement;

            let dialog = this.resultElement.querySelector('#report-dialog-instance') ?? document.getElementById('report-dialog').cloneNode(true);
            dialog.id = 'report-dialog-instance';
            this.resultElement.querySelector('#power-result-actions').appendChild(dialog);

            function openReportDialog() {
                dialog.style.display = 'flex';
                dialog.style.opacity = 1;
                dialog.style.transform = 'scale(1)';
                dialog.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            }

            function closeReportDialog() {
                dialog.style.display = 'none';
                dialog.style.opacity = 0;
                dialog.style.transform = 'scale(0.8)';
            }

            // Position within the viewport
            dialog.style.bottom = '80px';
            dialog.style.top = 'auto';
            dialog.style.left = '24px';
            dialog.style.right = 'auto';

            openReportDialog();

            setTimeout(() => {
                dialog.querySelector('.details').focus();
            }, 0); // Delay to ensure the dialog is fully rendered before focusing

            // Report Dialog Cancel Handler
            const dialogCloseListener = (e) => {
                closeReportDialog();
                focusedElement.focus();
                session.reportInfo("Report cancelled");
                dialog.querySelector('.report-dialog-close').removeEventListener('click', dialogCloseListener);
                dialog.querySelector('.report-dialog-cancel').removeEventListener('click', dialogCloseListener);
                dialog.querySelector('.report-dialog-submit').removeEventListener('click', dialogSubmitListener);
            };
            dialog.querySelector('.report-dialog-close').addEventListener('click', dialogCloseListener);
            dialog.querySelector('.report-dialog-cancel').addEventListener('click', dialogCloseListener);

            // Report Dialog Submit Handler
            const dialogSubmitListener = async (e) => {
                e.stopPropagation();

                let details = DOMPurify.sanitize(dialog.querySelector('.details').value) || '';
                let feedback = {
                    details: details,
                    content: this.result,
                };
                console.log(feedback);
                try {
                    await session.submitFeedback(feedback);
                    session.reportInfo("Report submitted");
                } catch (error) {
                    session.reportError(error);
                }
                closeReportDialog();
                focusedElement.focus(); // Restore the previously focused element
                dialog.querySelector('.report-dialog-submit').removeEventListener('click', dialogSubmitListener);
                dialog.querySelector('.report-dialog-close').removeEventListener('click', dialogCloseListener);
                dialog.querySelector('.report-dialog-cancel').removeEventListener('click', dialogCloseListener);
            };
            dialog.querySelector('.report-dialog-submit').addEventListener('click', dialogSubmitListener);

            const outsideClickListener = (e) => {
                if (dialog && dialog.style.display === 'flex' && !dialog.contains(e.target)) {
                    dialog.remove();
                    dialog = null;
                }
                document.removeEventListener('click', outsideClickListener);
            };
            document.addEventListener('click', outsideClickListener);
        });

    }

    set_card_mode(mode) {
        let container = document.getElementById("power-invocation-container");
        if (!container) {
            let container = document.createElement("div");
            container.id = "power-invocation-container";
            document.body.appendChild(container);
        }

        console.log("[power] setting card mode to ", mode, this.power.powerID, this.power.name, this.powerInput);

        switch (mode) {
            case "chat":
                container.innerHTML = "";
                container.appendChild(this.chatElement);
                this.chatElement.classList.remove("hidden");
                container.style.display = "flex";
                gPowerSelector.disable();
                break;
            case "form":
                container.innerHTML = "";
                container.appendChild(this.formElement);
                this.formElement.classList.remove("hidden");
                container.style.display = "flex";
                gPowerSelector.disable();
                break;
            case "progress":
                container.innerHTML = "";
                container.appendChild(this.progressElement);
                this.progressElement.classList.remove("hidden");
                container.style.display = "flex";
                gPowerSelector.disable();
                break;
            case "result":
                container.innerHTML = "";
                container.appendChild(this.resultElement);
                this.resultElement.classList.remove("hidden");
                container.style.display = "flex";
                gPowerSelector.disable();
                break;
            case "error":
                this.errorElement.showModal();
                gPowerSelector.disable();
                break;
            case "power":
                container.innerHTML = "";
                container.style.display = "none";
                gPowerSelector.enable();
                break;
            default:
                console.error("Invalid mode: ", mode);
                this.session.reportError("Invalid mode: " + mode);
                break;
        }

    }

    display_card() {
        console.log("[power] displaying power card", this.power);
        let index = gPowerCards.findIndex((card) => card.power.powerID === this.power.powerID);
        gPowerSelector.go("=" + index);
    }

    async display_power_form(resetSeed = false) {
        console.log("[power] displaying power form", this.power, this.powerInput);
        const isAuthenticated = await this.session.isAuthenticated();
        if (!isAuthenticated) {
            this.hide_progress();
            display_login_panel();
            return;
        }
        console.log("[power] initiating power form", this.power, "inputs=", this.powerInput);
        if (this.power.powerID === "v2-chat") {
            await initChatCard(this.session, this.power, null);
            this.set_card_mode("chat");
            return;
        }

        if (!this.powerInput) this.powerInput = new PowerInput(this.power);
        if (resetSeed) this.powerInput.resetSeed();
        this.display_form();
    }

    display_form() {

        console.log("[power] displaying power form", this.power, this.powerInput);
        try {
            let container = this.formElement.querySelector("#power-form-div");
            container.innerHTML = "";

            if (this.power.usageInstructions) {
                let instructions = document.createElement("div");
                instructions.classList.add("power-form-instructions");
                let instructionsText = document.createElement("p");
                instructionsText.innerText = this.power.usageInstructions;
                instructions.appendChild(instructionsText);
                container.appendChild(instructions);
            }
            this.power.createUserInputForm(this.session, container, this.powerInput);

            this.set_card_mode("form");

            const initialFocusKey = this.power?.steps[0]?.initial_focus;
            if (initialFocusKey) {
                console.log("[power] setting initial focus to ", initialFocusKey);
                const focusElement = this.formElement.querySelector(`#modal_${initialFocusKey}`);
                if (focusElement) {
                    focusElement.focus();
                } else {
                    console.log(`[power] Unable to find element with id: modal_${initialFocusKey}`);
                }
            }
        } catch (err) {
            console.error(err);
            this.session.reportError(err.message);
        }
    }

    set_visible_actions(actionKeys) {
        let ActionKeys = {
            "clipboard": "clipboard",
            "fullscreen": "fullscreen",
            "download": "download",
            "share": "share"
        };
        Object.keys(ActionKeys).forEach((key) => {
            let actionElem = this.resultElement.querySelector(`#power-card-${ActionKeys[key]}-result-button`);
            if (!actionElem) {
                console.error("Missing action element for key: ", key);
            }
            let overrideToHidden = false;

            if (key === "clipboard") {
                // We only support image copy if we have access to ClipboardItem (Chrome >= 76, Safari >= 13.1)
                if (this.fullScreenResultElement && !window.ClipboardItem) {
                    overrideToHidden = true;
                }
            }

            if (key === "fullscreen") {
                if (!this.fullScreenResultElement || !this.fullScreenResultElement.requestFullscreen) {
                    overrideToHidden = true;
                }
            }

            if (key === "share") {
                if (!navigator.canShare) {
                    // The absence of a canShare method indicates that share would fail.
                    overrideToHidden = true;
                }
                /*
                 else if(!navigator.canShare()) {
                    // Park this until the Web Share PR lands and then implement
                    // a data getter to pass into canShare
                    overrideToHidden = true;
                }
                */
            }
            if (!overrideToHidden && actionKeys.includes(key)) {
                actionElem.style.display = "inline-block";
            } else {
                actionElem.style.display = "none";
            }
        });
    }

    form_invoke_power() {
        console.log("[power] validating inputs and invoking ", this.power, this.powerInput);

        // process form inputs
        try {
            this.power.validateUserInputs(this.powerInput.getStepInputs());
        } catch (err) {
            this.session.reportError(err.message);
            console.error("Inputs are invalid");
            console.error(err);
            return;
        }
        let params = this.powerInput.toURLParams();
        let paramStr = params.toString();
        if (paramStr.length > 0) {
            history.pushState({}, null, `/power/${this.power.powerID}?${paramStr}`);
        }

        (async () => {
            try {
                this.display_progress({ title: "Invoking " + this.power.name + " power..." });
                let expectedOutput = this.power.expectedOutputType();
                let streamAccumulator = "";
                let streamReceiver = (data, eof, model) => {
                    if (data) {
                        streamAccumulator += data;
                        if (expectedOutput === "text") {
                            console.log("[power] Updating streaming text response");
                            // If this is the first bit of streamining text, then hide the progress panel
                            if (streamAccumulator === data) this.hide_progress();
                            this.display_result({
                                result: {
                                    output: {
                                        data: {
                                            output: streamAccumulator,
                                            model: model || ""
                                        }
                                    }
                                },
                                isStreaming: true
                            });
                        } else {
                            console.log("[power] Not updating streaming ", expectedOutput, "response with ", streamAccumulator);
                        }
                    }
                }
                let result = await this.session.invokePower(this.power, this.powerInput, streamReceiver);
                console.log("[power] invokePower result=", result);

                // XXXX what about errors?
                if (result) {
                    this.hide_progress();
                    this.display_result({ result: result, isStreaming: false });
                    //await this.update_power_history();
                } else {
                    console.error("No result from invokePower");
                    this.hide_progress();
                    this.display_error("Invocation failed", "The AI model you've selected did not return a result. Please try again.");
                }

            } catch (err) {
                console.error("[form_invoke_power] Error ", err.message);
                this.hide_progress();

                switch (err.message) {
                    case "no_active_subscription":
                    case "no_energy_remaining":
                        display_membership_panel(this.session);
                        break;

                    case "moderation_flagged":
                        this.display_error("Content Moderation", "This content has been flagged as being potentially inappropriate. Please try again.");
                        break;

                    case "internal_error":
                        this.display_error("Internal Server Error", "An internal server error occurred. Please try again later.");
                        break;

                    case "no_token":
                    case "invalid_or_expired_auth_token":
                    case "member_not_verified":
                        await this.session.logout();
                        await this.session.login();
                        break;

                    case "missing_power_id":
                        this.display_error("Invocation Failed", "This power is no longer available.");
                        break;

                    case "member_missing_permissions":
                        this.display_error("Permission Denied", "You do not have permission to access this power.");
                        break;

                    case "missing_prompt":
                    case "invalid_json":
                    case "endpoint_not_found":
                    case "missing_power_id":
                    case "missing_prompt":
                    case "capability_not_found":
                    case "post_only":
                    case "post_and_get_only":
                    case "promptmulti_cannot_be_used_with_prompt":
                    case "event_stream_not_supported_for_v1_powers":
                    default:
                        this.display_error("Invocation Failed", err.message);
                        break;
                }
                // gSession.reportError(err.message);
            }
        })();
    }

    // Power Progress
    randomProgressMessage() {
        const messages = [
            "Casting the algorithmic spell",
            "Calibrating psychic radars",
            "Finding the answer in another dimension",
            "Consulting the oracle",
            "Energizing the quantum flux capacitors",
            "Dusting off ancient scrolls for wisdom",
            "Conjuring the spirit of Ada Lovelace",
            "Charging the electro-magical resonators",
            "Engaging precognitive subroutines",
            "Activating the wisdom of the ancients",
            "Bargaining with the server hamsters",
            "Doing the mathemagics",
            "Waking up the web spiders",
            "Brewing a pot of digital wisdom",
            "Invoking the code spirits",
            "Consulting the binary elders",
            "Initiating warp drive computations",
            "Solving the unsolvable, hold on...",
            "Breaking the laws of physics, just a bit",
            "Scanning the Akashic records",
            "Unfurling the scrolls of ancient code",
            "Peering through the mist of the unknown",
            "Decoding the enigma machine",
            "Cracking the Voynich manuscript",
            "Tuning the cosmic antennae",
            "Running the numbers through the quantum computer",
            "Throwing digital darts at the answer board",
            "Flipping through the Encyclopedia Galactica",
            "Whispering to the electrons",
            "Unraveling the mysteries of the universe",
            "Searching the stars for your answer",
            "Counting infinity... almost there",
            "Poking Schrödinger's cat for insights",
            "Peeking behind the quantum curtain",
            "Channeling the power of the pyramids",
            "Juggling with quarks and leptons",
            "Playing hide and seek with your answer",
            "Bending spacetime to find results",
            "Reading digital palms for answers",
            "Balancing the karmic data",
            "Whispering to the algorithms",
            "Putting the 'fun' in functions",
            "Reticulating digital splines",
            "Brewing a fresh pot of data",
            "Teaching the hamsters to code",
            "Adding secret sauce to the mix",
            "Dusting off the circuit boards",
            "Optimizing the transistor conduits",
            "Unfolding the hyperdimensional array",
            "Channeling the wisdom of the cloud",
            "Breaking out the digital abacus",
            "Shaking the magic 8-bit ball",
            "Powering up the superconductors",
            "Running the digital obstacle course",
            "Checking the voltage on the crystal ball",
            "Priming the pixel pump",
            "Poking the processing pixies",
            "Consulting the coding clover",
            "Cranking up the computation cogs",
            "Feeding the binary beavers",
            "Revving the digital engine",
            "Running the e=mc^2 equation",
            "Doing a quick loop-de-loop in the time-space continuum",
            "Cycling through quantum states",
            "Calculating the path of least resistance",
            "Rolling the digital dice",
            "Checking with the digital oracle",
            "Looking for answers in the digital aether",
            "Communing with the electrons",
            "Crunching some really hard numbers",
            "Honing the silicon crystal",
            "Revving up the render engine",
            "Warming up the data cauldron",
            "Hyping the hyperloop",
            "Flipping the digital pancake",
            "Stirring the data stew",
            "Decoding the cypher of silence",
            "Herding the electronic sheep",
            "Tuning the algorithmic guitar",
            "Solving for X, Y, and Z",
            "Baking a binary cake",
            "Pushing pixels to the limit",
            "Charging the knowledge capacitors",
            "Inflating the cloud",
            "Fine-tuning the digital symphony",
            "Syncing with the silicon rhythm",
            "Doing some high-precision guesswork",
            "Waxing the digital surfboard",
            "Crossing the data streams",
        ];
        let randomIndex = Math.floor(Math.random() * messages.length);
        return messages[randomIndex];
    }
    c
    display_progress(options = {}) {
        console.log("[power] displaying power progress", this.power);
        let title = options.title || "Invoking " + this.power.name + " power...";
        this.progressElement.querySelector("#power-progress-title").innerText = title;
        this.progressElement.querySelector("#power-progress-wispy").classList.add("invoking");
        this.update_progress_status("Sprinkling pixie dust on algorithms");
        this.progressInterval = setInterval(() => {
            this.update_progress_status(this.randomProgressMessage());
        }, 2000);
        this.set_card_mode("progress");
    }
    update_progress_status(status) {
        this.progressElement.querySelector("#power-progress-status").innerText = status;
    }
    hide_progress() {
        this.progressElement.querySelector("#power-progress-wispy").classList.remove("invoking");
        clearInterval(this.progressInterval);
    }

    // Power Error
    display_error(error_title, error_message) {
        console.log("[power] displaying power error", error_message);

        let title_div = this.errorElement.querySelector("#power-error-title");
        if (error_title) {
            title_div.innerText = error_title;
            title_div.style.display = "block";
        } else {
            title_div.innerText = "";
            title_div.style.display = "none";
        }
        this.errorElement.querySelector("#power-error-message").innerText = error_message;
        this.set_card_mode("error"); // this calls showModal
    }

    // Power Result
    display_result({ result, isStreaming = false, isHistory = false }) {
        console.log("[power] displaying power result", this.power, result);

        this.set_card_mode("result");
        this.result = result;

        this.resultElement.querySelector("#power-result-name").textContent = this.power.name;
        this.resultElement.querySelector("#power-result-model").textContent = result?.output?.data?.model || "";

        if (isStreaming) {
            this.resultElement.querySelector("#power-result-progress-spinner").style.display = "flex";
            this.resultElement.querySelector("#power-result-actions").style.display = "none";
        } else {
            if (isHistory && result.timestamp) {
                if (result?.timestamp) {
                    this.resultElement.querySelector("#power-result-history-timestamp").textContent = humaneDate(new Date(result.timestamp));
                } else {
                    this.resultElement.querySelector("#power-result-history-timestamp").textContent = "";
                }
            }
            this.resultElement.querySelector("#power-result-progress-spinner").style.display = "none";
            this.resultElement.querySelector("#power-result-actions").style.display = "flex";
        }

        if (result?.output?.data?.output) {
            if (this.power.expectedOutputType() === "image") {
                let imgElem = document.createElement("img");
                imgElem.classList.add("result-image");

                imgElem.src = result.output.data.output;
                if (result.output.data.output_original) {
                    imgElem.src = result.output.data.output_original;
                }

                // Full screen container
                let needsAppend = false;
                let divElem = null;
                let existing = document.getElementById("result-image-fullscreen-container");
                if (existing) {
                    existing.innerHTML = "";
                    divElem = existing;
                } else {
                    divElem = document.createElement("div");
                    divElem.id = "result-image-fullscreen-container";
                    needsAppend = true;
                }
                let fullscreenImageElem = document.createElement("img");
                fullscreenImageElem.src = imgElem.src;
                fullscreenImageElem.classList.add("result-image-fullscreen");
                divElem.appendChild(fullscreenImageElem);
                let buttonElem = document.createElement("button");
                buttonElem.classList.add("minimize-button");
                let iconElem = document.createElement("i");
                iconElem.classList.add("material-icons");
                iconElem.textContent = "close";
                buttonElem.appendChild(iconElem);
                buttonElem.addEventListener("click", (e) => {
                    document.exitFullscreen();
                });
                divElem.appendChild(buttonElem);
                this.fullScreenResultElement = divElem;
                if (needsAppend) {
                    document.body.appendChild(this.fullScreenResultElement);
                }
                this.fullScreenResultElement.style.display = "none";
                document.addEventListener("fullscreenchange", (e) => {
                    if (document.fullscreenElement === this.fullScreenResultElement) {
                        this.fullScreenResultElement.style.display = "block";
                    } else {
                        this.fullScreenResultElement.style.display = "none";
                    }
                });

                // Image-related actions
                this.resultElement.querySelector("#power-card-fullscreen-result-button").style.display = "inline-block";
                this.resultElement.querySelector("#power-card-download-result-button").style.display = "inline-block";

                this.set_visible_actions(["clipboard", "fullscreen", "download", "share"]);

                let container = document.createElement("div");
                container.prepend(imgElem);
                container.addEventListener("click", (e) => {
                    if (e.target.tagName === "IMG") {
                        this.fullScreenResultElement.requestFullscreen();
                    }
                });

                let resultDiv = this.resultElement.querySelector("#power-result-div");
                resultDiv.innerHTML = "";
                resultDiv.appendChild(container);

            } else {
                let rawText = result?.output?.data?.output || "";
                let powerResultDiv = this.resultElement.querySelector("#power-result-div");

                if (isStreaming) {
                    rawText += '<span class="caret-loading"></span>';
                }

                this.set_visible_actions(["clipboard", "share"]);

                let resultRenderedText = DOMPurify.sanitize(marked.parse(rawText));
                powerResultDiv.innerHTML = resultRenderedText;
            }

            Prism.highlightAll();

            // Display the energy used for this invocation
            if (!isStreaming) {
                let energyUsed = result?.output?.data?.usage?.energy_usage || 0;
                if (typeof energyUsed === 'number') {
                    let formattedEnergyUsed = energyUsed.toLocaleString();
                    this.resultElement.querySelector("#power-result-energy-used").innerHTML = `Energy used for this invocation: <i class="material-icons">bolt</i>${formattedEnergyUsed}`;
                } else {
                    console.error('Energy used is not a number:', energyUsed);
                }
            } else {
                this.resultElement.querySelector("#power-result-energy-used").innerHTML = "";
            }

        } else {
            console.log("[power] Warning: Expected to find output.data.output in result", result);
        }

    }

    async copy_results_to_clipboard() {
        if (!this.result) return null;

        if (this.result?.output?.data?.output) {
            if (this.power.expectedOutputType() === "image") {
                fetch(this.result.output.data.output_original)
                    .then(response => response.blob())
                    .then(blob => {
                        const clipboardItem = new ClipboardItem({ "image/png": blob });
                        navigator.clipboard.write([clipboardItem])
                            .then(() => {
                                console.log("Copied image to clipboard");
                                this.session.reportInfo("Copied image to clipboard");
                            }).catch(e => {
                                console.error("Error copying image: ", e);
                                this.session.reportInfo("Error copying image to clipboard");
                            });
                    })
                    .catch(e => {
                        console.error("Error with data URL: ", e);
                        this.session.reportError("Error copying image to clipboard");
                    });
            } else {
                let rawText = this.result?.output?.data?.output;
                if (!rawText) rawText = "";
                // could render it somehow...

                let modelName;
                if (this.result?.output?.data?.model) {
                    // Result includes the model for the last step only.
                    let modelTable = await this.session.getAvailableModels();
                    let type = this.power.steps[this.power.steps.length - 1].type;
                    if (!modelTable[type]) {
                        console.error("Warning: no model for type", type);
                    } else {
                        let match = modelTable[type].models.filter((model) => {
                            return model.model === this.result.output.data.model;
                        });
                        if (match && match.length > 0) {
                            modelName = match[0].name;
                        } else {
                            console.error("Warning: no match for ", this.result.output.model);
                        }
                    }
                }

                if (modelName) {
                    rawText += `\n\nGenerated by Wispy using ${modelName} https://wispy-2.technicalmagic.ai/power/${this.power.powerID}`
                } else {
                    rawText += `\n\nGenerated by Wispy https://wispy-2.technicalmagic.ai/power/${this.power.powerID}`
                }
                try {
                    await this.copy_text_to_clipboard_helper(rawText);
                } catch (err) {
                    this.session.reportError("Error copying text to clipboard");
                    console.error("Error copying text to clipboard: ", err);
                }
            }
        }
    }

    async update_power_history() {
        console.log("[power] updating power history", this.power.powerID);

        try {
            if (!this.historyElement) {
                this.historyElement = document.createElement("div");
                this.historyElement.id = "power-history";
            }

            const historyContainer = document.getElementById("power-history-container");
            historyContainer.textContent = "";
            historyContainer.appendChild(this.historyElement);

            // Get the power history for this power
            let powerHistory = this.session.powerHistory;
            let keys = await powerHistory?.getKeys(this.power.powerID);
            if (!powerHistory || !keys.length) {
                // Reset the power history display
                document.getElementById("power-history-title").textContent = "No Recent Invocations";
                this.historyElement.innerHTML = "";
                this.historyElement = document.createElement("div");
                this.historyElement.id = "power-history";
                return;
            }

            console.log("[history] power history keys", keys);

            // For each power invocation, add or update the history element
            for (let powerInvocationKey of keys) {

                let existing = this.historyElement?.querySelector("#" + powerInvocationKey.replace(/:/g, "_"));
                let timestamp = powerHistory.keyToTimestamp(powerInvocationKey);

                console.log("[history] power invocation key", powerInvocationKey, timestamp, existing);

                // If not already in the history display, add it
                if (!existing) {
                    let powerInvocation = await powerHistory.get(powerInvocationKey);
                    console.log("[history] update-power-history", powerInvocation);
                    let powerInvocationDiv = document.createElement("div");
                    powerInvocationDiv.setAttribute("id", powerInvocationKey.replace(/:/g, "_"));
                    powerInvocationDiv.classList.add("power-history-item");

                    let powerInvocationLeftColumn = document.createElement("div");
                    powerInvocationLeftColumn.classList.add("power-history-item-left-column");

                    // Date/time of last update to this power
                    let powerInvocationTimestamp = document.createElement("div");
                    powerInvocationTimestamp.classList.add("power-history-item-timestamp");
                    if (powerInvocation.type === "chat") {
                        powerInvocationTimestamp.innerHTML = humaneDate(new Date(powerInvocation.result.lastupdate));
                    } else {
                        powerInvocationTimestamp.innerHTML = humaneDate(timestamp);
                    }
                    powerInvocationLeftColumn.appendChild(powerInvocationTimestamp);

                    let powerInvocationModel = document.createElement("div");
                    powerInvocationModel.classList.add("power-history-item-model");
                    powerInvocationModel.innerHTML = powerInvocation.result?.output?.data?.model || "";
                    powerInvocationLeftColumn.appendChild(powerInvocationModel);
                    powerInvocationDiv.appendChild(powerInvocationLeftColumn);
                    let powerInvocationSummary = document.createElement("div");
                    powerInvocationSummary.classList.add("power-history-item-summary");
                    powerInvocationSummary.innerHTML = powerInvocation.summarize();
                    powerInvocationDiv.appendChild(powerInvocationSummary);
                    let powerInvocationDelete = document.createElement("div");
                    powerInvocationDelete.classList.add("power-history-item-delete");
                    powerInvocationDiv.appendChild(powerInvocationDelete);

                    console.log("added to DOM", this.historyElement);
                    this.historyElement.prepend(powerInvocationDiv);

                    powerInvocationDiv.addEventListener("click", async (e) => {
                        e.preventDefault();
                        if (powerInvocation.type === "chat") {
                            await initChatCard(this.session, this.power, powerInvocation.result);
                            this.set_card_mode("chat");
                        } else {
                            if (powerInvocation.result?.output?.data?.seed) {
                                powerInvocation.inputs.forEach(input => {
                                    if (input._seed) {
                                        input._seed = JSON.stringify(powerInvocation.result.output.data.seed);
                                    }
                                });
                            }
                            this.powerInput = new PowerInput(this.power, powerInvocation.inputs, powerInvocation.stepCapabilities);
                            this.display_result({ result: powerInvocation.result, isStreaming: false, isHistory: true });
                        }
                    });

                    powerInvocationDelete.addEventListener("click", async (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        const confirmDialog = new ConfirmationDialog({
                            title: "Delete this power invocation?",
                            text: "This is permanent and cannot be undone.",
                            confirmText: "Delete",
                            cancelText: "Cancel",
                            confirmCallback: async () => {
                                await powerHistory.delete(powerInvocationKey);
                                await this.update_power_history();

                                this.session.reportInfo("Power Invocation Deleted");
                            },
                            cancelCallback: async () => {
                                this.session.reportInfo("Cancelled");
                            }
                        });
                        confirmDialog.show();
                    });
                }
            }

            // Remove any items from display that are no longer in the history
            this.historyElement.querySelectorAll(".power-history-item").forEach((child) => {
                let key = child.id.replace(/_/g, ":");
                if (!keys.includes(key)) {
                    console.log("[history] removing power invocation from display", key);
                    child.remove();
                }
            });

            document.getElementById("power-history-title").textContent = "";
        } catch (err) {
            console.error(err);
            this.session.reportError(err.message);
        }
    }

    // Use the Web Sharing API to share a link to the current power
    async share_power() {
        if (!this.power) return null;

        // Construct the URL using the URL API
        let url = new URL("/power/" + this.power.powerID, document.location);
        const shareData = {
            title: "Wispy: " + this.power.name,
            url: url.toString(),
        };

        if (navigator.share) {
            // Use the Web Share API if it's available
            try {
                await navigator.share(shareData);
                console.log("Link shared");
            } catch (err) {
                if (err.name === 'AbortError') {
                    console.log("Share dismissed");
                } else {
                    this.session.reportError("Error sharing link");
                    console.error("Error sharing link: ", err);
                }
            }
        } else {
            // Fallback for browsers that don't support the Web Share API
            try {
                await this.copy_text_to_clipboard_helper(shareData.url);
            } catch (err) {
                console.error("Error copying link to clipboard: ", err);
            }
        }
    }

    // Use the Web Sharing API to share results
    async share_results() {
        if (!this.result) return null;

        if (this.result?.output?.data?.output) {
            if (this.power.expectedOutputType() === "image") {
                try {
                    const response = await fetch(this.result.output.data.output);
                    const blob = await response.blob();
                    const index = Date.now();
                    const file = new File([blob], `power-result-${index}.png`, { type: "image/png" });
                    const filesArray = [file];
                    const modelName = this.result?.output?.data?.model;
                    let titleString = `Generated by Wispy https://wispy-2.technicalmagic.ai/power/${this.power.powerID}`;
                    if (modelName) {
                        titleString = `Generated by Wispy using ${modelName} https://wispy-2.technicalmagic.ai/power/${this.power.powerID}`;
                    }
                    const shareData = {
                        title: titleString,
                        files: filesArray,
                    };
                    await navigator.share(shareData);
                    console.log("Image shared");
                } catch (err) {
                    if (err.name === 'AbortError') {
                        console.log("Share dismissed");
                    } else {
                        this.session.reportError("Error sharing image");
                        console.error("Error sharing image: ", err);
                    }

                }
            } else {
                let rawText = this.result?.output?.data?.output;
                if (!rawText) rawText = "";
                // could render it somehow...
                const modelName = this.result?.output?.data?.model;
                if (modelName) {
                    rawText += `\n\nGenerated by Wispy using ${modelName} https://wispy-2.technicalmagic.ai/power/${this.power.powerID}`;
                } else {
                    rawText += `\n\nGenerated by Wispy https://wispy-2.technicalmagic.ai/power/${this.power.powerID}`;
                }

                try {
                    // Send text to share API
                    await navigator.share({
                        title: "Wispy Result",
                        text: rawText,
                    });
                    console.log("Shared text");
                } catch (err) {
                    if (err.name === 'AbortError') {
                        console.log("Share dismissed");
                    } else {
                        this.session.reportError("Error sharing text");
                        console.error("Error sharing text: ", err);
                    }
                }
            }
        }
    }

    // Helper function for various ways of copying to the clipboard...
    async copy_text_to_clipboard_helper(text) {
        try {
            if (navigator.clipboard && window.isSecureContext) {
                // Navigator clipboard api method
                await navigator.clipboard.writeText(text);
            } else {
                // Text area method
                return new Promise((resolve, reject) => {
                    let textArea = document.createElement("textarea");
                    textArea.value = text;
                    textArea.style.position = "fixed";  //avoid scrolling to bottom
                    document.body.appendChild(textArea);
                    textArea.focus();
                    textArea.select();

                    try {
                        if (!document.execCommand('copy')) {
                            throw new Error('Failed to copy');
                        }
                        document.body.removeChild(textArea);
                        resolve();
                    } catch (err) {
                        document.body.removeChild(textArea);
                        reject(err);
                    }
                });
            }
            // If text starts with http say link copied, otherwise say text copied
            if (text.startsWith("http")) {
                console.log("Copied link to clipboard");
                this.session.reportInfo("Copied link to clipboard");
            } else {
                console.log("Copied text to clipboard");
                this.session.reportInfo("Copied text to clipboard");
            }
        } catch (err) {
            this.session.reportError("Error copying to clipboard");
            console.error('Failed to copy: ', err);
        }
    }

    // Helper function for various ways of copying to the clipboard...
    async copy_text_to_clipboard_helper(text) {
        try {
            if (navigator.clipboard && window.isSecureContext) {
                // Navigator clipboard api method
                await navigator.clipboard.writeText(text);
            } else {
                // Text area method
                return new Promise((resolve, reject) => {
                    let textArea = document.createElement("textarea");
                    textArea.value = text;
                    textArea.style.position = "fixed";  //avoid scrolling to bottom
                    document.body.appendChild(textArea);
                    textArea.focus();
                    textArea.select();

                    try {
                        if (!document.execCommand('copy')) {
                            throw new Error('Failed to copy');
                        }
                        document.body.removeChild(textArea);
                        resolve();
                    } catch (err) {
                        document.body.removeChild(textArea);
                        reject(err);
                    }
                });
            }
            // If text starts with http say link copied, otherwise say text copied
            if (text.startsWith("http")) {
                console.log("Copied link to clipboard");
                this.session.reportInfo("Copied link to clipboard");
            } else {
                console.log("Copied text to clipboard");
                this.session.reportInfo("Copied text to clipboard");
            }
        } catch (err) {
            this.session.reportError("Error copying to clipboard");
            console.error('Failed to copy: ', err);
        }
    }
}

/******************************************************************************/
/*********************** User Input Form Support ******************************/
/******************************************************************************/
export function buildUserInputForm(userInputs, topLevelContainer, currentValues = {}) {

    let basicContainer = document.createElement("div");
    basicContainer.classList.add("basic-options");

    let advancedContainer = document.createElement("div");
    advancedContainer.classList.add("advanced-options");
    advancedContainer.classList.add("mdl-collapsible");
    advancedContainer.innerHTML = `
        <div class="mdl-collapsible__header">
            <span class="advanced-title"><i class="material-icons mdl-collapsible__header--rotate-">keyboard_arrow_down</i>Advanced options </span>
        </div>
    `;

    let advancedContent = document.createElement("div");
    advancedContent.classList.add("mdl-collapsible__body");
    advancedContent.style.display = 'none';
    advancedContainer.appendChild(advancedContent);

    let header = advancedContainer.querySelector('.mdl-collapsible__header');
    header.addEventListener('click', () => {
        let body = advancedContainer.querySelector('.mdl-collapsible__body');
        if (body.style.display === 'none') {
            body.style.display = 'flex';
            body.style.flexDirection = 'column';
            header.querySelector('.mdl-collapsible__header--rotate-').textContent = 'keyboard_arrow_up';
        } else {
            body.style.display = 'none';
            header.querySelector('.mdl-collapsible__header--rotate-').textContent = 'keyboard_arrow_down';
        }
    });

    topLevelContainer.appendChild(basicContainer);
    topLevelContainer.appendChild(advancedContainer);

    try {

        let inputBuilderFn = (userInput, container) => {
            console.log("Building userInput", userInput);
            switch (userInput.type) {
                case "short_text":
                    let shortTextBuilder = new ShortTextBuilder(userInput);
                    shortTextBuilder.construct(container, currentValues);
                    break;
                case "long_text":
                    let longTextBuilder = new LongTextBuilder(userInput);
                    longTextBuilder.construct(container, currentValues);
                    break;
                case "checkbox":
                    let checkboxBuilder = new CheckboxBuilder(userInput);
                    checkboxBuilder.construct(container, currentValues);
                    break;
                case "radio":
                    let radioBuilder = new RadioBuilder(userInput);
                    radioBuilder.construct(container, currentValues);
                    break;
                case "select":
                    let selectBuilder = new SelectBuilder(userInput);
                    selectBuilder.construct(container, currentValues);
                    break;
                default:
                    console.error("Unknown user input type: ", userInput.type);
            }
        };
        // Advanced elements go last:
        userInputs.filter((userInput) => !userInput.advanced).forEach((i) => inputBuilderFn(i, basicContainer));

        let advanced = userInputs.filter((userInput) => userInput.advanced);
        if (advanced.length > 0) {
            advanced.forEach((i) => inputBuilderFn(i, advancedContent));
        } else {
            advancedContainer.classList.add("hidden");
        }
    } catch (e) {
        console.error(e);
    }
    return [basicContainer, advancedContainer]
}
class UserInputBuilder {
    constructor(data) {
        this.type = data?.type;
        this.key = data?.key;
        this.label = data?.label;
        this.required = data?.required;
        this.hint = data?.hint;
        this.default = data?.default;
        this.placeholder = data?.placeholder;
        this.options = data?.options;
        this.remotable = data?.remotable;
        this.remote_label = data?.remote_label;
        this.style = data?.style;
    }

    mkDiv(classNameOrNames) {
        let d = document.createElement("div");
        if (Array.isArray(classNameOrNames)) {
            classNameOrNames.forEach((className) => {
                d.classList.add(className);
            });
        } else {
            d.classList.add(classNameOrNames);
        }
        return d;

    }

    buildShared(container) {
        let optionRow = this.mkDiv("row");
        let labelDiv = this.mkDiv("label");
        let label = document.createElement("label");
        let controlDiv = this.mkDiv("control", this.type);
        optionRow.appendChild(labelDiv);
        labelDiv.appendChild(label);

        if (this.hint) {
            let hintDiv = this.mkDiv("hint");
            optionRow.appendChild(hintDiv);
            hintDiv.textContent = this.hint;
        }
        optionRow.appendChild(controlDiv);

        if (this.label) {
            label.textContent = this.label;
        } else {
            label.textContent = this.key;
        }

        if (this.required) {
            // add a "Required" indicator to the right of label
            let requiredDiv = document.createElement("span");
            requiredDiv.textContent = " *";
            requiredDiv.classList.add("required");
            label.appendChild(requiredDiv);
        }
        container.appendChild(optionRow);
        return [optionRow, controlDiv, label];
    }
}

class ShortTextBuilder extends UserInputBuilder {
    constructor(data) {
        super(data);
    }

    construct(container, currentValues = {}) {
        let [optionRow, controlDiv, label] = this.buildShared(container);
        let input = document.createElement("input");
        input.type = "text";
        input.id = `modal_${this.key}`;
        input.classList.add("short_text");
        label.htmlFor = input.id;
        if (this.style) input.classList.add(this.style);
        controlDiv.appendChild(input);

        // Check for existing value
        if (currentValues?.[this.key]) {
            if (typeof currentValues[this.key] === "string") {
                input.value = currentValues[this.key];
            }
        } else if (this.default) {
            if (typeof this.default === "string") {
                input.value = this.default;
                currentValues[this.key] = this.default;
            }
        }
        if (this.placeholder) {
            if (typeof this.placeholder === "string") {
                input.placeholder = this.placeholder;
            }
        }
        input.addEventListener("change", (e) => {
            currentValues[this.key] = e.target.value;
            console.log(currentValues);
        });
        input.addEventListener("keyup", (e) => {
            currentValues[this.key] = e.target.value;
            console.log(currentValues);
        });
    }
}

class LongTextBuilder extends UserInputBuilder {
    constructor(data) {
        super(data);
    }

    construct(container, currentValues = {}) {
        let [optionRow, controlDiv, label] = this.buildShared(container);

        let input = document.createElement("textarea");
        input.classList.add("long_text");
        input.id = `modal_${this.key}`;
        label.htmlFor = input.id;
        controlDiv.appendChild(input);

        let remotable_cbox;
        let remote_input;
        if (this.remotable) {
            remotable_cbox = document.createElement("input");
            remotable_cbox.setAttribute("type", "checkbox");
            remotable_cbox.id = `modal_${this.key}_remotable`;
            let cbox_label = document.createElement("label");
            cbox_label.classList.add("remotable");
            cbox_label.textContent = this.remote_label || "Use Remote URL";
            cbox_label.htmlFor = remotable_cbox.id;
            cbox_label.appendChild(remotable_cbox);


            remote_input = document.createElement("input");
            remote_input.setAttribute("type", "text");
            remote_input.placeholder = "Remote URL...";
            remote_input.classList.add("remote_url");
            remote_input.classList.add("hidden");
            remote_input.id = `modal_${this.key}_remote_url`;
            controlDiv.appendChild(remote_input);

            //label.parentNode.appendChild(cbox_label, input);
            controlDiv.appendChild(cbox_label);
        }

        if (currentValues?.[this.key]) {
            if (typeof currentValues[this.key] === "string") {
                input.value = currentValues[this.key];
            } else if (typeof currentValues[this.key] === "object") {
                if (currentValues[this.key].remote) {
                    remotable_cbox.checked = true;
                    remote_input.value = currentValues[this.key].remote;
                    remote_input.classList.remove("hidden");
                    input.classList.add("hidden");
                }
            }
        } else if (this.default) {
            if (typeof this.default === "string") {
                input.value = this.default;
                currentValues[this.key] = this.default;
            }
        }
        if (this.placeholder) {
            if (typeof this.placeholder === "string") {
                input.placeholder = this.placeholder;
            }
        }
        input.addEventListener("change", (e) => {
            if (!remotable_cbox || !remotable_cbox.checked)
                currentValues[this.key] = e.target.value;
        });
        input.addEventListener("keyup", (e) => {
            if (!remotable_cbox || !remotable_cbox.checked)
                currentValues[this.key] = e.target.value;
        });

        input.addEventListener('input', (e) => {
            input.style.height = 'auto';
            input.style.height = (input.scrollHeight) + 'px';
        });

        if (remotable_cbox) remotable_cbox.addEventListener("change", (e) => {
            if (remotable_cbox && remotable_cbox.checked) {
                remote_input.classList.remove("hidden");
                input.setAttribute("disabled", true);
                input.classList.add("hidden");
            } else {
                remote_input.classList.add("hidden");
                input.removeAttribute("disabled");
                input.classList.remove("hidden");
            }
        });
        if (remote_input) {
            remote_input.addEventListener("change", (e) => {
                if (remotable_cbox.checked)
                    currentValues[this.key] = { remote: e.target.value };
            });
            remote_input.addEventListener("keyup", (e) => {
                if (remotable_cbox.checked)
                    currentValues[this.key] = { remote: e.target.value };
            });
        }
    }
}

class CheckboxBuilder extends UserInputBuilder {
    constructor(data) {
        super(data);
    }

    construct(container, currentValues = {}) {
        let self = this;
        let [optionRow, controlDiv, label] = this.buildShared(container);
        console.log("CheckboxBuilder", this.key, "currentValues", currentValues);

        let all_boxes = [];
        let getAllCurrentlyChecked = () => {
            let values = [];
            all_boxes.forEach((input) => {
                if (input.checked) {
                    values.push(input.value);
                }
            });
            return values;
        };
        this.options.forEach((radioOption, index) => {
            let input = document.createElement("input");
            all_boxes.push(input);
            input.type = "checkbox";
            input.name = self.key;
            input.id = `modal_${self.key}_${index}`;
            input.value = radioOption.value;
            console.log("Creating checkbox ", self.key, " with value ", radioOption.value, " and currentValues ", currentValues, " and default ", self.default, " and input ", input);

            if (currentValues?.[self.key]) {
                if (Array.isArray(currentValues[self.key]) &&
                    currentValues[self.key].includes(radioOption.value)) {
                    input.checked = true;
                } else if (typeof currentValues[self.key] === "string" &&
                    currentValues[self.key] === radioOption.value) {
                    input.checked = true;
                }
            } else if (self.default) {
                if (Array.isArray(self.default) &&
                    self.default.includes(radioOption.value)) {
                    input.checked = true;
                } else if (typeof self.default === "string" &&
                    self.default === radioOption.value) {
                    input.checked = true;
                    currentValues[self.key] = radioOption.value;
                }
            }
            let sublabel = document.createElement("label");
            sublabel.htmlFor = input.id;
            sublabel.appendChild(input);
            sublabel.appendChild(document.createTextNode(radioOption.label));
            controlDiv.appendChild(sublabel);

            input.addEventListener("change", (e) => {
                currentValues[this.key] = getAllCurrentlyChecked();
                console.log(currentValues);
            });
        });
    }
}
class RadioBuilder extends UserInputBuilder {
    constructor(data) {
        super(data);
    }

    construct(container, currentValues = {}) {
        let self = this;
        let [optionRow, controlDiv, label] = this.buildShared(container);
        console.log("RadioBuilder", this.key, "currentValues", currentValues, "selfValues", this.options);

        let all_radios = [];
        let getCurrentlyChecked = () => {
            let value = null;
            all_radios.forEach((input) => {
                if (input.checked) {
                    value = input.value;
                }
            });
            return value;
        };

        this.options.forEach((radioOption, index) => {
            let input = document.createElement("input");
            all_radios.push(input);
            input.type = "radio";
            input.name = self.key;
            input.id = `modal_${self.key}_${index}`;
            input.value = radioOption.value;

            console.log("checking ", currentValues?.[self.key], "?=", radioOption.value,
                currentValues[self.key] === radioOption.value);

            if (currentValues?.[self.key]) {
                if (currentValues[self.key] === radioOption.value) {
                    input.checked = true;
                }
            } else {
                if (self.default &&
                    self.default === radioOption.value) {
                    input.checked = true;
                    currentValues[self.key] = radioOption.value;
                }
            }
            controlDiv.appendChild(input);
            let sublabel = document.createElement("label");
            sublabel.htmlFor = input.id;
            sublabel.textContent = radioOption.label;
            controlDiv.appendChild(sublabel);
            input.addEventListener("change", (e) => {
                currentValues[this.key] = getCurrentlyChecked();
                console.log(currentValues);
            });
        });
    }
}

class SelectBuilder extends UserInputBuilder {
    constructor(data) {
        super(data);
    }

    construct(container, currentValues = {}) {
        let self = this;
        let [optionRow, controlDiv, label] = this.buildShared(container);

        let select = document.createElement("select");
        select.id = `modal_${this.key}`;
        label.htmlFor = select.id;
        let foundSelected;
        this.options.forEach((selectOption) => {
            let option = document.createElement("option");
            option.value = selectOption.value;
            option.textContent = selectOption.label;
            if (currentValues?.[self.key] &&
                currentValues[self.key] === selectOption.value) {
                option.selected = true;
                foundSelected = true;
            } else
                if (self.default &&
                    self.default === selectOption.value) {
                    option.selected = true;
                    currentValues[self.key] = selectOption.value;
                }
            select.appendChild(option);
        });
        //        if (!this.default && !foundSelected) {
        //            // ensure nothing is selected to start
        //            select.selectedIndex = -1;
        //        }
        select.addEventListener("change", (e) => {
            currentValues[this.key] = select.value;
            console.log(currentValues);
        });
        controlDiv.appendChild(select);
    }
}