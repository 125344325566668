import dialogPolyfill from 'dialog-polyfill';

export class WispyAlertManager {
    constructor() {
        this.queue = [];
        this.isAlertActive = false;
        this.alertContainer = document.getElementById("alert-container");
        this.alertDialog = document.getElementById("alert-dialog");
        this.alertDialogText = document.getElementById("alert-error-message");
        if (this.alertDialog) {
            dialogPolyfill.registerDialog(this.alertDialog);
        } else {
            console.error("Could not find alert dialog")
        }
        document.getElementById("alert-error-proceed-button").addEventListener("click", (e) => {
            this.alertDialog.close();
        });
    }

    showAlert(message, actionText, actionFunction, timeout = 4000) {
        if (this.isAlertActive) {
            this.queue.push([message, actionText, actionFunction, timeout]);
        } else {
            this.displayAlert(message, actionText, actionFunction, timeout);
        }
    }

    displayAlert(message, actionText, actionFunction, timeout) {
        const data = {
            message: message,
            actionHandler: () => {
                actionFunction();
                this.dismissAlert();
            },
            actionText: actionText || ' ',
            timeout: timeout
        };

        if (!this.alertContainer.MaterialSnackbar) {
            componentHandler.upgradeElement(this.alertContainer);
        }
        this.alertContainer.MaterialSnackbar.showSnackbar(data);
        this.isAlertActive = true;

        if (timeout > 0) {
            setTimeout(() => {
                this.dismissAlert();
            }, timeout);
        }
    }

    dismissAlert() {
        this.isAlertActive = false;
        this.alertContainer.classList.remove('mdl-snackbar--active');
        if (this.queue.length > 0) {
            const [message, actionText, actionFunction, timeout] = this.queue.shift();
            this.displayAlert(message, actionText, actionFunction, timeout);
        }
    }

    dismissHandler() {
        this.dismissAlert();
    }

    info(message) {
        this.showAlert(message, null, this.dismissHandler.bind(this), 800);
    }

    warn(message) {
        this.showAlert(message, 'Understood', this.dismissHandler.bind(this), 6000);
    }

    error(message) {
        // this.showAlert(message, 'Dismiss', this.dismissHandler.bind(this), 0);
        this.alertDialogText.innerHTML = message;
        this.alertDialog.showModal();
    }
}
