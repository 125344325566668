
import { ImageResizer } from "./image.js";
import { buildUserInputForm } from "./power_card.js";
import { buildModelPickerControls } from "./capability_utils.js";
import DOMPurify from 'dompurify';
import Mustache from 'mustache';
import localforage from 'localforage';
import { marked } from "marked";

let gWispy_CurrentPower = null;
let isPowerInitialized = false;

const SUMMARIZE_INVOCATION_ID = "v2-summarize-invocation";
let gEnableRemotableImages = true;

// HACK: this is stability's list of ratios, we need to make it available to the client somehow
let IMAGE_RATIOS = [[1024, 1024], [1152, 896], [1216, 832], [1344, 768], [1536, 640], [640, 1536], [768, 1344], [832, 1216], [896, 1152]];

/** Classes related to representing Powers.
 *
 * User interface to Powers is defined in power_card.js
 */
export class PowerRegistry {
    constructor(data) {
        this._loaded = false;
        this.powers = [];
    }

    async load(session) {
        try {
            const server = import.meta.env.VITE_WISPY_API_SERVER_URL;
            const response = await fetch(`${server}/powers-v2/public`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                }
            });
    
            if (!response.ok) {
                throw new Error(`Unable to refresh powers: bad response from server (${response.statusText})`);
            }
    
            const powerPayload = await response.json();
            this.powers = powerPayload.map(powerData => new Power(powerData));
    
            console.log("[power] powers refreshed", this.powers);
        } catch (error) {
            console.error("[power] error refreshing powers", error);
            throw new Error("Unable to connect to power server");
        }
    }

    loaded() {
        return this._loaded;
    }

    index(power) {
        return this.powers.indexOf(power);
    }

    length() {
        return this.powers.length;
    }

    get(index) {
        return this.powers[index];
    }

    getPowerByID(power_id) {
        if (!this.powers) {
            throw new Error("Powers not initialized");
        }
        return this.powers.find((power) => {
            return power.powerID === power_id;
        });
    }

    all() {
        return this.powers;
    }
}

async function invokeInternalPower(authClient, powerID, inputs) {
    let request = {
        "powerID": powerID,
        "inputs": inputs,
        //"stepCapabilities": ["textCompletion;mistral?model=@cf/mistral/mistral-7b-instruct-v0.1"]
        "stepCapabilities": ["textCompletion;gemini"]
    };

    let server = import.meta.env.VITE_WISPY_API_SERVER_URL;
    let response;

    try {
        let headers = {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + await authClient.getAccessToken()
        };
        response = await fetch(server + '/summon', {
            method: "POST",
            headers: headers,
            body: JSON.stringify(request),
        });
    } catch (err) {
        console.error("[power] Error contacting server for internal power", err);
        throw new Error("Error contacting server");
    }
    if (!response.ok) {
        // NB error is currently used only for status code-level errors
        powerInvocation.error = response.status + " " + response.statusText;
        console.error("[power] Error invoking power", response.statusText, response);
        throw new Error("Server error: " + response.statusText);
    }
    return await response.json();
}

export class Power {
    constructor(data) {
        this.powerID = data?.id || null;
        this.name = data?.name || null;
        this.description = data?.description || null;
        this.usageInstructions = data?.usageInstructions || null;
        this.image = data?.image || null;
        this.steps = data?.steps || null;

        // XXX refactor this to a PowerInvocation object
        // this.capability = [ array of capability overrides... ]
        this.inputs = data?.inputs || null;
    }

    expectedOutputType(step = undefined) {
        if (!step) {
            return this.mapStepTypeToOutput(this.steps[this.steps.length - 1]?.type);
        } else {
            // check isNumber
            if (!Number.isInteger(step)) {
                throw new Error("Step must be a number");
            }
            if (step > this.steps.length) {
                throw new Error("Step is out of bounds");
            }
            return this.mapStepTypeToOutput(this.steps[step].type);
        }
    }

    /** Helper method: maps a model capability to an expected output */
    mapStepTypeToOutput(stepType) {
        if (stepType === "textToImage" || stepType === "imageToImage") {
            return "image";
        }
        if (stepType === "textCompletion" || stepType === "imageToText") {
            return "text";
        }
    }

    /** Helper method: maps a model capability to an expected input(s)
     *
     * Return value: Array<string>
     */
    mapStepTypeToInput(stepType) {
        if (stepType === "textToImage" || stepType === "textCompletion") {
            return ["text"];
        }
        if (stepType === "imageToText") {
            return ["image"];
        }
        if (stepType === "imageToImage") {
            return ["image", "text"];
        }
    }

    validateUserInputs(stepInputs) {
        for (let step = 0; step < this.steps.length; step++) {
            console.log("Validating user inputs step=", step, stepInputs[step]);
            if (!stepInputs[step]) {
                throw new Error("Missing required inputs; no input for step", step);
            }
            let stepObj = this.steps[step];
            let stepType = stepObj.type;

            if (stepObj.prompt?.userInputs) stepObj.prompt?.userInputs.forEach((userInput) => {
                if (userInput.required) {
                    if (!stepInputs[step][userInput.key]) {
                        throw new Error("Missing required input: " + userInput.label);
                    }
                }
            });
            if (step == 0) {
                let defaultTypes = this.mapStepTypeToInput(stepType);
                if (!defaultTypes) {
                    throw new Error(`No default types for step type "${stepType}" (malformed Power data?)`);
                }
                let defaultTextInputNeeded = defaultTypes.includes("text");
                if (stepObj.prompt?.userInputs) {
                    // If we have a userInput-driven prompt, suppress the default text field
                    defaultTextInputNeeded = false;
                }

                if (defaultTextInputNeeded) {
                    if (!stepInputs[step].text) {
                        throw new Error("Missing required input text");
                    }
                }
                if (defaultTypes.includes("image")) {
                    if (!stepInputs[step].image) {
                        throw new Error("Missing required input image");
                    }
                }
            }
        }
    }

    /*
    * Creates user interface elements for the UserInputs of the power;
    * optionally for a specific step.
    */
    createUserInputForm(session, container, powerInput) {
        console.log("[power] Building user input form for power", this.powerID, "with", powerInput);

        // Default prompts are only included for step 0.
        let defaultTypes, defaultTextInputNeeded;

        let wrappingForm = document.createElement("form");
        wrappingForm.setAttribute("action", "#");
        wrappingForm.setAttribute("id", "power-user-input-form");
        wrappingForm.classList.add("power-form");
        container.appendChild(wrappingForm);
        let firstAdvancedUIContainer;

        for (let step = 0; step < this.steps.length; step++) {
            let inputs = powerInput.getStepInputs()[step];
            let stepObj = this.steps[step];
            let stepType = stepObj.type;
            console.log("Rendering form for ", stepObj);

            if (step == 0) {
                defaultTypes = this.mapStepTypeToInput(stepType);
                if (!defaultTypes) {
                    throw new Error(`No default types for step type "${stepType}" (malformed Power data?)`);
                }
                defaultTextInputNeeded = defaultTypes.includes("text");
            }

            let basicUIContainer, advancedUIContainer;
            if (stepObj.prompt?.userInputs) {
                if (stepObj.prompt.template) {
                    // If we have a userInput-driven prompt, suppress the default text field
                    defaultTextInputNeeded = false;
                }
                [basicUIContainer, advancedUIContainer] = buildUserInputForm(stepObj.prompt?.userInputs, wrappingForm, inputs);
                if (!firstAdvancedUIContainer) firstAdvancedUIContainer = advancedUIContainer
            }
            if (step == 0 && defaultTypes) defaultTypes.forEach((defaultType) => {
                if (defaultType === "text" && defaultTextInputNeeded) {
                    let inputElem = document.createElement("textarea");
                    inputElem.setAttribute("name", "input");
                    inputElem.setAttribute("id", "input");
                    inputElem.classList.add("full-textfield");
                    inputElem.classList.add("input-text");
                    inputElem.setAttribute("placeholder", "Enter prompt text here");
                    inputElem.setAttribute("required", "");
                    inputElem.setAttribute("rows", 4);
                    inputElem.setAttribute("autocomplete", "off");
                    if (inputs.text) {
                        inputElem.value = inputs.text;
                    }
                    inputElem.addEventListener("change", (e) => {
                        inputs.text = e.target.value;
                    });
                    inputElem.addEventListener("keyup", (e) => {
                        inputs.text = e.target.value;
                    });
                    wrappingForm.insertBefore(inputElem, advancedUIContainer);
                } else if (defaultType === "image") {
                    let imageUploadElem = document.createElement("input");
                    imageUploadElem.setAttribute("type", "file");
                    imageUploadElem.setAttribute("name", "imageUpload");
                    imageUploadElem.classList.add("input-image");
                    imageUploadElem.setAttribute("id", "imageUpload");
                    imageUploadElem.setAttribute("accept", "image/*");
                    //imageUploadElem.setAttribute("capture", "environment");
                    imageUploadElem.classList.add("image-upload");
                    imageUploadElem.style.opacity = 0;

                    let imageRollButton = document.createElement("label");
                    imageRollButton.classList.add("input-image-label");
                    imageRollButton.setAttribute("for", "imageUpload");
                    imageRollButton.innerHTML = '<span class="material-icons right">add_photo_alternate</span> Select Image';

                    let imagePreview = document.createElement("img");
                    imagePreview.setAttribute("id", "imagePreview");
                    imagePreview.classList.add("image-preview");

                    // If we already have a value load it up here
                    if (powerInput.getStepInputs()[0].image) {
                        let value = powerInput.getStepInputs()[0].image
                        if (typeof value === "string") {
                            let imgResize = new ImageResizer(value);
                            imgResize.resizeToClosestAspectRatio(IMAGE_RATIOS).then((resizedImage) => {
                                powerInput.getStepInputs()[0].image = resizedImage
                            });
                            imagePreview.src = value;// XXX should we use the resized image here?
                        }
                    }
                    let uploadHandler = (e) => {
                        console.log("Photo upload handler");
                        let file = e.target.files[0];
                        let reader = new FileReader();
                        reader.onload = (e) => {
                            try {
                                console.log("Photo upload read", e);
                                let imgResize = new ImageResizer(e.target.result);
                                imgResize.resizeToClosestAspectRatio(IMAGE_RATIOS).then((resizedImage) => {
                                    powerInput.getStepInputs()[0].image = resizedImage
                                });
                                imagePreview.src = e.target.result;
                            } catch (err) {
                                console.error("Photo upload error", err);
                            }
                        };
                        reader.readAsDataURL(file);
                    };
                    imageUploadElem.addEventListener("change", uploadHandler);

                    let imageUploadContainer = document.createElement("div");
                    imageUploadContainer.className = 'addImageForm';
                    wrappingForm.insertBefore(imageUploadContainer, advancedUIContainer);

                    imageUploadContainer.appendChild(imageRollButton);
                    imageUploadContainer.appendChild(imageUploadElem);

                    if (gEnableRemotableImages) {
                        let remote_cbox_label;
                        let remote_input;
                        let remotable_cbox = document.createElement("input");
                        remotable_cbox.setAttribute("type", "checkbox");
                        remotable_cbox.id = `modal_${this.key}_remotable`;
                        remote_cbox_label = document.createElement("label");
                        remote_cbox_label.classList.add("remote-check");
                        remote_cbox_label.textContent = this.remote_label || "Use Remote URL";
                        remote_cbox_label.htmlFor = remotable_cbox.id;
                        remote_cbox_label.appendChild(remotable_cbox);

                        remote_input = document.createElement("input");
                        remote_input.setAttribute("type", "text");
                        remote_input.placeholder = "Remote URL...";
                        remote_input.classList.add("remote_url");
                        remote_input.classList.add("hidden");
                        remote_input.id = `modal_${this.key}_remote_url`;
                        imageUploadContainer.appendChild(remote_input);
                        imageUploadContainer.appendChild(remote_cbox_label);

                        if (typeof inputs.image === "object") {
                            if (inputs.image.remote) {
                                remote_input.value = inputs.image.remote;
                                remotable_cbox.checked = true;
                                remote_input.classList.remove("hidden");
                                imageUploadElem.setAttribute("disabled", true);
                                imageUploadElem.classList.add("hidden");
                                imageRollButton.classList.add("hidden");
                            }
                        }

                        remotable_cbox.addEventListener("change", (e) => {
                            if (remotable_cbox && remotable_cbox.checked) {
                                remote_input.classList.remove("hidden");
                                imageUploadElem.setAttribute("disabled", false);
                                imageUploadElem.classList.add("hidden");
                                imageRollButton.classList.add("hidden");
                                remote_input.focus();
                            } else {
                                remote_input.classList.add("hidden");
                                imageUploadElem.setAttribute("disabled", true);
                                imageUploadElem.classList.remove("hidden");
                                imageRollButton.classList.remove("hidden");
                            }
                        });
                        remote_input.addEventListener("change", (e) => {
                            if (remotable_cbox.checked)
                                powerInput.getStepInputs()[0].image = { remote: e.target.value };
                        });
                        remote_input.addEventListener("keyup", (e) => {
                            if (remotable_cbox.checked)
                                powerInput.getStepInputs()[0].image = { remote: e.target.value };
                        });
                    }
                    imageUploadContainer.appendChild(imagePreview);
                }
            });
        }

        let modelPickerContainer = document.createElement("div");
        modelPickerContainer.classList.add("power-model-picker-container");
        buildModelPickerControls(this, session, modelPickerContainer, powerInput.getStepCapabilities());
        if (!firstAdvancedUIContainer) {
            wrappingForm.appendChild(modelPickerContainer);
        } else {
            wrappingForm.insertBefore(modelPickerContainer, firstAdvancedUIContainer);
        }
        wrappingForm.addEventListener("submit", (e) => {
            e.preventDefault();
        });
        console.log("Created form ", powerInput);
    }

    /** Returns an array of PowerInvocations */
    async getInvocations() {
        let resultStr = await localforage.getItem("invoke-" + this.powerID);
        if (resultStr) {
            let result = JSON.parse(resultStr);
            if (result && result.version === 1) {
                return result.invocations.map((invocation) => {
                    return new PowerInvocation(invocation);
                });
            }
        }
    }

    // TODO: refactor to share this code with server
    generatePrompt(stepIndex, inputs) {
        let step = this.steps[stepIndex];
        console.log("[power] generatePrompt with prompt=", step.prompt?.template?.substring(0, 100) + "...", "inputs=", inputs);
        if (typeof step.prompt === "string") {
            return step.prompt;
        }

        if (step.prompt?.template) {
            let templateInputs;
            if (typeof inputs == "object") {
                templateInputs = inputs;
            } else if (typeof inputs == "string") {
                templateInputs = { _input: inputs };
            }
            console.log("Mustache render with inputs=", templateInputs, " and prompt=", step.prompt.template);
            let prompt = Mustache.render(step.prompt.template, templateInputs);
            return prompt;
        } else {
            if (inputs.text) {
                return inputs.text;
            }
            // if prompt is null, or there's no template, we just use the provided inputs
            return null;
        }
    }
}

/* PowerInvocationHistory is a wrapper around localforage that
 * implements a time-sorted index of PowerInvocations.  Each
 * PowerInvocationHistory represents a single Power, but many
 * histories can exist in the same History store (so long as their
 * PowerIDs are different).
 *
 * The implementation assumes that iteration of Storage keys is
 * efficient, so that scan and sort operations over them are cheap.
 * It is designed to allow the client to page Invocation objects
 * to external storage if needed, while the index stays local.
 *
 * The storage format is:
 *    invoke:<powerID>:<timestamp> = <PowerInvocation>
 *
 * where <timestamp> is a unix timestamp in milliseconds
 * and <PowerInvocation> is a JSON-serialized PowerInvocation,
 * which might someday include a URL to the actual invocation data.
 */
export class PowerInvocationHistory {
    constructor() {
    }

    async getKeys(powerID) {
        let keys = await localforage.keys();
        let scanFilter = "invoke:" + powerID.replace(":", "/");

        keys = keys.filter(key => key.startsWith(scanFilter));

        keys.sort((a, b) => {
            let tsA = a.match(/\d+/);
            let tsB = b.match(/\d+/);
            if (!tsA) return -1;
            if (!tsB) return 1;
            const timestampA = parseInt(tsA[0]);
            const timestampB = parseInt(tsB[0]);
            return timestampA - timestampB;
        });

        return keys;
    }

    async get(key) {

        try {
            let invocationStr = await localforage.getItem(key);
            if (invocationStr) {
                let invocation = JSON.parse(invocationStr);
                if (invocation && invocation.version === 1) {

                    let result = new PowerInvocation(invocation);
                    console.log("[history] get ", result);

                    return result;
                }
            }
            return null;
        } catch (err) {
            console.log(err);
        }
    }

    keyToTimestamp(key) {
        // Convert from milliseconds since Unix epoch to a Date object
        let msec = Number(key.split(":")[2]);
        return new Date(msec);
    }

    async sortedKeys() {
        let keys = await localforage.keys();

        keys = keys.filter(key => key.startsWith('invoke:'));

        return keys.sort((a, b) => {
            let tsA = a.match(/\d+/);
            let tsB = b.match(/\d+/);
            if (!tsA) return -1;
            if (!tsB) return 1;
            const timestampA = parseInt(tsA[0]);
            const timestampB = parseInt(tsB[0]);
            return timestampA - timestampB;
        });
    }

    async insert(invocation, authClient) {
        const powerID = invocation.powerID.replace(":", "/");
        const limit = 10;
        
        console.log("[history] insert", invocation);

        let id = invocation.result.id ?? Date.now();

        while (true) {
            let key = "invoke:" + powerID + ":" + id;

            // Get all keys for the current power
            let keys = (await localforage.keys()).filter(k => k.includes(powerID));

            if (keys.length >= limit) {
                // If the limit is reached, delete the oldest item for the current power
                keys.sort();
                console.log("Exceeded ", limit, " items of history, removing olding item", keys[0]);
                for (let i = 0; i < keys.length - limit + 1; i++) {
                    await localforage.removeItem(keys[i]);
                }
            }

            try {
                await localforage.setItem(key, await invocation.serialize());
                return key;
            } catch (err) {
                console.log("Storage is full. Deleting oldest items.");
                keys = await this.sortedKeys();
                if (keys && keys.length > 10) {
                    // Remove the last 20%
                    let numberToDelete = keys.length / 5;
                    for (let i = 0; i < numberToDelete / 5; i++) {
                        await localforage.removeItem(keys[i]);
                    }
                    console.log("Deleted ", numberToDelete, "items");
                    continue;
                }
                // Different exception, or couldn't find items to delete, throw the error.
                console.log(err);
                throw err;
            }
        }
    }

    async update(invocation, authClient) {
        const powerID = invocation.powerID.replace(":", "/");
       
        let key = "invoke:" + powerID + ":" + invocation.result.id;

        console.log("[history] update", invocation);

        try {
        await localforage.setItem(key, await invocation.serialize());
        } catch(err) {
            console.log(err);
        }
    }

    /*
    // kick off a summarize call:
    let generate_summary = false;
    if (generate_summary && authClient) {
        try {
            // Sanitize inputs to remove any images
            let sanitizedInputs = JSON.parse(JSON.stringify(invocation.inputs));
            traverseAndRemoveImages(sanitizedInputs);
            let result = await invokeInternalPower(authClient, SUMMARIZE_INVOCATION_ID, [{
                "powerName": invocation.powerID,
                "arguments": JSON.stringify(sanitizedInputs)
            }]);
            console.log("Summarization result", result);
            invocation._summary = result.output.data.output;
            window.localStorage.setItem(key, await invocation.serialize());
        } catch (err) {
            // unable to generate summary
        }
    }
*/

    async delete(key) {
        console.log("[power] Deleting history item", key);
        await localforage.removeItem(key);
    }
}

export class PowerInvocation {
    constructor(data) {
        this.powerID = data?.powerID || null;
        this.inputs = data?.inputs || null;
        this.stepCapabilities = data?.stepCapabilities || null;
        this.step = data?.step || 0;
        this.result = data?.result || null;
        this.error = data?.error || null;
        this._summary = data?._summary || null;
        this.type = data?.type || "power";
        this.version = 1;
    }

    async serialize() {
        let clone = JSON.parse(JSON.stringify(this));
        await traverseAndDownscale(clone);
        return JSON.stringify(clone);
    }

    summarize() {
        let summaryVal;

        // If we have a generated summary, great
        if (this._summary) {
            summaryVal = DOMPurify.sanitize(marked.parse(this._summary));
        }

        // But if we have an image, we still might want to include it:
        if (this.result) {
            if (this.result.output) {
                if (this.result.output.data) {
                    if (this.result.output.data.output) {
                        let data = this.result.output.data.output;
                        if (data.startsWith("data:image/")) {

                            if (summaryVal) {
                                // Summary prompt and image:
                                return "<span class='summary-input'>" + summaryVal + "</span><img alt='Generated Image' src='" + data + "'/>";
                            } else {
                                // Just the image:
                                return "<img alt='Generated Image' src='" + data + "'/>";
                            }
                        } else {
                            if (!summaryVal) {
                                summaryVal = DOMPurify.sanitize(marked.parse(this.result.output.data.output));
                                if (summaryVal.length > 140) {
                                    summaryVal = summaryVal.substring(0, 140) + "...";
                                }
                            }

                            let summaryText = summaryVal.replace(/<[^>]*>/g, ''); // strip HTML tags from Markdown
                            return "<span class='summary-input'>" + summaryText + "</span>";
                        }
                    }
                }
            }
            // Because this returns HTML we can actually generate an IMG tag with data URLs
            // and put it in here...
        }

        if (this.type === "chat") {
            
            let summary = this.result.title;
            
            return summary;
        }

        return "No summary available.";
    }
}

/* PowerInput encapsulates the inputs to a Power invocation.
 * It can represent inputs to multiple steps, user overrides to
 * the capability settings, and remoting of source data. */
export class PowerInput {
    constructor(power, inputs = null, capabilities = null) {
        this.stepInputs = [];
        this.stepCapabilities = [];

        if (power) {
            for (let i = 0; i < power.steps.length; i++) {
                if (!inputs || inputs[i] == null) {
                    this.stepInputs.push({});
                } else {
                    if (typeof inputs[i] !== "object") {
                        console.error("Step inputs must be objects");
                        throw new Error("Error processing power step");
                    }
                    this.stepInputs.push(inputs[i]);
                }

                if (!capabilities || capabilities[i] == null) {
                    this.stepCapabilities.push(null);
                } else {
                    if (typeof capabilities[i] !== "string") {
                        console.error("Step capabilities must be strings");
                        throw new Error("Error processing power step");
                    }
                    this.stepCapabilities.push(capabilities[i]);
                }
            }
        } else {
            console.error("WARNING: PowerInput created without a Power object");
        }
        console.log("finish PowerInput constructor", JSON.stringify(this));
    }

    /* Given a URLSearchParams, constructs a PowerInput object */
    static fromURL(power, params) {
        if (!power || !(power instanceof Power)) {
            throw new Error("Input 1 to fromURL must be a Power object");
        }
        if (!params || !(params instanceof URLSearchParams)) {
            throw new Error("Input 2 to fromURL must be a URLSearchParams object");
        }
        let pi = new PowerInput(power);
        let startingValues = sanitizeURLParams(params);
        if (Object.keys(startingValues).length == 0) {
            startingValues = undefined;
        }
        console.log("Decoding PowerInput pi=", pi, "startingValues=", startingValues);
        if (startingValues) {
            Object.keys(startingValues).forEach((key) => {
                if (key.startsWith("@cap")) {
                    let idx = Number(key.substring(4));
                    if (!isNaN(idx)) {
                        if (typeof startingValues[key] !== "string") {
                            throw new Error("Capability values must be strings")
                        }
                        let val = startingValues[key];
                        if (val) {
                            pi.stepCapabilities[idx] = val;
                        }
                        delete startingValues[key];
                    }
                } else {
                    let value = startingValues[key];
                    let parts = key.split(";");
                    let idx = 0;
                    if (parts.length > 1) {
                        idx = Number(parts[1]);
                        if (Number.isNaN(idx)) {
                            throw new Error(`Malformed input: key "${key}" has a non-numeric index`);
                        }
                        key = parts[0];
                    }
                    while (idx >= pi.stepInputs.length) {
                        pi.stepInputs.push({});
                    }
                    if (value.startsWith("@remote:")) {
                        pi.stepInputs[idx][key] = {
                            remote: value.substring(8)
                        }
                    } else {
                        pi.stepInputs[idx][key] = value;
                    }
                }
            });
        }
        return pi;
    }

    /* Returns a URLSearchParams object representing the inputs and capabilities */
    toURLParams() {
        let params = new URLSearchParams();
        this.stepInputs.forEach((inputs, idx) => {
            Object.keys(this.stepInputs[idx]).forEach((key) => {
                let value = this.stepInputs[idx][key];

                // Don't encode data URLs
                if (value) {
                    if (typeof value === "string") {
                        if (value.startsWith("data:")) {
                            return;
                        }
                    } else if (typeof value === "object") {
                        // Encode remoted params with a "@remote" prefix
                        if (value.remote) {
                            value = "@remote:" + value.remote;
                        }
                    }
                    if (idx > 0) {
                        key = key + ";" + idx;
                    }
                    params.append(key, value);
                }
            });
        });
        for (let i = 0; i < this.stepCapabilities.length; i++) {
            if (this.stepCapabilities[i]) {
                params.append("@cap" + i, this.stepCapabilities[i]);
            }
        }
        return params;
    }

    getStepInputs() {
        return this.stepInputs;
    }

    // If there is a "seed" parameter within stepInputs, reset it to 0
    resetSeed() {
        this.stepInputs.forEach((inputs, idx) => {
            if (inputs._seed) {
                inputs._seed = 0;
            }
        });
    }


    getStepCapabilities() {
        return this.stepCapabilities;
    }

    // Fetches remote inputs, if any; returns an array of
    // inflated stepInput objects ready for processing.
    async retrieveRemotes(session) {
        // Quick pass: any remotes needed?
        let remotes = [];
        this.stepInputs.forEach((inputObj, idx) => {
            Object.keys(inputObj).forEach((key) => {
                if (inputObj[key].remote) {
                    remotes.push([idx, key]);
                }
            });
        });
        // No remotes, just return and use inputs as is
        if (remotes.length == 0) return this.stepInputs;

        // Fetch the remotes by POST to cex;
        // except for image, which is a direct fetch:
        let remotingPromises = [];
        remotes.forEach(([step, key]) => {
            if (key == "image") {
                remotingPromises.push(fetchImageData(this.stepInputs[step][key].remote));
            } else {
                remotingPromises.push(fetchPageData(this.stepInputs[step][key].remote, session));
            }
        });

        // Build output:
        let outputs = [];
        this.stepInputs.forEach((inputObj, idx) => {
            let output = {};
            outputs[idx] = output;
            Object.keys(inputObj).forEach((key) => {
                if (!inputObj[key].remote) {
                    output[key] = inputObj[key];
                }
            })
        });
        try {
            let results = await Promise.all(remotingPromises);
            for (let i = 0; i < remotes.length; i++) {
                let [idx, key] = remotes[i];
                outputs[idx][key] = results[i];
            }
        } catch (err) {
            console.error("Unable to load data", err);
            throw new Error("Unable to load remote data for power");
        }
        return outputs;
    }
}

/* Helper function: fetch image data from a remote URL and encode it.*/
async function fetchImageData(url) {
    let response = await fetch(url);
    if (!response.ok) {
        console.error("Unable to fetch image", response);
        throw new Error("Unable to fetch image");
    }
    try {
        let blob = await response.blob();
        let reader = new FileReader();
        return new Promise((resolve, reject) => {
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                let imgResize = new ImageResizer(reader.result);
                imgResize.resizeToClosestAspectRatio(IMAGE_RATIOS).then((resizedImage) => {
                    resolve(resizedImage);
                });
            }
        });
    } catch (err) {
        console.error("Unable to fetch image", err);
        throw err;
    }
}

/* Helper function: fetch page data using the CEX service and return it.*/
async function fetchPageData(url, session) {
    let response;
    if (session.extensionIsInstalled() && session.extensionSupportsContentExtraction()) {
        try {
            let result = await session.callExtensionFunction({
                op: "perform-content-extraction",
                url: url
            });
            console.log("Got content from extension", result);
            let content = result.result.data.content;
            if (Array.isArray(content) && content.length == 1) {
                return content[0];
            }
            return "(content not found)";
        } catch (err) {
            console.error("Unable to fetch page", err);
            throw new Error("Unable to fetch page");
        }
    }
    try {
        response = await fetch(import.meta.env.VITE_WISPY_CEX_SERVER_URL + "/content", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "url": url
            })
        });
    } catch (err) {
        console.error("[power] Error fetching page", err);
        throw new Error("Unable to fetch page");
    }
    if (!response.ok) {
        console.error("[power] Error fetching page", response);
        throw new Error("Unable to fetch page");
    }
    let result = await response.json();
    if (result.output?.content) {
        // there's other metadata in output including title, href, and a large "meta" property object
        let content = result.output.content;
        if (Array.isArray(content) && content.length == 1) {
            return content[0];
        }
        // We don't expect any other shape to the output
    }
    return "";
}

// Traverse all values of obj, and when we find a data URL containing
// an image, make it smaller
async function traverseAndDownscale(obj) {
    if (!obj) {
        return;
    }
    if (typeof obj === "object") {
        let keys = Object.keys(obj);
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            let value = obj[key];
            if (typeof value === "object") {
                if (value instanceof Array) {
                    for (let i = 0; i < value.length; i++) {
                        await traverseAndDownscale(value[i]);
                    }
                } else {
                    await traverseAndDownscale(value);
                }
            } else if (typeof value === "string") {
                if (value.startsWith("data:image/")) {
                    // Render and downscale the image
                    try {
                        let scaler = new ImageResizer(value);
                        let smaller = await scaler.rescale(128);
                        obj[key] = smaller;
                        obj[key + "_original"] = value;
                    } catch (err) {
                        console.error(err);
                        obj[key] = "(image data)";
                    }
                }
            }
        }
    }
}



// Traverse all values of obj, and when we find a data URL containing
// an image, remove it
async function traverseAndRemoveImages(obj) {
    if (!obj) {
        return;
    }
    if (typeof obj === "object") {
        if (Array.isArray(obj)) {
            for (let i = 0; i < obj.length; i++) {
                await traverseAndRemoveImages(obj[i]);
            }
        } else {
            let keys = Object.keys(obj);
            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];
                let value = obj[key];
                if (typeof value === "object") {
                    if (value instanceof Array) {
                        for (let i = 0; i < value.length; i++) {
                            await traverseAndRemoveImages(value[i]);
                        }
                    } else {
                        await traverseAndRemoveImages(value);
                    }
                } else if (typeof value === "string") {
                    if (value.startsWith("data:image/")) {
                        obj[key] = "(image data)";
                    }
                }
            }
        }
    }
}

/**
 * Given a URLSearchParams, sanitizes the outputs and returns them in an object
 * Each key-value pair in the object represents a query parameter, where the key is the parameter name and the value is the parameter value.
 * The function sanitizes the parameter names and values using DOMPurify to prevent XSS attacks.
 *
 * @param {string} url - The URL to parse the query parameters from.
 * @returns {object} An object containing the query parameters as key-value pairs.
 */
function sanitizeURLParams(urlSearchParams) {
    const obj = {};
    for (let param of urlSearchParams) {
        if (typeof param[0] === 'string' && typeof param[1] === 'string') {
            const sanitizedKey = DOMPurify.sanitize(param[0]);
            const sanitizedValue = DOMPurify.sanitize(param[1]);
            obj[sanitizedKey] = sanitizedValue;
        }
    }
    return obj;
}
