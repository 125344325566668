import { ConfirmationDialog } from "./confirmation_dialog.js";

import '@material/web/select/outlined-select.js';
import '@material/web/select/select-option.js';

export async function buildModelSelectors(session, container) {

    try {
        if (!session) throw new Error("Session required for buildModelPickerControls");
        let availableModels = await session.getAvailableModels();
        if (!availableModels) throw new Error("No available models");
        let capabilities = await session.getCapabilities();
        if (!capabilities) throw new Error("No capabilities");
        let prefs = await session.getModelPrefs();
        if (!prefs) throw new Error("No preferences");

        capabilities.forEach(async (capability, index) => {
            let wrapper = document.createElement("div");
            wrapper.classList.add("model-picker-wrapper");

            let icon = document.createElement("img");
            icon.src = getCapabilityIcon(capability);
            icon.classList.add("model-picker-icon");
            wrapper.appendChild(icon);

            let label = document.createElement("div");
            label.classList.add("model-picker-label");
            label.innerText = getCapabilityTitle(capability);
            wrapper.appendChild(label);

            container.appendChild(wrapper);

            let modelTable = availableModels[capability];
            if (!modelTable) throw new Error(`No models for ${capability}`);
            let select = document.createElement("md-outlined-select");
            select.classList.add("power-model-picker");
            select.setAttribute("id", `model-picker-${index}`);
            select.setAttribute("name", `model-picker-${index}`);
            select.setAttribute("data-capability", capability);

            let validValues = {};
            for (let model of modelTable.models) {
                let option = document.createElement("md-select-option");
                option.value = `${capability};${model.provider}?model=${model.model}`;
                option.displayText = model.name;
                validValues[option.value] = true;
                let icon = await getProviderIcon(model.provider);
                let headline = document.createElement("div");
                headline.slot = "headline";
                headline.innerHTML = `
    <span class="power-model-option-container">
        <span class="icon"><img src="${icon}" class="power-model-leading-icon"/></span>
        <div class="model">
            <span class="name">${model.name}</span>
            <span class="description">${model.description ?? ""}</span>
        </div>
        <span class="energy">${getModelEnergyRating(session, capability, model.model)}</span>
    </span>
    `;
                option.appendChild(headline);
                select.appendChild(option);
            }
            wrapper.appendChild(select);

            let onModelPrefChange = (newPrefs) => {
                let pval = newPrefs.model_preferences[capability];
                pval = pval.replace(";", "?model=");
                let cap = capability + ";" + pval;
                if (validValues[cap]) {
                    console.log("Setting model to user default", pval);
                    select.value = cap;
                } else {
                    console.error("Warning: unknown capability spec in user preferences", cap);
                }
            };

            // Set the initial values
            onModelPrefChange(prefs);

            // Register a listener for any changes
            session.addModelPrefsChangedListener(onModelPrefChange);

            let localLabel = document.createElement("md-select-option");
            localLabel.innerText = "Ollama Models...";
            localLabel.disabled = true;
            select.appendChild(localLabel);

            select.addEventListener("change", async (e) => {
                let capability = e.target.getAttribute("data-capability");
                let modelPref = e.target.value;
                modelPref = modelPref.replace(capability + ";", "");
                modelPref = modelPref.replace("?model=", ";");
                console.log("Set capability", capability, "to", modelPref);
                let prefs = await session.getModelPrefs();
                prefs.model_preferences[capability] = modelPref;
                await session.setModelPrefs(prefs);

                session.reportInfo(getCapabilityTitle(capability) + " AI Updated");
            });

        });
    } catch (err) {
        console.error(err);
    }
}

export async function resetModelPrefsToDefault(session) {
    const confirmDialog = new ConfirmationDialog({
        title: "Are you sure?",
        text: "Resetting models to default will change the models used in power invocations.",
        confirmText: "Confirm",
        cancelText: "Cancel",
        confirmCallback: async () => {
            console.log("[settings] resetting model preferences to defaults");
            try {
                await session.resetModelPrefsToDefaults();
                session.reportInfo("Model preferences reset to defaults");
            } catch (err) {
                console.error("Unable to reset model preferences to defaults", err);
                session.reportError("Unable to update AI preference. Please try again.");
            }
        },
        cancelCallBack: async () => { }
    });
    confirmDialog.show();
}


export async function buildModelPickerControls(power, session, container, currentStepCapabilities = null, stepIndex = null) {
    try {
        if (!session) throw new Error("Session required for buildModelPickerControls");
        let availableModels = await session.getAvailableModels();
        if (!availableModels) throw new Error("No available models");

        let label1 = document.createElement("div");
        label1.classList.add("model-picker-header-label");
        label1.innerText = power.steps.length > 1 ? "Invoke power with these models" : "Invoke power with this model";
        container.appendChild(label1);

        power.steps.forEach(async (step, index) => {
            if (stepIndex !== null && index !== stepIndex) return;

            let wrapper = document.createElement("div");
            wrapper.classList.add("model-picker-wrapper");

            let icon = document.createElement("img");
            icon.src = getStepIcon(step);
            icon.classList.add("model-picker-icon");
            wrapper.appendChild(icon);

            let label = document.createElement("div");
            label.classList.add("model-picker-label");
            label.innerText = getStepTitle(step);
            wrapper.appendChild(label);

            container.appendChild(wrapper);

            let modelTable = availableModels[step.type];
            if (!modelTable) throw new Error(`No models for ${step.type}`);
            let select = document.createElement("md-outlined-select");
            select.classList.add("power-model-picker");
            select.setAttribute("id", `model-picker-${index}`);
            select.setAttribute("name", `model-picker-${index}`);
            select.setAttribute("data-step", index);
            select.setAttribute("data-power", power.powerID);
            select.setAttribute("data-step-type", step.type);

            let validValues = {};
            for (let model of modelTable.models) {
                let option = document.createElement("md-select-option");
                option.value = `${step.type};${model.provider}?model=${model.model}`;
                option.displayText = model.name;
                validValues[option.value] = true;
                let icon = await getProviderIcon(model.provider);
                let headline = document.createElement("div");
                headline.slot = "headline";
                headline.innerHTML = `
    <span class="power-model-option-container">
        <span class="icon"><img src="${icon}" class="power-model-leading-icon"/></span>
        <div class="model">
            <span class="name">${model.name}</span>
            <span class="description">${model.description ?? ""}</span>
        </div>
        <span class="energy">${getModelEnergyRating(session, step.type, model.model)}</span>
    </span>
    `;
                option.appendChild(headline);
                select.appendChild(option);
            }
            wrapper.appendChild(select);
            if (currentStepCapabilities && currentStepCapabilities[index]) {
                // If the user has overridden the spec, display that:
                if (validValues[currentStepCapabilities[index]]) {
                    console.log("Setting model to input override", currentStepCapabilities[index]);
                    select.value = currentStepCapabilities[index];
                } else {
                    console.error("Ignoring unknown capability spec ", currentStepCapabilities[index]);
                }
            } else if (step.capabilitySpec) {
                // If step provides a default capabilitySpec, use it:
                if (validValues[step.capabilitySpec]) {
                    console.log("Setting model to power override", step.capabilitySpec);
                    select.value = step.capabilitySpec;
                    currentStepCapabilities[index] = step.capabilitySpec;
                } else {
                    console.error("Warning: unknown capability spec in power definition", step.capabilitySpec);
                }
            } else {
                // Set it to the user's default
                let prefs = await session.getModelPrefs();
                let pval = prefs.model_preferences[step.type];
                pval = pval.replace(";", "?model=");
                let cap = step.type + ";" + pval;
                if (validValues[cap]) {
                    console.log("Setting model to user default", pval);
                    select.value = cap;
                    currentStepCapabilities[index] = cap;
                } else {
                    console.error("Warning: unknown capability spec in user preferences", cap);
                }
            }

            let localLabel = document.createElement("md-select-option");
            localLabel.innerText = "Ollama Models...";
            localLabel.disabled = true;
            select.appendChild(localLabel);

            select.addEventListener("change", (e) => {
                let stepIndex = Number(e.target.getAttribute("data-step"));
                let capability = e.target.value;
                console.log("Set capability", stepIndex, "to", capability);
                currentStepCapabilities[stepIndex] = capability;
            });
        });
    } catch (err) {
        console.error(err);
    }
}

/******************************************************************************/
/************************ Model Picker Support ********************************/
/******************************************************************************/

function getModelEnergyRating(session, capability, model) {
    if (session && session.availableModels && session.availableModels[capability]) {
        const modelInfo = session.availableModels[capability].models.find(m => m.model === model);
        if (modelInfo) {
            let energyRating = modelInfo.energyRating || 0;
            let energyIcons = "";
            for (let i = 0; i < energyRating; i++) {
                energyIcons += "<i class='material-icons model-selector-energy_rating_icon'>bolt</i>";
            }
            return energyIcons;
        }
    }
}

async function getProviderIcon(capability) {
    let provider = capability.split(";")[0];
    provider = provider.split("?")[0];

    switch (provider) {
        case "openai":
            return "/assets/models/icon-openai.png";
        case "llama2":
        case "meta":
            return "/assets/models/icon-meta.png";
        case "stability":
            return "/assets/models/icon-stability.png";
        case "mistral":
            return "/assets/models/icon-mistral.png";
        case "anthropic":
            return "/assets/models/icon-anthropic.png";
        case "playground":
            return "/assets/models/icon-playground.png";
        case "gemini":
        case "google":
            return "/assets/models/icon-google.png";
        case "huggingface":
            return "/assets/models/icon-hugginface.png";
        case "zephyr":
        case "deepseek":
            return "/assets/models/icon-default.png";
        default:
            console.error("No matching provider found", capability);
            return "/assets/models/icon-default.png";
    }
}

export function getStepIcon(step) {
    return getCapabilityIcon(step.type);
}
export function getCapabilityIcon(capability) {
    switch (capability) {
        case "audioToText":
            return "../icons/icon-audio2txt.svg";
        case "textToAudio":
            return "../icons/icon-txt2audio.svg";
        case "translate":
            return "../icons/icon-translate.svg";
        case "chatCompletion":
            return "../icons/icon-chat.svg";
        case "textCompletion":
            return "../icons/icon-txt2txt.svg";
        case "textToImage":
            return "../icons/icon-txt2img.svg";
        case "imageToText":
            return "../icons/icon-img2txt.svg";
        case "imageToImage":
            return "../icons/icon-img2img.svg";
        default:
            console.error("Unknown capability type: ", capability);
            return "../icons/icon-txt2txt.svg";
    }
}

export function getStepTitle(step) {
    return getCapabilityTitle(step.type);
}
export function getCapabilityTitle(capability) {
    switch (capability) {
        case "audioToText":
            return "Audio to Text";
        case "textToAudio":
            return "Text to Audio";
        case "translate":
            return "Translate";
        case "chatCompletion":
            return "Chat";
        case "textCompletion":
            return "Text Completion";
        case "textToImage":
            return "Text to Image";
        case "imageToText":
            return "Image to Text";
        case "imageToImage":
            return "Image to Image";
        default:
            console.error("Unknown step type: ", capability);
            return "Unknown capability";
    }
}