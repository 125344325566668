export class ImageResizer {
    constructor(data) {
        this.data = data;
    }

    async rescale(targetMaxDimension) {
        // Create an image element
        const img = new Image();
        try {
            img.src = this.data;
            await img.decode();
            console.log("rescale onLoad");
            // Scale the image so that it is no larger than the target max dimension
            let scale = 1;
            if (img.width > img.height) {
                scale = targetMaxDimension / img.width;
            } else {
                scale = targetMaxDimension / img.height;
            }
            // Create a canvas and resize the image to the closest target size
            const canvas = document.createElement('canvas');
            canvas.width = img.width * scale;
            canvas.height = img.height * scale;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

            // Return the resized image as a data URL
            return canvas.toDataURL();
        } catch(err) {
            console.log("rescale error", error);
            throw err;
        }
    }

    resizeToClosestAspectRatio(targetSizes) {
        return new Promise((resolve, reject) => {
            // Create an image element
            const img = new Image();
            img.src = this.data;

            img.onload = () => {
                // Calculate the aspect ratio of the original image
                const originalAspectRatio = img.width / img.height;

                // Find the target size with the closest aspect ratio
                let closestSize = targetSizes[0];
                let minAspectRatioDifference = Math.abs((closestSize[0] / closestSize[1]) - originalAspectRatio);

                targetSizes.forEach(size => {
                    const aspectRatio = size[0] / size[1];
                    const aspectRatioDifference = Math.abs(aspectRatio - originalAspectRatio);
                    if (aspectRatioDifference < minAspectRatioDifference) {
                        closestSize = size;
                        minAspectRatioDifference = aspectRatioDifference;
                    }
                });

                // Create a canvas and resize the image to the closest target size
                const canvas = document.createElement('canvas');
                canvas.width = closestSize[0];
                canvas.height = closestSize[1];
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                console.log("Resized " + img.width + ":" + img.height + " image from ",
                    originalAspectRatio, "to",
                    closestSize[0] + ":" + closestSize[1],
                    (closestSize[0] / closestSize[1]));

                // Return the resized image as a data URL
                resolve(canvas.toDataURL());
            };

            img.onerror = (error) => {
                reject("Failed to load image: " + error);
            };
        });
    }
}

/*img.onload = () => {
                console.log("rescale onLoad");
                // Scale the image so that it is no larger than the target max dimension
                let scale = 1;
                if (img.width > img.height) {
                    scale = targetMaxDimension / img.width;
                } else {
                    scale = targetMaxDimension / img.height;
                }
                // Create a canvas and resize the image to the closest target size
                const canvas = document.createElement('canvas');
                canvas.width = img.width * scale;
                canvas.height = img.height * scale;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                // Return the resized image as a data URL
                resolve(canvas.toDataURL());
            };*/