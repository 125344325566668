import '../css/membership.css';

let googlePayEnabled = false;

export async function init_membership_panel(session) {

    if ('getDigitalGoodsService' in window) {
        try {
            const service = await window.getDigitalGoodsService('https://play.google.com/billing');
            console.log("getDigitalGoodsService success: ", service);
            googlePayEnabled = true;
        } catch (error) {
            console.log("getDigitalGoodsService error: ", error);
            googlePayEnabled = false;
        }
    } else {
        console.log("getDigitalGoodsService not available");
        googlePayEnabled = false;
    }

    const membershipCloseButton = document.getElementById("membership-close-button");
    membershipCloseButton.addEventListener("click", (e) => {
        e.preventDefault();
        hide_membership_panel();
    });

    const membershipCancelButton = document.getElementById("membership-cancel-button");
    membershipCancelButton.addEventListener("click", (e) => {
        e.preventDefault();
        hide_membership_panel();
    });

    const membershipSubscribeButton = document.getElementById("membership-subscribe-button");
    membershipSubscribeButton.addEventListener("click", async (e) => {
        e.preventDefault();
        let buttonLabel = membershipSubscribeButton.innerHTML;
        membershipSubscribeButton.innerHTML = "Loading...  ";
        let spinner = document.createElement("div");
        spinner.className = "mdl-spinner mdl-js-spinner is-active";
        membershipSubscribeButton.appendChild(spinner);
        componentHandler.upgradeElement(spinner);
        try {
            if (googlePayEnabled === true) {
                show_google_subscription_options();
            } else {
                let stripeCheckoutURL = await session.getStripeUpdateURL();
                window.open(stripeCheckoutURL, '_blank');
            }
        } catch (error) {
            membershipSubscribeButton.removeChild(spinner);
            membershipSubscribeButton.innerHTML = buttonLabel;
            console.error('An error occurred:', error);
            session.reportError(`${error.message}`);
        } finally {
            membershipSubscribeButton.removeChild(spinner);
            membershipSubscribeButton.innerHTML = buttonLabel;
        }
    });

    const membershipManageButton = document.getElementById("membership-manage-button");
    membershipManageButton.addEventListener("click", async (e) => {
        e.preventDefault();
        let buttonLabel = membershipManageButton.innerHTML;
        membershipManageButton.innerHTML = "Loading...  ";
        let spinner = document.createElement("div");
        spinner.className = "mdl-spinner mdl-js-spinner is-active";
        membershipManageButton.appendChild(spinner);
        componentHandler.upgradeElement(spinner);
        try {
            let url = "";
            if (googlePayEnabled === true) {
                url = "https://play.google.com/store/account/subscriptions?sku=technical_magic&package=ai.technicalmagic.wispy_2.twa";
            } else {
                url = await session.getStripePortalURL();
            }
            window.open(url, '_blank');
        } catch (error) {
            membershipManageButton.removeChild(spinner);
            membershipManageButton.innerHTML = buttonLabel;
            console.error('An error occurred:', error);
            session.reportError(`${error.message}`);
        } finally {
            membershipManageButton.removeChild(spinner);
            membershipManageButton.innerHTML = buttonLabel;
        }
    });
}

async function show_google_subscription_options(session) {

    try {
        if (googlePayEnabled === true) {

            const service = await window.getDigitalGoodsService('https://play.google.com/billing');
            const itemDetails = await service.getDetails(['technical_magic']);
            let subscriptionItem = itemDetails.find(item => item.itemId === 'technical_magic');
            const paymentMethodData = [
                {
                    supportedMethods: 'https://play.google.com/billing',
                    data: {
                        sku: subscriptionItem.itemId,
                    },
                },
            ];

            const request = new PaymentRequest(paymentMethodData);
            const paymentResponse = await request.show();

            async function validateTWASubscription(purchaseToken, itemId) {
                const API_SERVER_URL = import.meta.env.VITE_WISPY_ACCOUNTS_API_SERVER_URL;

                try {
                    // Get the access token
                    let accessToken = await this.authClient.getAccessToken();

                    // Construct the fetch URL
                    let fetchURL = `${API_SERVER_URL}/google-play/subscription"`;

                    // Send the request to the server
                    let result = await fetch(fetchURL, {
                        method: "POST",
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({ purchaseToken, itemId }),
                    });

                    // Handle the response based on the status code
                    switch (result.status) {
                        case 200:
                            return true;
                        case 400:
                            let error = await result.json();
                            console.error("Error validating purchase: ", error);
                            session.reportError(`${error.error}`);
                            return false;
                        default:
                            let defaultError = await result.json();
                            console.error("Unexpected error validating purchase: ", defaultError);
                            session.reportError(`Unexpected error: ${defaultError.error}`);
                            return false;
                    }
                } catch (error) {
                    // Handle network errors
                    console.error("Network error validating purchase: ", error);
                    session.reportError(`Network error: ${error.message}`);
                    return false;
                }
            }

            const { purchaseToken } = paymentResponse.details;

            let paymentComplete;
            if (await validateTWASubscription(purchaseToken, subscriptionItem.itemId)) {
                paymentComplete = await paymentResponse.complete('success');
                console.log("payment successful");
                session.reportInfo("Subscription purchase successful");
            } else {
                paymentComplete = await paymentResponse.complete('fail');
                console.log("payment failed");
                session.reportError("Subscription purchase failed");
            }

        }
    } catch (error) {

        if (googlePayEnabled === true) {
            const RESULT_CANCELED = 20;

            if (error.code === RESULT_CANCELED) {
                membershipSubscribeButton.removeChild(spinner);
                membershipSubscribeButton.innerHTML = buttonLabel;
                console.log("User canceled the payment dialog");
                session.reportInfo("Cancelled subscription purchase");
                return;
            }

        }

        console.error('An error occurred:', error);
        session.reportError(`${error.message}`);
    }
}

export async function display_membership_panel(session) {

    // Fetch user subscription status
    let subscriptions = await session.getSubscriptions();
    if (!subscriptions || !subscriptions.subscriptions || subscriptions.subscriptions.length === 0) {
        // User has no active subscription
        document.getElementById('membership-detail').innerHTML = `You are not currently subscribed to any plan. Start your membership today to invoke AI powers with Wispy!`;
        document.getElementById('membership-subscribe-button').textContent = "Start Your Membership";
        document.getElementById('membership-manage-button').style.display = "none";
        document.getElementById('membership-panel').showModal();
        return;
    }
    let energyUsage = await session.getEnergyUsage();
    if (!energyUsage) {
        throw new Error("Unable to fetch user energy usage");
    }

    let remainingEnergyPct = 100 - (energyUsage.usage?.percent_used || 0);

    let periodEnd = new Date(subscriptions.subscriptions[0].period_end * 1000);
    let now = new Date();
    let differenceInTime = periodEnd.getTime() - now.getTime();
    let differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
    let remainingEnergy = Math.max(0, Math.round(subscriptions.subscriptions[0]?.monthly_energy * (remainingEnergyPct / 100)));
    let monthlyEnergy = subscriptions.subscriptions[0]?.monthly_energy;
    let status = subscriptions.subscriptions[0]?.status;

    const energyMeter = document.getElementById('wispy-energy');
    energyMeter.MaterialProgress.setProgress(remainingEnergyPct);

    if (subscriptions.subscriptions[0]?.plan_id === import.meta.env.VITE_WISPY_STRIPE_PREMIUM_PRICE_ID ||
        subscriptions.subscriptions[0]?.plan_id === import.meta.env.VITE_WISPY_STRIPE_ELITE_PRICE_ID) {

        document.getElementById('membership-detail').innerHTML = `
            ${remainingEnergyPct <= 0 ? "<h2>You've run out of energy!</h2><h4>Upgrade your plan to continue invoking powers with Wispy today</h4>" : ""}
            <span class="membership-heading">CURRENT PLAN</span>
            <span class="membership-name">${subscriptions.subscriptions[0]?.plan_id === import.meta.env.VITE_WISPY_STRIPE_ELITE_PRICE_ID ? "Technical Magic Elite" : "Technical Magic"} ${status === "trialing" ? " (Trial)" : ""}</span>
            <span class="membership-heading">ENERGY</span>
            <span class="membership-energy">${remainingEnergy.toLocaleString()} / ${monthlyEnergy.toLocaleString()}</span>
            <span class="membership-heading">${status === "trialing" ? "FREE TRIAL" : "REFRESH"}</span>
            <span class="membership-refresh">${status === "trialing" ? `${differenceInDays} Day${differenceInDays === 1 ? "" : "s"} Remaining` : `In ${differenceInDays} Day${differenceInDays === 1 ? "" : "s"}`} </span>
        `;
        document.getElementById('membership-subscribe-button').textContent = remainingEnergyPct <= 0 ? "Upgrade Your Plan" : "Change Your Plan";
        document.getElementById('membership-manage-button').style.display = "block";
    } else {
        if (remainingEnergyPct <= 0) {
            document.getElementById('membership-detail').innerHTML = `
            <h2>Your Free Trial has ended.</h2><h4>Become a Technical Magic member to continue invoking powers with Wispy today!</h4>
            <span class="membership-offer-name">Technical Magic</span>
            <span class="membership-offer-price">USD $4.99/month</span>
            <span class="membership-offer-features">
                <span>Access to Wispy with <i class="material-icons">bolt</i>5,000,000 Energy monthly allowance to invoke AI powers.
            </span>
            `;
        } else {
            document.getElementById('membership-detail').innerHTML = `
            <span class="membership-heading">CURRENT PLAN</span>
            <span class="membership-name">Free Trial</span>
            <span class="membership-heading">ENERGY</span>
            <span class="membership-energy">${remainingEnergy.toLocaleString() + " / 100,000 to Invoke Powers"}</span>
        `;
        }
        document.getElementById('membership-subscribe-button').textContent = "Start Your Membership";
        document.getElementById('membership-manage-button').style.display = "none";
    }

    document.getElementById('membership-panel').showModal();
}

export function hide_membership_panel() {
    document.getElementById('membership-panel').close();
}