import { createAuth0Client } from '@auth0/auth0-spa-js';

export class WispyAuthClient {
  constructor() {
    this.auth0Client = null;
    this.authAudience = import.meta.env.VITE_WISPY_AUTH0_AUDIENCE
  }

  async initialize() {
    this.auth0Client = await createAuth0Client({
      domain: import.meta.env.VITE_WISPY_AUTH0_DOMAIN,
      clientId: import.meta.env.VITE_WISPY_AUTH0_CLIENT_ID,
      cacheLocation: "localstorage",
      useRefreshTokens: true,
      authorizationParams: {
        redirect_uri: window.location.origin,
        audience: this.authAudience,
        scope: "openid email",
      }
    }).catch((err) => {
      console.log(err);
      throw(err);
    });
  }

  async getAccessToken(ignoreCache = false) {
    console.log("fetching access token", ignoreCache);
    return await this.auth0Client.getTokenSilently({
      authorizationParams: {
        audience: this.authAudience,
        scope: "openid email",
      },
      cacheMode: ignoreCache ? "off" : "on",
    }).catch(async (err) => {
      console.log(err);
      throw(err);

      if (err.error === "login_required") {
        await this.login();
        // XXX loginWithPopup?
      }
    });
  }

  async isAuthenticated() {
    return await this.auth0Client.isAuthenticated().catch((err) => {
      console.log(err);
    });
  }

  async handleRedirectCallback() {
    try {
      const result = await this.auth0Client.handleRedirectCallback();
      return result;
    } catch (err) {
      console.log(err);
      // Rethrow the error to ensure that the calling function can handle it
      throw err;
    }
  }

  async signup(return_uri) {
    console.log("attemping signup");
    await this.auth0Client.loginWithRedirect({
      authorizationParams: {
        prompt: "login",
        redirect_uri: return_uri || window.location.origin,
      },
      appState: { returnToPath: window.location.pathname + window.location.search }
    }).catch((err) => {
      console.log(err);
    });
  }

  async login(return_uri) {
    console.log("attemping login");
    await this.auth0Client.loginWithRedirect({
      authorizationParams: { 
        prompt: "login",
        redirect_uri: return_uri || window.location.origin,
    },
      appState: { returnToPath: window.location.pathname + window.location.search }
    }).catch((err) => {
      console.log(err);
    });
  }

  async logout() {
    await this.auth0Client.logout({
      logoutParams: {
        returnTo: window.location.origin,
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  async getUser() {
    return await this.auth0Client.getUser().catch((err) => {
      console.log(err);
    });
  }

  async getIdTokenClaims() {
    return await this.auth0Client.getIdTokenClaims().catch((err) => {
      console.log(err);
    });
  }
}