import { WispyAuthClient } from '../../shared/js/auth.js';
import { WispyAlertManager } from './alert.js';
import { PowerRegistry, PowerInvocation, PowerInvocationHistory } from './power.js';
// ua-parser-js
import { UAParser } from 'ua-parser-js';

export class UserSession {
    constructor(data) {
        console.log("Creating user session");

        // References to shared utility objects:
        this.authClient = new WispyAuthClient();
        this.alertManager = new WispyAlertManager();
        this.powerRegistry = new PowerRegistry();
        this.powerHistory = new PowerInvocationHistory();
        this.extensionResponseHandlers = {};

        // User preferences for models to use for each capability
        this.modelPrefs = null;
        this.onModelPrefsChanged = [];

        // AI models available to this user
        this.availableModels = null;

        // Stripe portal URL
        this.stripePortalURL = null;
        this.stripeUpdateURL = null;

        // User agent detail
        this.userAgent = new UAParser().getResult();

        // If we have any saved power invocation records, inflate them now.
        // If any of them fail to inflate, discard them.
        this.powerInvocations = [];
        if (data && data.powerInvocations) {
            this.powerInvocations = data.powerInvocations.map((powerInvocationData) => {
                try {
                    return new PowerInvocation(powerInvocationData);
                } catch (err) {
                    return null;
                }
            }).filter((x) => x !== null);
        }

        let self = this;
        document.addEventListener("technical-magic-query-response", function (e) {
            console.log("Got response from extension", e);
            const { responseId, responseData } = e.detail;

            if (e.detail.streaming) {
                if (self.extensionResponseHandlers[responseId]) {
                    if (self.extensionResponseHandlers[responseId].stream) {
                        self.extensionResponseHandlers[responseId].stream(responseData.data, responseData.done);
                    } else {
                        console.log("Warning: got streaming response but no stream handler")
                    }
                }
                // do not delete, more is coming
            } else {
                if (self.extensionResponseHandlers[responseId]) {
                    self.extensionResponseHandlers[responseId].done(responseData);
                    delete self.extensionResponseHandlers[responseId];
                }
            }
        });
    }

    isMobileOrTablet() {
        return false;
        /// disabled for now
        return (this.userAgent.device.type === "mobile" || this.userAgent.device.type === "tablet");
    }

    async login(returnToPath) {
        if (returnToPath) {
            if (returnToPath.startsWith("/")) {
                await this.authClient.login(window.location.origin + returnToPath);
            } else {
                await this.authClient.login(window.location.origin + "/" + returnToPath);
            }
        } else {
            await this.authClient.login(window.location.origin);
        }
    }

    async signup(returnToPath) {
        if (returnToPath) {
            if (returnToPath.startsWith("/")) {
                await this.authClient.signup(window.location.origin + returnToPath);
            } else {
                await this.authClient.signup(window.location.origin + "/" + returnToPath);
            }
        } else {
            await this.authClient.signup(window.location.origin);
        }
    }

    async initializeAuth() {
        let result;
        try {
            result = await this.authClient.initialize();
        } catch (err) {
            console.error(err);
            throw new Error("Unable to initialize authentication");
        }
        return result;
    }

    extensionIsInstalled() {
        if (document.documentElement.getAttribute('technical-magic-installed')) return true;
        return false;
    }

    extensionSupportsContentExtraction() {
        return this.extensionIsInstalled() && (window.navigator.userAgent.includes("Chrome"));
    }

    callExtensionFunction(data, streamHandler) {
        if (document.documentElement.getAttribute('technical-magic-installed')) {
            console.log("Extension is installed - dispatching event", data);
            return new Promise((resolve, reject) => {
                const responseId = this.generateUniqueID();
                this.extensionResponseHandlers[responseId] = {
                    done: resolve
                };
                if (streamHandler) {
                    this.extensionResponseHandlers[responseId].stream = streamHandler;
                }
                var event = new CustomEvent("technical-magic-query", { detail: { data, responseId } });
                document.dispatchEvent(event);
            });
        } else {
            console.log("Extension is not installed; ignoring event", data);
            return null;
        }
    }

    generateUniqueID() {
        return Math.random().toString(36).substring(2, 15);
    }

    // Fetch the list of available AI models for this user profile
    async getAvailableModels() {
        if (this.availableModels) {
            return this.availableModels;
        }

        const accessToken = await this.authClient.getAccessToken();
        console.log("getAvailableModels path ", import.meta.env.VITE_WISPY_API_SERVER_URL + '/ai/models');
        let result;
        try {
            result = await fetch(`${import.meta.env.VITE_WISPY_API_SERVER_URL}/ai/models`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json"
                },
            });
        } catch (err) {
            console.error(err);
            throw new Error("Unable to fetch available AI models");
        }
        if (!result.ok) {
            throw new Error(`Unable to fetch available AI models: ${result.status} ${result.statusText}`);
        }
        this.availableModels = await result.json();

        // See if the extension is installed and supports any capabilities
        let extensionResult = await this.callExtensionFunction({ op: "get-capabilities" });
        if (extensionResult) {
            console.log("Got extension capabilities", extensionResult);
            Object.keys(extensionResult).forEach((capType) => {
                extensionResult[capType].forEach((cap) => {
                    if (!this.availableModels[capType].providers.includes(cap.provider)) {
                        this.availableModels[capType].providers.push(cap.provider);
                    }
                    this.availableModels[capType].models.push(cap);
                });
            });
        }
        console.log("getAvailableModels returning with ", this.availableModels);
        return this.availableModels;
    }

    async getCapabilities() {
        return ["chatCompletion", "textCompletion", "textToImage", "imageToText", "imageToImage"];
    }

    async submitFeedback(feedback) {
        const accessToken = await this.authClient.getAccessToken();
        console.log("submitFeedback path ", import.meta.env.VITE_WISPY_API_SERVER_URL + '/feedback');
        let result;
        try {
            result = await fetch(import.meta.env.VITE_WISPY_API_SERVER_URL + '/feedback', {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(feedback),
            });
        } catch (err) {
            console.error(err);
            throw new Error("Unable to submit feedback");
        }
        if (!result.ok) {
            throw new Error(`Unable to submit feedback: ${result.status} ${result.statusText}`);
        }
    }

    async getModelPrefs() {
        if (this.modelPrefs) {
            return this.modelPrefs
        }
        let accessToken = await this.authClient.getAccessToken();
        console.log("getModelPrefs path ", import.meta.env.VITE_WISPY_ACCOUNTS_API_SERVER_URL + '/modelPreferences');
        let result;
        try {
            result = await fetch(import.meta.env.VITE_WISPY_ACCOUNTS_API_SERVER_URL + '/modelPreferences', {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json"
                },
            });
        } catch (err) {
            console.error(err);
            throw new Error("Unable to fetch model preferences");
        }
        if (!result.ok) {
            throw new Error(`Unable to fetch AI model preferences: ${result.status} ${result.statusText}`);
        }
        this.modelPrefs = await result.json();
        return this.modelPrefs;
    }

    async setModelPrefs(modelPrefs) {
        const accessToken = await this.authClient.getAccessToken();
        console.log("setModelPrefs path ", import.meta.env.VITE_WISPY_ACCOUNTS_API_SERVER_URL + '/modelPreferences');
        let result;
        try {
            result = await fetch(import.meta.env.VITE_WISPY_ACCOUNTS_API_SERVER_URL + '/modelPreferences', {
                method: "PATCH",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(modelPrefs),
            });
            console.log(result);
        } catch (err) {
            console.error(err);
            throw new Error("Unable to save model preferences");
        }
        if (!result.ok) {
            throw new Error("Unable to save model preferences");
        }

        this.modelPrefs = await result.json();

        this.onModelPrefsChanged.forEach((listener) => {
            listener(this.modelPrefs);
        });

        return this.modelPrefs;
    }

    async resetModelPrefsToDefaults() {
        const accessToken = await this.authClient.getAccessToken();
        console.log("getModelPrefs path ", import.meta.env.VITE_WISPY_ACCOUNTS_API_SERVER_URL + '/modelPreferences/setDefault');
        let result;
        try {
            result = await fetch(import.meta.env.VITE_WISPY_ACCOUNTS_API_SERVER_URL + '/modelPreferences/setDefault', {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json"
                },
            });
        } catch (err) {
            console.error(err);
            throw new Error("Unable to reset model preferences to defaults");
        }
        if (!result.ok) {
            throw new Error("Unable to reset model preferences to defaults");
        }

        this.modelPrefs = await result.json();

        this.onModelPrefsChanged.forEach((listener) => {
            listener(this.modelPrefs);
        });

        return this.modelPrefs;
    }

    addModelPrefsChangedListener(listener) {
        this.onModelPrefsChanged.push(listener);
    }

    removeModelPrefsChangedListener(listener) {
        this.onModelPrefsChanged = this.onModelPrefsChanged.filter((l) => l !== listener);
    }

    reportInfo(message) {
        this.alertManager.info(message);
    }

    reportWarn(message) {
        this.alertManager.warn(message);
    }

    reportError(message) {
        this.alertManager.error(message);
    }

    async isAuthenticated() {
        return await this.authClient.isAuthenticated();
    }

    async getUserProfile() {
        if (await this.isAuthenticated()) {
            return await this.authClient.getUser();
        } else {
            return null;
        }
    }

    async getEnergyUsage() {
        let accessToken = await this.authClient.getAccessToken();
        console.log("getEnergyUsage path ", import.meta.env.VITE_WISPY_ACCOUNTS_API_SERVER_URL + '/energyUsage');
        let result = await fetch(import.meta.env.VITE_WISPY_ACCOUNTS_API_SERVER_URL + '/energyUsage', {
            method: "GET",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json"
            },
        });
        console.log("[power] fetch energy response", result)
        if (!result.ok) {
            throw new Error("Unable to fetch energy usage");
        }
        let data = await result.json();
        console.log("[power] fetch energy data", data);
        return data;
    }

    async getSubscriptions() {
        let accessToken = await this.authClient.getAccessToken();
        console.log("getSubscriptions path ", import.meta.env.VITE_WISPY_ACCOUNTS_API_SERVER_URL + '/subscriptions');
        let result = await fetch(import.meta.env.VITE_WISPY_ACCOUNTS_API_SERVER_URL + '/subscriptions', {
            method: "GET",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json"
            },
        });
        console.log("[power] fetch subscriptions response", result)
        if (!result.ok) {
            throw new Error("Unable to fetch subscriptions");
        }
        let data = await result.json();
        console.log("[power] fetch subscriptions data", data);
        return data;
    }

    async getStripePortalURL() {
        if (this.stripePortalURL) {
            console.log("Returning cached Stripe portal URL");
            return this.stripePortalURL;
        }

        const FALLBACK_URL = "https://billing.stripe.com/p/login/eVabLw3okbxo0TedQQ";
        const STRIPE_PORTAL_LINK_PATH = '/stripe-portal-link';
        const API_SERVER_URL = import.meta.env.VITE_WISPY_ACCOUNTS_API_SERVER_URL;

        let accessToken = await this.authClient.getAccessToken();
        let fetchURL = `${API_SERVER_URL}${STRIPE_PORTAL_LINK_PATH}?return_url=${encodeURIComponent(window.location.href)}`;
        console.log("getStripeURL path ", fetchURL);
        let result = await fetch(fetchURL, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json"
            },
        });

        // If result is 404, inform user and log them out to login back in
        if (result.status === 404) {
            // window.alert("Your account has been disabled. Please log out and log back in to re-enable your account.");
            await this.authClient.logout();
            await this.authClient.login(window.location.origin);
            return;
        }


        if (!result.ok) {
            throw new Error(`Unable to fetch Stripe portal URL. Status: ${result.status} ${result.statusText}`);
        }

        const data = await result.json();
        this.stripePortalURL = data.url ?? FALLBACK_URL;
        console.log("Stripe portal URL set", this.stripePortalURL);

        return this.stripePortalURL;
    }

    async getStripeUpdateURL() {
        if (this.stripeUpdateURL) {
            console.log("Returning cached Stripe update URL");
            return this.stripeUpdateURL;
        }

        const FALLBACK_URL = "https://billing.stripe.com/p/login/eVabLw3okbxo0TedQQ";
        const STRIPE_UPDATE_LINK_PATH = '/stripe-update-link';
        const API_SERVER_URL = import.meta.env.VITE_WISPY_ACCOUNTS_API_SERVER_URL;

        let accessToken = await this.authClient.getAccessToken();
        let fetchURL = `${API_SERVER_URL}${STRIPE_UPDATE_LINK_PATH}?return_url=${encodeURIComponent(window.location.href)}`;
        console.log("getStripeURL path ", fetchURL);
        let result = await fetch(fetchURL, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json"
            },
        });

        if (result.status === 404) {
//            window.alert("Your account has been disabled. Please log out and log back in to re-enable your account.");
            await this.authClient.logout();
            await this.authClient.login(window.location.origin);
            return;
        }

        if (!result.ok) {
            throw new Error(`Unable to fetch Stripe update URL. Status: ${result.status} ${result.statusText}`);
        }

        const data = await result.json();
        this.stripeUpdateURL = data.url ?? FALLBACK_URL;
        console.log("Stripe update URL set", this.stripeUpdateURL);

        return this.stripeUpdateURL;
    }

    async refreshPowers() {
        try {
            await this.powerRegistry.load(this);
            console.log("[power] refreshed powers");
        } catch (err) {
            console.error(err);
            // this.reportError("Unable to refresh powers");
            throw err;
        }
    }

    getPowerByID(power_id) {
        return this.powerRegistry.getPowerByID(power_id);
    }

    getAllPowers() {
        return this.powerRegistry.all();
    }

    // XXXX this is a placeholder for now
    async saveChatPowerInvocation(power, chatLog) {
        let request = {
            "powerID": power.powerID,
            "type": "chat",
            "seq": chatLog.id,
        };

        let result = {
            "id": chatLog.id,
            "title": chatLog.title,
            "history": chatLog.history,
            "lastupdate": chatLog.lastUpdate,
        };

        let keys = await this.powerHistory.getKeys(power.powerID);
        let key = `invoke:${power.powerID}:${chatLog.id}`;
        
        if (keys.includes(key)) {
            let invocation = await this.powerHistory.get(key);
            invocation.result = result;
            await this.powerHistory.update(invocation, this.authClient);
            console.log("updated record", key, invocation.result);
        } else {
            let invocation = new PowerInvocation(request);
            invocation.result = result;
            await this.powerHistory.insert(invocation, this.authClient);
            console.log("new record", key, invocation, result);
        }
    }

    /*
    * Invoke the power with the given data.
    *
    * power: the Power object
    * input: a PowerInput object encoding the inputs and configuration, and the Capabilities to use
    * streamReceiver: function - if provided, streaming results will be sent to it; the results are just the output string
    */
    async invokePower(power, input, streamReceiver) {
        let expectedType = power.expectedOutputType();
        let stream = streamReceiver ? true : false;
        if (!power) {
            throw new Error("No power given to invokePower");
        }
        if (!input) {
            throw new Error("No input given to invokePower");
        }
        // Check whether I/O is needed for any inputs:
        let preparedInputs;
        try {
            preparedInputs = await input.retrieveRemotes(this);
        } catch (err) {
            console.error("Error retrieving inputs", err);
            throw err;
        }

        let request = {
            "powerID": power.powerID,
            "inputs": preparedInputs,
            "stepCapabilities": input.getStepCapabilities(),
            "step": 0,// we always start at step 0
            "stream": stream,
            "type": "power",
        };

        let powerInvocation = new PowerInvocation(request);
        this.powerInvocations.push(powerInvocation);

        let response;
        let provider;
        let customStreamHandler;
        let stepCapabilities = input.getStepCapabilities();
        console.log("StepCapabilities is ", stepCapabilities)
        if (stepCapabilities && stepCapabilities.length > 0) {
            if (stepCapabilities[0] && typeof stepCapabilities[0] === "string") {
                let parts = stepCapabilities[0].split(";");
                if (parts.length > 1) provider = parts[1];
            }
        }
        if (provider && provider.startsWith("local-")) {
            if (power.steps.length > 1) {
                throw new Error("Local providers do not support multi-step powers");
            }
            let prompt = power.generatePrompt(0, preparedInputs[0]);
            request.inputs[0].prompt = prompt; //XXXX this is problematic
            let responseBody = await this.callExtensionFunction(
                { op: "invoke-capability", request: request },
                (data, done, model) => {
                    streamReceiver(data, null, null);
                }
            );
            console.log("Got invoke-capability response from extension", responseBody);
            if (responseBody.error) {
                powerInvocation.error = responseBody.error;
                throw new Error("Error contacting local provide runtime");
            }
            response = {
                ok: true,
                json: async () => responseBody
            };
            stream = false;
        } else {
            let server = import.meta.env.VITE_WISPY_API_SERVER_URL;
            console.log(server + "/summon", request);

            try {
                console.log("Bearer token is ", await this.authClient.getAccessToken());
                let headers = {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + await this.authClient.getAccessToken()
                };
                if (stream) {
                    headers["Accept"] = "text/event-stream";
                }
                response = await fetch(server + '/summon', {
                    method: "POST",
                    headers: headers,
                    body: JSON.stringify(request),
                });
            } catch (err) {
                console.error("[power] Error contacting server for power", err);
                powerInvocation.error = err.message;
                throw new Error("Error contacting server");
            }
        }
       
        if (!response.ok) {
            let data = await response.json();
            console.error("Error response", data, response);
            throw new Error(data.error || "unknown_error");
        }

        if (stream) {
            console.log("Beginning streaming response", response);
            // Handle streaming response with server-sent events:

            try {
                const reader = response.body.pipeThrough(new TextDecoderStream()).getReader();

                let dataBuffer = "";
                while (true) {
                    // NB a single read call could contain more than one event.
                    // Also, we could get a short read.  Don't dispatch events until
                    // we get a valid parse from the data payload.
                    const { value, done } = await reader.read();
                    console.log("Read stream payload=", value?.length, "done=", done);
                    if (customStreamHandler) {
                        let customDone = customStreamHandler.read(value, done);
                        if (customDone) {
                            let result = {
                                output: customStreamHandler.final()
                            }
                            powerInvocation.result = result;
                            await this.powerHistory.insert(powerInvocation, this.authClient);
                            return result;
                        }
                        continue;
                    }
                    if (done) {
                        streamReceiver(null, true, null);
                        break;
                    }

                    dataBuffer += value;
                    while (true) {
                        let eom = dataBuffer.indexOf("\n\n");
                        if (eom < 0) { // can't find EOM; save it for next time
                            break;
                        }
                        // Consume event off buffer
                        let event = dataBuffer.substring(0, eom);
                        dataBuffer = dataBuffer.substring(eom + 2);

                        // Parse it
                        let lines = event.split("\n");
                        let eventType, data;
                        for (let i = 0; i < lines.length; i++) {
                            let line = lines[i];
                            if (line.startsWith("event: ")) {
                                eventType = line.substring(7);
                            } else if (line.startsWith("data: ")) {
                                data = line.substring(6);
                            }
                        }
                        console.log("Got event type=", eventType, "data=", data);
                        if (eventType === "data") {
                            try {
                                let parsed = JSON.parse(data);
                                console.log("Parsed event", parsed);
                                if (parsed.success) {
                                    if (parsed.data?.output) {
                                        streamReceiver(parsed.data.output, false, parsed.data.model);
                                    } else {
                                        console.log("[power] Warning: no data.output in data event", parsed);
                                    }
                                } else if (parsed.error) {
                                    console.error("[power] Error invoking power", parsed.error, parsed);
                                    powerInvocation.error = parsed.error?.message ? parsed.error.message : parsed.error;
                                    throw new Error("Server error: " + parsed.error?.message ? parsed.error.message : parsed.error);
                                }
                            } catch (err) {
                                console.error("[power] Error parsing stream response", err);
                                break;
                                //XXXX should we keep going?
                            }
                        } else if (eventType === "result") {
                            let parsed;
                            try {
                                parsed = JSON.parse(data);
                                console.log("Parsed result", parsed);
                            } catch (err) {
                                console.error("[power] Error parsing stream response", err);
                                return;
                            }

                            parsed.timestamp = Date.now();

                            if (parsed.output.success) {
                                powerInvocation.result = parsed;
                                console.log("[power] invokePower (streaming) returning with ", parsed);
                                await this.powerHistory.insert(powerInvocation, this.authClient);
                                return parsed;
                            } else if (parsed.error) {
                                console.error("[power] Error invoking power", parsed.error, parsed);
                                powerInvocation.error = parsed.error;
                                await this.powerHistory.insert(powerInvocation);
                                throw new Error(parsed.error?.message);
                            } else {
                                console.log("[power] Warning: no error or success in result event", parsed)

                                // XXX should we record the event in this case?
                                await this.powerHistory.insert(powerInvocation, this.authClient);
                                throw new Error("Unexpected EOF");
                            }
                            // else... what?

                        } else if (eventType === "error") {
                            let parsed;
                            try {
                                parsed = JSON.parse(data);
                                console.error("[power] Error invoking power", parsed.error, parsed);
                            } catch (err) {
                                console.error("[power] Error parsing stream response", err);
                                if (err.message) {
                                    this.alertManager.error("Server error while invoking power: " + err.message);
                                } else {
                                    this.alertManager.error("Server error while invoking power");
                                }
                                return;
                            }
                            powerInvocation.error = parsed.error;
                            await this.powerHistory.insert(powerInvocation);
                            throw new Error(parsed.error?.message);
                        }
                    }
                }
            } catch (err) {
                console.error(err);
                this.alertManager.error("Server error while invoking power: " + err.message);
                return null;
            }
        } else {
            let result = await response.json();
            console.log("[power] invokePower (non-streaming) returning with ", result);
            powerInvocation.result = result; // whether error or not

            if (result.error) {
                console.error("[power] Error invoking power", result.error, result);
                throw new Error(result.error);
            }

            // this returns the key but we don't need it, do we?
            await this.powerHistory.insert(powerInvocation);

            return result;
        }
    }

    //  Fetches remote inputs, if any; returns an inflated input block
    async retrieveInputs(input) {
        // Quick pass: any remotes needed?
        let remotes = [];
        Object.keys(input).forEach((key) => {
            if (input[key].remote) {
                remotes.push(key);
            }
        })
        if (remotes.length == 0) return input;

        // Fetch the remotes by POST to cex;
        // except for image, which is a direct fetch:
        let remotingPromises = [];
        remotes.forEach((key) => {
            if (key == "image") {
                remotingPromises.push(fetchImageData(input[key].remote));
            } else {
                remotingPromises.push(fetchPageData(input[key].remote));
            }
        });

        // Build output:
        let output = {};
        Object.keys(input).forEach((key) => {
            if (!input[key].remote) {
                output[key] = input[key];
            }
        })
        try {
            let results = await Promise.all(remotingPromises);
            for (let i = 0; i < remotes.length; i++) {
                let key = remotes[i];
                output[key] = results[i];
            }
        } catch (err) {
            console.error("Unable to load data", err);
            throw new Error("Unable to load remote data for power");
        }
        return output;
    }

    // Submits feedback reported by end-user tied to AI generated content
    async submitFeedback(feedback) {

        if (!feedback) {
            throw new Error("No feedback provided");
        }

        const accessToken = await this.authClient.getAccessToken();
        let result;
        try {
            result = await fetch(import.meta.env.VITE_WISPY_API_SERVER_URL + '/ai/feedback', {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(feedback),
            });
        } catch (err) {
            console.error(err);
            throw new Error("Unable to submit report. Please try again.");
        }
    }

}

class ExtensionStreamHandler {
    constructor(streamHandler) {
        this.streamHandler = streamHandler;
        this.accumulator = "";
    }

    final() {
        return {
            data: {
                output: this.accumulator
            }
        };
    }

    read(buf, done) {
        try {
            if (done && !buf) {
                this.streamHandler(null, true);
                return true;
            }
            let p = JSON.parse(buf);
            if (p.response) this.accumulator += p.response;
            this.streamHandler(p.response, p.done);
            if (p.done) {
                return true;
            }
            if (done) {
                console.error("[ollama-stream] LLM stream closed unexpectedly");
                this.streamHandler(null, true);
                return true;
            }
            return false;
        } catch (err) {
            console.log("[ollama-stream] error reading streamed response");
            console.error(err);
        }
    }
}