import { initPowerCard, displayPowerSelector, setPowerCardRouter } from './power_card.js';
import { buildModelSelectors, resetModelPrefsToDefault } from './capability_utils.js';

import { init_membership_panel, display_membership_panel, hide_membership_panel } from './membership.js';
import { init_login_panel, display_login_panel, hide_login_panel } from './login.js';

import { init_app_loading_progress, display_app_loading_progress, update_app_loading_progress, hide_app_loading_progress } from './progress.js';

import { initChatCard } from './chat_card.js';
import { PowerInput } from './power.js';
import { UserSession } from './session.js';

import { Wispy } from './wispy.js';

//import { gtag, install } from 'ga-gtag';

import DOMPurify from 'dompurify';

import 'dialog-polyfill/dialog-polyfill.css';

import '@shared/css/base.css';
import '@shared/css/forms.css';

import '../css/power-color.css';
import '../css/power.css';
import '../css/chat.css';
import '../css/help.css';

let gDefaultPower = "v2-create-image";
let gSystemInited = false;
let session;


// Disable debug log messages in production
/*
if (import.meta.env.PROD) {
    console.log = function () { };
} */

try {
    session = new UserSession();
} catch (err) {
    console.error(err);
}
let currentRoute = null;


async function router() {

    if (!gSystemInited) {
        console.log("[router] System not yet initialized, skipping routing");
        return;
    }

    let routerPath = document.location.pathname;
    let routerParams = new URLSearchParams(document.location.search);
    let needsReplaceState = false;
    let needsFirstRun = false;

    console.log("[router] Initializing routing", routerPath, routerParams);

    // If requesting manifest.json do nothing
    if (routerPath === "/manifest.json") {
        return;
    }

    // If the user is signing in, handle the callback
    if (routerParams.has("state") &&
        (routerParams.has("code") ||
            routerParams.has("error"))) {

        try {
            display_app_loading_progress();
            update_app_loading_progress("Signing in...");

            const result = await session.authClient.handleRedirectCallback();
            if (result && result.appState && result.appState.returnToPath) {
                routerPath = result.appState.returnToPath;
            }


            try {
                // Check to see if this is the first login
                const claims = await session.authClient.getIdTokenClaims();
                console.log("[router] Fetched user claims", claims);
                if (claims) {
                    const loginCount = claims["https://wispy.cyberwell.ai/logins_count"]; // XXX adapt to environment
                    if (loginCount < 2) {
                        needsFirstRun = true;
                        gtag('event', 'first_login');
                    } else {
                        gtag('event', 'returning_login');
                    }
                }
            } catch (error) {
                // Unable to fetch user claims from auth0 for some reason, for now log the error
                console.log("[router] Unable to fetch user claims from auth0: ", error);
                // And continue...
            }

            // Update the account view once after handling the callback
            await update_account_view();

            // Clear the query parameters from the URL
            routerParams.delete("state");
            routerParams.delete("code");
            routerParams.delete("error");

            // Replace the URL state to avoid reprocessing the callback on refresh
            //needsReplaceState = true;

            hide_app_loading_progress();

            session.reportInfo("Signed in.");
            console.log("[router] Signed in");
        } catch (error) {

            console.error("[router] Error handling redirect callback:", error.message);
            session.reportError(error.message);
            hide_app_loading_progress();
        }
    }
    // keep on going to load first page...

    console.log("[router] After signin block, routerPath=", routerPath, "routerParams=", routerParams);

    if (routerPath === "/") {
        routerPath = "/power";
    } // and continue...

    if (routerPath === "/power" || (routerPath.startsWith("/power") && !routerPath.startsWith("/power/assets") && !routerPath.startsWith("/power/icons"))) {
        let parts = routerPath.split("/");
        let targetPower = null;

        if (parts.length > 1 && parts[1].length > 0 && parts[2]) {
            targetPower = parts[2];
        }

        if (targetPower) {
            let power = session.getPowerByID(targetPower);
            if (power) {
                if (routerParams.size > 0) {
                    if (needsReplaceState) {
                        history.replaceState(null, "", "/power/" + power.powerID + "?" + routerParams.toString());
                    }
                    let powerInput = PowerInput.fromURL(power, routerParams);
                    initPowerCard(session, power, powerInput);
                } else {
                    if (needsReplaceState) {
                        history.replaceState(null, "", "/power/" + power.powerID);
                    }
                    initPowerCard(session, power, null);
                }
                if (needsFirstRun) {
                    document.getElementById('help-panel').showModal();
                }
                return;
            } else {
                // no such power: need a 404 page!
                session.reportError("No power with that ID exists");
                // XXX but what should we display?
            }
        } // else route to default power

        let power = session.getPowerByID(gDefaultPower);
        if (power) {
            history.replaceState(null, "", "/power/" + power.powerID);
            initPowerCard(session, power, null);
            displayPowerSelector(session);
            // If this is the first run, show the help panel as a modal overlay
            if (needsFirstRun) {
                document.getElementById('help-panel').showModal();
            }
            return;
        } else {
            // no such power: need a 404 page!
            console.log("[app] router; no such power (default)", gDefaultPower)
            session.reportError("No power with that ID exists");
        }
    } else if (routerPath == '/chat') {
        console.log("[app] router; chat");
        await initChatCard(session);
        return;
    } else {
        console.log("[app] router; unknown route, defaulting to power selector")
        history.replaceState(null, "", "/");
        session.reportInfo("No such power exists");
    }

}

async function connect() {

    console.log("[connect] attempt to connect");
    try {
        // Initialize the authentication client
        update_app_loading_progress("Initializing authentication...");
        await session.initializeAuth();

        // Check to see if the user is already authenticated
        const isAuthenticated = await session.isAuthenticated();
        if (isAuthenticated) {
            // If authenticated, then update their account data and view
            console.log("[connect] user is already authenticated");
            update_app_loading_progress("Updating account view...");
            console.log("[connect] updating account view");
            await update_account_view();
            console.log("[connect] account view updated");
        } else {
            // If not authenticated, disable the energy bar
            console.log("[connect] user is not authenticated");
            document.getElementById('wispy-energy').style.display = "none";
        }

        // In either case, we fetch the available powers for this user session
        console.log("[connect] updating powers");
        update_app_loading_progress("Refreshing powers...");
        await session.refreshPowers();
        console.log("[connect] powers updated");

    } catch (err) {
        console.error("[connect] fatal startup error", err);
        session.reportError(err.message);
        show_disconnected();
        return false;
    }
    return true;
}

async function onLoad() {

    console.log("[power] starting up");
    console.log("[power] environment = ", import.meta.env);

    setPowerCardRouter(router);

    await init_app_loading_progress();

    display_app_loading_progress();
    update_app_loading_progress("Starting up...")

    const isAndroid = () => /Android/i.test(navigator.userAgent);
    const isIOS = () => /iPhone|iPad|iPod/i.test(navigator.userAgent);

    // Resize the viewport to account for mobile keyboard open/close
    const setAppHeight = () => {
        const doc = document.documentElement;
        const height = window.visualViewport?.height || window.innerHeight;

        doc.style.setProperty('--app-height', `${height}px`);
    };

    const debounce = (func, delay) => {
        let timeoutId;
        return () => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(func, delay);
        };
    };

    if (isAndroid()) {
        setAppHeight();

        if (window.visualViewport) {
            window.visualViewport.addEventListener('resize', debounce(setAppHeight, 100));
            window.visualViewport.addEventListener('scroll', debounce(setAppHeight, 100));
        } else {
            window.addEventListener('resize', debounce(setAppHeight, 100));
            window.addEventListener('scroll', debounce(setAppHeight, 100));
        }
        window.addEventListener('orientationchange', debounce(setAppHeight, 100));
        document.addEventListener('focusout', setAppHeight);
        document.addEventListener('focusin', setAppHeight);
    }

    // Wispy app icon in the top left corner of the app should return to the root
    const wispyAppIcon = document.getElementById("wispy-app-icon");
    wispyAppIcon.addEventListener("click", (e) => {
        // XXX replace with pushstate / router call to avoid page reload?
        window.location.href = "/";
    });

    init_login_panel(session);

    await init_membership_panel(session);

    const helpButton = document.getElementById("help-button");
    helpButton.addEventListener("click", (e) => {
        e.preventDefault();
        document.getElementById('help-panel').showModal();
    });

    const privacyPolicyButton = document.getElementById("privacy-policy-button");
    privacyPolicyButton.addEventListener("click", (e) => {
        e.preventDefault();
        window.open('https://technicalmagic.ai/privacy', '_blank');
    });

    const termsOfServiceButton = document.getElementById("terms-of-service-button");
    termsOfServiceButton.addEventListener("click", (e) => {
        e.preventDefault();
        window.open('https://technicalmagic.ai/terms-of-service', '_blank');
    });

    const helpCloseButton = document.getElementById("help-close-button");
    helpCloseButton.addEventListener("click", (e) => {
        e.preventDefault();
        document.getElementById('help-panel').close();
    });

    const helpCancelButton = document.getElementById("help-cancel-button");
    helpCancelButton.addEventListener("click", (e) => {
        e.preventDefault();
        document.getElementById('help-panel').close();
    });

    const logoutButton = document.getElementById("logout");
    logoutButton.addEventListener("click", (e) => {
        e.preventDefault();
        session.authClient.logout(window.location.origin + "/");
    });

    const energyBar = document.getElementById("wispy-energy");
    energyBar.addEventListener("click", async (e) => {
        e.preventDefault();
        display_app_loading_progress();
        update_app_loading_progress("Checking membership status..");
        await display_membership_panel(session);
        hide_app_loading_progress();
    });

    const resetModelPrefsButton = document.getElementById("reset-model-prefs-button");
    resetModelPrefsButton.addEventListener("click", async (e) => {
        e.preventDefault();
        try {
            await resetModelPrefsToDefault(session);
        } catch (error) {
            console.error('An error occurred:', error);
        }
    });

    const subscriptionChangeButton = document.getElementById("manage-account-button");
    subscriptionChangeButton.addEventListener("click", async (e) => {
        e.preventDefault();
        let accountDrawer = document.getElementById('account-drawer');
        accountDrawer.classList.remove('open');
        display_app_loading_progress();
        update_app_loading_progress("Checking membership status..");
        await display_membership_panel(session);
        hide_app_loading_progress();
    });

    const accountButton = document.getElementById('account-button');
    accountButton.addEventListener('click', async (e) => {
        e.preventDefault();
        e.stopPropagation();
        await handleAccountButton();
    });

    const wispy = new Wispy('25%');
    document.getElementById('power-wispy-icon').appendChild(wispy.element);

    const closeButton = document.getElementById('drawer-close-button');
    closeButton.addEventListener('click', function () {
        let accountDrawer = document.getElementById('account-drawer');
        accountDrawer.classList.remove('open');
    });

    const connectButton = document.getElementById('attempt-connection-button');
    let attemptingConnect = false;
    connectButton.addEventListener('click', async function () {
        // Avoid races during startup:
        if (attemptingConnect) return;
        attemptingConnect = true;
        connectButton.setAttribute("disabled", true);
        try {
            if (await connect()) {
                hide_disconnected();
                gSystemInited = true;
                router();
            }
        } catch (err) {
            console.error(err); // should not happen
        }
        connectButton.removeAttribute("disabled");
        attemptingConnect = false;
    });

    // Any click outside the account button or drawer should close the drawer, if it's open
    document.addEventListener("click", function (event) {
        const accountDrawer = document.getElementById('account-drawer');
        if (!accountButton.contains(event.target) && !accountDrawer.contains(event.target)) {
            accountDrawer.classList.remove('open');
        }
    });

    update_app_loading_progress("Connecting to server...");

    if (await connect()) {
        gSystemInited = true;

        hide_app_loading_progress();

        // Invoke router to handle the landing URL
        router();
    } else {
        // we were unable to connect
    }

    // Initialize the Material Design Lite (MDL) library after initial page load
    componentHandler.upgradeDom();
}

document.addEventListener('DOMContentLoaded', onLoad);
window.addEventListener("popstate", router);

async function show_disconnected() {
    document.getElementById("offline-view").style.display = "flex";
    // disable drawer:
}
async function hide_disconnected() {
    document.getElementById("offline-view").style.display = "none";
}

async function handleAccountButton() {
    try {
        const isAuthenticated = await session.isAuthenticated();
        if (isAuthenticated) {
            let accountDrawer = document.getElementById('account-drawer');
            accountDrawer.style.display = "flex";
            setTimeout(function () {
                accountDrawer.classList.toggle('open');
            }, 100);
        } else {
            display_login_panel();
        }
    } catch (error) {
        console.error('An error occurred:', error);
    }
}

async function update_account_view() {

    display_app_loading_progress();
    update_app_loading_progress("Checking status...");

    const isAuthenticated = await session.isAuthenticated();
    if (!isAuthenticated) {
        document.getElementById('wispy-energy').style.display = "none";
        return;
    }

    document.getElementById('wispy-energy').style.display = "flex";

    try {
        // Update the account button to show the user's avatar
        update_app_loading_progress("Fetching profile...");
        const accountButton = document.getElementById("account-button");
        const userProfile = await session.getUserProfile();
        if (!userProfile) {
            throw new Error("Unable to fetch user profile");
        }
        if (userProfile["https://wispy.cyberwell.ai/picture"]) {
            const sanitizedPictureURL = DOMPurify.sanitize(userProfile["https://wispy.cyberwell.ai/picture"]);
            accountButton.innerHTML = `<img src="${sanitizedPictureURL}" class="wispy-account-avatar" referrerpolicy="no-referrer" alt="account avatar" />`;
        } else {
            // Update the account button to show the default avatar
            accountButton.innerHTML = `<i class="material-icons">person</i>`;
        }

        // Update the account drawer to show the user's profile
        const profileElement = document.getElementById("profile");
        const sanitizedEmail = DOMPurify.sanitize(userProfile["email"] || "No email provided");
        profileElement.innerHTML = `<p class="wispy-account-name">${sanitizedEmail}</p>`;

        // Fetch user subscription status & update the energy bar in header
        update_app_loading_progress("Fetching membership status...");
        const subscriptions = await session.getSubscriptions();
        if (!subscriptions) {
            throw new Error("Unable to fetch user subscription status");
        }
        update_app_loading_progress("Fetching energy usage...");
        const energyUsage = await session.getEnergyUsage();
        if (!energyUsage) {
            throw new Error("Unable to fetch user energy usage");
        }
        document.getElementById('wispy-energy').style.display = "flex";
        const energyMeter = document.getElementById('wispy-energy');
        let remainingEnergyPct = 100 - (energyUsage.usage?.percent_used || 0);
        energyMeter.MaterialProgress.setProgress(remainingEnergyPct);
        console.log("[power] remaining energy set to " + remainingEnergyPct + "%");
        componentHandler.upgradeElements(energyMeter);

        if (subscriptions.subscriptions[0]?.plan_id === import.meta.env.VITE_WISPY_STRIPE_ELITE_PRICE_ID) {
            document.getElementById('wispy-subscription-name').textContent = "Technical Magic Elite Member";
        } else if (subscriptions.subscriptions[0]?.plan_id === import.meta.env.VITE_WISPY_STRIPE_PREMIUM_PRICE_ID) {
            document.getElementById('wispy-subscription-name').textContent = "Technical Magic Member";
        } else {
            document.getElementById('wispy-subscription-name').textContent = "Free Trial";
        }

        if (subscriptions.subscriptions[0]?.status === "trialing") {
            document.getElementById('wispy-subscription-name').textContent += " (Trial)";
        }

        update_app_loading_progress("Fetching AI model preferences...");
        // Fetch user model preferences
        let modelPrefs = await session.getModelPrefs();
        if (!modelPrefs) {
            throw new Error("Unable to fetch user model preferences");
        }

        // Fetch AI models available for this user session
        const availableModels = await session.getAvailableModels();
        if (!availableModels) {
            throw new Error("Unable to fetch available AI models");
        }

        // If this user has no model preferences, set them to the default
        if (modelPrefs.model_preferences && Object.keys(modelPrefs.model_preferences).length === 0) {
            try {
                const resetModelPrefs = await session.resetModelPrefsToDefaults();
                console.log("[power] reset model prefs response", resetModelPrefs);
                modelPrefs = resetModelPrefs;
            } catch (err) {
                throw new Error("Error resetting model preferences");
            }
        }

        session.modelPrefs = modelPrefs;

        let modelPickerContainer = document.getElementById("account-model-selectors");
        modelPickerContainer.innerHTML = "";
        modelPickerContainer.classList.add("power-model-picker-container");
        buildModelSelectors(session, modelPickerContainer);

    } catch (error) {
        //session.reportError(`Unable to update account data: ${error.message}`);
        console.log("[power] error updating account view", error);
        //session.authClient.logout(window.location.origin + "/");
    }
}