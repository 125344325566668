import { Wispy } from './wispy.js';

import '../css/progress.css';

/*****************************************************************************/
/* App Loading Progress                                                      */
/*****************************************************************************/

export async function init_app_loading_progress() {
  
}

export function display_app_loading_progress() {
    document.getElementById("wispy-app-loading").style.display = "flex";
    document.getElementById("wispy-pwa").style.display = "none";
    document.getElementById("wispy-footer").style.display = "none";
}

export function update_app_loading_progress(statusText) {
    document.getElementById("wispy-app-loading-status").innerText = statusText;
}

export function hide_app_loading_progress() {
    document.getElementById("wispy-app-loading").style.display = "none";
    document.getElementById("wispy-pwa").style.display = "flex";
    document.getElementById("wispy-footer").style.display = "flex";
}

