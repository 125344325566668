import dialogPolyfill from 'dialog-polyfill';
export class ConfirmationDialog {
    constructor({ title, text, confirmText, cancelText, confirmCallback, cancelCallback }) {
        const dialogElement = document.getElementById("confirmation-dialog");
        if (!dialogElement) {
            console.error("Could not find confirmation dialog");
            return;
        }

         // Clone the dialog element to allow reusing the same dialog structure with different content and behavior.
        this.dialog = dialogElement.cloneNode(true);
        
         // Store the confirm and cancel callbacks. If no callback is provided, use a no-op function.
        this.confirmCallback = confirmCallback || (async () => { });
        this.cancelCallback = cancelCallback || (async () => { });

        // Set the title, text, and button labels of the dialog.
        this.dialog.querySelector('#confirmation-title').innerText = title || "Are you sure?";
        this.dialog.querySelector('#confirmation-text').innerText = text || "";
        this.dialog.querySelector('#confirmation-confirm_text').innerText = confirmText || "Confirm";
        this.dialog.querySelector('#confirmation-cancel_text').innerText = cancelText || "Cancel";

        // Add event listeners to the confirm and cancel buttons.
        // When the confirm button is clicked, call the confirm callback and destroy the dialog.
        this.dialog.querySelector('#confirmation-confirm_button').addEventListener("click", async (e) => {
            await this.confirmCallback();
            this.destroy();
        });

        // When the cancel button is clicked, call the cancel callback and destroy the dialog.
        this.dialog.querySelector('#confirmation-cancel_button').addEventListener("click", (e) => {
            this.cancelCallback(e);
            this.destroy();
        });        
    }

    show() {
        document.body.appendChild(this.dialog);
        // Upgrade the dialog with MDL behavior.
        componentHandler.upgradeElement(this.dialog);
        // Register the dialog with the dialog polyfill to ensure it works correctly in all browsers.
        dialogPolyfill.registerDialog(this.dialog);
        this.dialog.showModal();
    }

    destroy() {
        this.dialog.close();
        this.dialog.remove();
    }
}