import '../css/wispy.css';

export class Wispy {

    constructor(scale='100%') {
        this.blinkDur = 0.2; // blink duration in seconds
        this.pauseMin = 2; // minimum pause duration in seconds
        this.pauseMax = 8; // maximum pause duration in seconds

        this.element = this.createWispyElement(scale);
        this.blink();
        this.randomizeBlink();
    }

    createWispyElement(scale) {

        const outer_container = document.createElement('div');
        outer_container.classList.add('wispy-outer-container');
        outer_container.style.height = 'auto';
        outer_container.style.width = 'auto';
        
        const container = document.createElement('div');
        container.classList.add('wispy-container');
        container.style.transform = `scale(${scale})`;
        container.style.height = (parseInt(scale) * 9) + 'px';
        container.style.width = 'auto';
        
        const wispy = document.createElement('div');
        wispy.classList.add('wispy');
        wispy.innerHTML = `
            <svg class="wispy-base color-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 293.16 374.93">
                <path d="m291.9,222.51c-3.99,33.79-18.42,82.16-48.36,106.99-.99.9-2,1.78-3.02,2.64-2.6,2.24-4.94,4.04-7.02,5.43-26.32,18.77-59.89,26.22-84.21,26.22-.96,0-1.95-.02-2.95-.04-37.91,1.2-71.75-13.95-94.24-37.68-3.14-3.08-6.09-6.37-8.83-9.89C3.85,273.4-6.41,198.71,3.37,181.34c3.36-6.43,2.5,4.11,13.82,18.34-1.03-24.08,6.63-46.53,12.69-59.42,17.98-38.24-19.53-56.92-16.97-61.5-.25-.32.23-.59,1.67-.89.62-.13,1.25-.22,1.89-.31,13.83-1.93,33.28,4.8,49.75,17.75-.03-1.7-.06-3.03-.13-3.87-1.86-23.78-29.7-34.07-24.81-37.43.09-.17.55-.36,1.47-.59,6.56-1.67,17.22-.95,29.62,2.35,8,2.07,15.89,4.9,24.39,8.84-2.36-13.62-15.77-23.14-12-24.37.23-.09.6-.15,1.14-.17,7.44-.32,18.72,3.87,30.75,11.1,3.17-15.1-5.58-27.94-30.82-19.74-32.1,10.43-56.03-8.96-55.01-22.29.41-5.32,12.68,3.5,36.91-1.02.9-.27,1.8-.56,2.69-.88,11.71-4.27,21.81-6.2,30.07-6.85,8.89-.88,17.2-.23,24.94,1.69-.85-.27-1.57-.47-2.11-.6.14.02.28.05.45.08,4.73.81,10.12,2.7,15.8,5.68,15.42,7.54,28.01,20.33,37.76,35.37,6.75,10.02,12.74,21.92,17.25,35.74,1.16-12.85-3.72-23.13.3-22.4.15-.05.58.1,1.37.5,14.43,7.15,34,36.14,39.31,69.83,2.24-14.94-2.52-27.29,1.81-26.04.18-.03.65.2,1.48.73,19.62,12.45,44.78,72.12,35.91,116.08,13.44-15.52,12.96-35.69,15.87-29.56t0,0c.55,1,1.01,2.39,1.38,4.1,1.67,7.02,1.61,17.99-.13,30.94Zm-75.71,131.43c-1.85-.22-3.44,2.36-2.88,3.77.56,1.41,2.17,1,3.14.06s.87-3.7-.26-3.83Zm20.67-6.71c-8.88-.93-16.77,11.8-14.16,18.65,2.6,6.85,10.4,4.7,15.1.03,4.71-4.67,4.49-18.11-.94-18.68Zm-27.01,19.56c-3.33-.49-6.06,3.94-4.98,6.45s3.96,1.88,5.64.29c1.68-1.6,1.37-6.44-.66-6.74Zm-136.63.31c5.77,1.81,12.79.55,11.69-5.61s-13-12.91-19.46-8.93c-3.95,2.44,2,12.72,7.77,14.53Zm-21.19-25.12c2.12.41,4.97-.87,5.05-3.95.08-3.08-4.03-5.49-6.9-2.85-1.75,1.62-.27,6.39,1.85,6.8Zm-.18,21.19c1.22.26,2.84-.4,2.84-2.1,0-1.7-2.4-3.1-4-1.69-.98.87-.06,3.53,1.16,3.79Z"/>
            </svg>
            <div class="light-shadow">
                <img src="/assets/wispy-light-shadow.png">
            </div>
            <div class="eyes">
                <div id="eye-left" class="mask eye eye-left">
                    <img src="/assets/wispy-eye.png">
                </div>
                <div id="eye-right" class="mask eye eye-right">
                    <img src="/assets/wispy-eye.png">
                </div>
            </div>
        `;

        container.appendChild(wispy);
        outer_container.appendChild(container);

        return outer_container;
    }

    blink() {
        const eyes = this.element.getElementsByClassName('eye');
        for (let eye of eyes) {
            eye.style.animation = `blink ${this.blinkDur}s ease-in-out`;
            setTimeout(() => {
                eye.style.animation = 'none';
            }, this.blinkDur * 1000);
        }
    }

    randomizeBlink() {
        const pauseDuration = Math.random() * (this.pauseMax - this.pauseMin) + this.pauseMin;
        setTimeout(() => {
            this.blink();
            this.randomizeBlink();
        }, pauseDuration * 1000);
    }

    randomizeColor() {
        const colors = ['color-1', 'color-2', 'color-3', 'color-4', 'color-5'];
        const base = this.element.getElementsByClassName('wispy-base')[0];
        base.classList.remove(...colors);
        base.classList.add(colors[Math.floor(Math.random() * colors.length)]);
    }

}