import Prism from 'prismjs';
import 'prismjs/components/prism-json';
import '../../shared/css/code-highlighting.css';
import { marked } from "marked";
import DOMPurify from 'dompurify';
import { closePowerCard } from './power_card.js';

import '@material/web/select/outlined-select.js';
import '@material/web/select/select-option.js';

import * as pdfjsLib from 'pdfjs-dist/webpack.mjs';

import '../css/report_dialog.css';

const CHAT_SERVER_ENDPOINT = import.meta.env.VITE_WISPY_CHAT_API_SERVER_URL; 

/******************************************************************************/
/***************************** Card Session Support ***************************/
/******************************************************************************/

const CHAT_CONNECTING = 1;
const CHAT_CONNECTED = 2;

class ChatConnection {
    constructor(chatLog) {
        this.state = 0;
        this.pendingUserText = null;
        this.pendingInfoText = null;
        this.chatLog = chatLog;
    }

    send(message) {
        throw new Error("Not implemented");
    }

    clear() {
        throw new Error("Not implemented");
    }

    close() {

    }

    async onMessage(message) {

        gChatHistoryLog.lastUpdate = Date.now();

        if (message.op === "servererror") {
            this.chatLog.addErrorText(message.text);
        } else if (message.op === "serverinfo") {
            this.chatLog.addInfoText(message.text);
        } else if (message.op === "servertext") {
            gChatHistoryLog.hide_pending();
            if (!message.sequence) console.error("No sequence number in message", message);
            if (message.energy && message.energy > 0) {
                gChatEnergyUsed += message.energy;
                document.getElementById("power-chat-energy-used").innerHTML = `<i class="material-icons">bolt</i> ${Number(gChatEnergyUsed).toLocaleString()}`;
                message.timestamp = Date.now();
                await gSession.saveChatPowerInvocation(gCurrentPower, gChatHistoryLog);
            }
            console.log("[onMessage]", message);
            this.chatLog.addSystemText(message.text, message.sequence, message.timestamp);
        } else if (message.op === "contextrequest") {
            // chatContextRequest(data);
            throw new Error("ContextRequest not implemented yet");
        } else if (message.op === "progress") {
            console.log("[chat progress] ", message.data);
        } else if (message.op === "disconnect") {
            console.log("[chat] Connection disconnect");
            this.clear();
        } else {
            console.error("Unknown message type: " + message.op, message);
        }
    }
}

class WebSocketChatConnection extends ChatConnection {
    constructor(chatLog) {
        super(chatLog);
        this.socket = null;
    }

    connected() {
        return (this.state === CHAT_CONNECTED);
    }

    close() {
        if (this.socket) {
            this.socket.close();
            this.socket = null;
        }
        this.state = 0;
    }

    // Lower-level interface; sends a message
    sendMessage(message) {
        if (!this.connected()) {
            console.error("sendMessage called on disconnected socket");
            throw new Error("sendMessage called on disconnected socket");
        }
        this.socket.send(message);
    }

    // Top-level interface to send user text
    async sendText(text) {
        if (!this.connected()) {
            this.pendingUserText = text;
            await this.reconnect();
        } else {
            let message = buildUsertextMessage(text);
            console.log("sending message", message);
            await this.sendMessage(JSON.stringify(message));
        }
    }

    async sendInfoText(text) {
        if (!this.connected()) {
            this.pendingInfoText = text;
            this.reconnect();
        } else {
            await this.sendMessage(JSON.stringify(buildInfoTextMessage(text)));
            // we don't add to the log, only on server response
        }
    }

    clear() {
        this.socket = null;
        this.state = 0;
    }

    // NB this returns before the handshake is complete;
    // consider refactoring to remove the weird external
    // dependency on gPendingChatText.
    async reconnect() {
        let self = this;
        // XXX consider refactoring this to keep authClient private
        const accessToken = await gSession.authClient.getAccessToken()
        let params = new URLSearchParams();
        params.set("token", accessToken);
        let socket = new WebSocket(CHAT_SERVER_ENDPOINT + "?" + params.toString());
        if (!socket) {
            console.log("Failed to connect to server");
            return;
        }
        this.state = CHAT_CONNECTING;
        socket.onopen = async (e) => {
            console.log("[chat-ws] Connection established");
            self.state = CHAT_CONNECTED;

            let pendingUserText = self.pendingUserText;
            let pendingInfoText = self.pendingInfoText;

            self.pendingUserText = null;
            self.pendingInfoText = null;

            await self.sendMessage(JSON.stringify({
                type: 'hello',
                sequence: self.chatLog.lastSystemSequence,
                timestamp: Date.now(),
                history: self.chatLog.toMessageVector()
            }));

            setTimeout(async () => {
                if (pendingUserText) {
                    let x = gChatHistoryLog.lastSystemSequence;
                    console.log("[chat-ws] Sending pending message:", pendingUserText, x);

                    let message = buildUsertextMessage(pendingUserText);
                    console.log("sending message", message);
                    await self.sendMessage(JSON.stringify(message));
                    //self.sendText(pendingUserText);
                    //self.sendMessage(JSON.stringify(buildUsertextMessage(pendingUserText)));
                }
                if (pendingInfoText) {
                    console.log("[chat-ws] Sending pending info message:");
                    self.sendInfoText(pendingInfoText);
                }
            }, 500);
        };
        socket.onmessage = function (event) {
            // console.log(`[chat-ws] Data received from server: ${event.data}`);
            let data = JSON.parse(event.data);
            self.onMessage(data);
        };
        socket.onclose = function (event) {
            if (event.wasClean) {
                console.log(`[chat-ws] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
            } else {
                console.log('[chat-ws] Connection timeout');
                self.state = 0;
                self.socket = null;
            }
            self.state = 0;
            self.socket = null;
        };
        socket.onerror = function (error) {
            console.error(`[chat-ws] Error: ${error.message}`);
            self.chatLog.hide_pending();
            self.state = 0;
            self.socket = null;
        };
        this.socket = socket;
    }
}

/* ExtensionProvidedChatConnection is used for local models,
* which could be running in the extension or in an external process.
*/
class ExtensionProvidedChatConnection extends ChatConnection {
    constructor(chatLog) {
        super(chatLog);
    }

    connected() {
        return true;
    }

    // Lower-level interface; sends a message
    sendMessage(message) {
        let self = this;

        gSession.callExtensionFunction(
            {
                op: "chat-session",
                request: message
            },
            (data, done) => {
                console.log("[chat-extension] Got stream response", data, done);
                // data = { message: { content, role }}
                let dataObj = data;
                self.onMessage({
                    op: "servertext",
                    sequence: dataObj.sequence,
                    text: dataObj.message.content,
                });
            }
        );
        // we ignore return value
    }

    // Top-level interface to send user text
    async sendText(text) {
        // We have to replay all previous messages every time, so
        // we convert to {role,content} syntax here.
        let messages = this.chatLog.toMessageVector()

        let currentMessage = {
            role: "user",
            content: text
        };

        //if (gChatHistoryLog.droppedFiles.length > 0) {
        //    gChatHistoryLog.augmentMessageWithFiles(currentMessage);
       // }

        let sequence = this.chatLog.lastSystemSequence++;
        if (!sequence) {
            console.error("No sequence number");
            throw new Error("No sequence number");
        }
        messages.push(currentMessage);
        let message = {
            messages: messages,
            sequence: sequence,
            capability: gChatCapability ? "chatCompletion;" + gChatCapability : undefined,
        }
        this.sendMessage(message);
    }

    clear() {
    }

}


/******************************************************************************/
/***************************** Card UI Support ********************************/
/******************************************************************************/

let gSession = null;
let gCurrentPower;
let gPowerInput;
let gIsChatCardInitialized = false;
let gChatHistoryLog;
let gChatConnection;
let gChatHistoryDiv;
let gChatInputText;
let gChatCapability;
let gChatEnergyUsed;

// do streaming response here

/** Initialize the UI for a given power.
 *
 * session - UserSession
 * power - Power object
 * result - optional result object
 **/
export async function initChatCard(session, power, result = null) {
    // The user session is a global singleton; we save a reference to it here
    gSession = session;
    gCurrentPower = power;

    console.log("[chat] initializing chat card");

    if (!gIsChatCardInitialized) {
        //let chatContainer = document.getElementById("chat-container");
        gChatHistoryDiv = document.getElementById("chat-history");
        gChatInputText = document.getElementById("chat-input-text");

        document.getElementById("chat-input-submit").addEventListener("mousedown", async (e) => {
            e.preventDefault();
            await submitUserInputText();
        });

        document.getElementById("chat-input-submit").addEventListener("touchstart", async (e) => {
            e.preventDefault();
            await submitUserInputText();
        });

        gChatInputText.addEventListener('keydown', async (e) => {
            if (e.key === 'Enter') {
                await submitUserInputText();
            }
        });

        document.getElementById('chat-input-box').addEventListener('touchmove', function (e) {
            e.preventDefault();
        });

        const closeChatListener = async (e) => {
            e.preventDefault();

            // Save the chat history
            if (gChatHistoryLog.lastSystemSequence > 1) {
                await gSession.saveChatPowerInvocation(gCurrentPower, gChatHistoryLog);
            }

            await closePowerCard(gCurrentPower);
            gChatConnection.close();
            gChatHistoryLog = null;
            hide_chat_card();
        };

        document.getElementById("power-chat-back-arrow").addEventListener("click", closeChatListener);
        document.getElementById("power-chat-close-button").addEventListener("click", closeChatListener);

        // Attach the attachment button

        let attachmentWrapper = document.getElementById("chat-input-attachment-wrapper");

        let attachmentForm = document.createElement("input");
        attachmentForm.setAttribute("type", "file");
        attachmentForm.setAttribute("name", "chat-input-attachment-form");
        attachmentForm.setAttribute("accept", "application/pdf");
        //attachmentForm.setAttribute("accept", "application/pdf,image/*");
        //attachmentForm.setAttribute("capture", "environment");
        attachmentForm.setAttribute("id", "chat-input-attachment-form");
        attachmentForm.style.display = "none";

        let attachmentButton = document.createElement("label");
        attachmentButton.id = "chat-input-attachment";
        attachmentButton.classList.add("chat-input-attachment-button");
        attachmentButton.setAttribute("for", "chat-input-attachment-form");
        attachmentButton.innerHTML = '<i class="material-icons">add</i>';

        const attachmentHandler = async(e) => {
            e.preventDefault();

            let files = e.target.files;
            if (!files) return;

            // XXX only handle one file for now
            let file = files[0];

            try {
                switch (file.type) {
                    case "application/pdf":
                        const pdf = await pdfjsLib.getDocument(URL.createObjectURL(file)).promise;
                        let text = '';
                        for (let i = 1; i <= pdf.numPages; i++) {
                            const page = await pdf.getPage(i);
                            const content = await page.getTextContent();
                            text += "\n= PAGE " + i + " of " + pdf.numPages + " =\n" + content.items.map(item => item.str).join(' ');
                        }
                        text += `\n\n`;

                        let maxTokens = 32768;
                        let tokenEstimate = Math.max(Math.round(text.split(/\s+/).length / 0.75), Math.round(text.length / 4));
                        if (tokenEstimate > maxTokens) {
                            let truncateAt = Math.floor(maxTokens * 4);
                            text = text.substring(0, truncateAt).split(/\s+/).slice(0, Math.floor(maxTokens * 0.75)).join(' ');
                            text += "\n\n[... text truncated ...]";
                        }

                        gChatHistoryLog.addInfoText("Added: " + file.name + " (" + file.size + " bytes)");
                        sendUserInput('The following PDF with the file name "' + file.name + '" has been added to this chat with the following content: ###\n\n' + text + '\n###\n\n');

                        // Focus input element if not mobile or tablet
                        if (!gSession.isMobileOrTablet()) gChatInputText.focus();
                        break;

                    default:
                        gSession.reportError("Unsupported file type: " + file.type);
                        break;
                }
            } catch (error) {
                console.error("Error while handling file:", error);
            }
        };

        attachmentWrapper.appendChild(attachmentForm);
        attachmentWrapper.appendChild(attachmentButton);

        attachmentForm.addEventListener("change", attachmentHandler);

      
        /*
        let dropzone = gChatInputText;
        dropzone.addEventListener('dragover', handleDragOver);
        dropzone.addEventListener('dragenter', handleDragEnter); // MDN says only dragenter and dragleave for OS files
        dropzone.addEventListener('dragleave', handleDragLeave); // MDN says only dragenter and dragleave for OS files
        dropzone.addEventListener('drop', handleDrop);
        */

        // Load GPT tokenizer:
        // const { encode, decode } = GPTTokenizer_cl100k_base
        // tokenizerEncode = encode;
        // tokenizerDecode = decode;

        gChatHistoryLog = null;
        gChatHistoryLog = new ChatHistory(gChatHistoryDiv);
        gIsChatCardInitialized = true;

        console.log("[chat] chat card initialized");
    } else {
        console.log("[chat] chat card already initialized");
        gChatHistoryLog = new ChatHistory(gChatHistoryDiv, gChatInputText);
        await gChatHistoryLog.render();
    }

    let prefs = await gSession.getModelPrefs();
    gChatCapability = prefs.model_preferences.chatCompletion;

    let modelName;
    try {
        modelName = await getChatModelName(gChatCapability);
    } catch (err) {
        console.error("Error getting model name", err);
        // Reset to default and try again
        gChatCapability = "openai?model=gpt-4o-mini";
        modelName = await getChatModelName(gChatCapability);
    }

    let providerIcon = await getChatProviderIcon(gChatCapability);

    gChatConnection = new WebSocketChatConnection(gChatHistoryLog);
    gChatEnergyUsed = 0;

    resetChatDisplay();

    let chatTitleElement = document.getElementById("power-chat-model");
    chatTitleElement.innerHTML = "";
    await buildChatModelPicker(chatTitleElement);

    document.getElementById("power-chat-energy-used").innerHTML = `<i class="material-icons">bolt</i> ${gChatEnergyUsed}`;
    gChatHistoryLog.addInfoText("You are now chatting with ![image](" + providerIcon + ") **" + modelName + "**.");
    document.getElementById("chat-input-text").placeholder = "Message " + modelName;
    //document.getElementById("chat-capability-icon").src = await getChatProviderIcon(gChatCapability);

    await display_chat_card();

    // Handle inputs

    /* See if we have the extension installed;
    try {
        let result = await callExtensionFunction({op:"get-retrievers"});
        console.log("Got installed retrievers", result);
        retrievers = result;

        // Testing:
        //console.log("Performing search");
        //let result2 = await callExtensionFunction({op:"search-retriever", name:"bookmarks", query:{text:"cake"}});
        //console.log("Got extension search result", result2);
    } catch (err) {
        console.log("Got exception while accessing extension", err);
    }*/

    // Restore previous session
    if (result) {
        console.log("[history log] restoring", result);

        gChatHistoryLog.id = result.id;
        gChatHistoryLog.title = result.title;
        gChatHistoryLog.history = result.history;
        gChatHistoryLog.lastUpdate = result.lastUpdate;

        for (let i = 0; i < gChatHistoryLog.history.length; i++) {
            let h = gChatHistoryLog.history[i];
            if (h.type === 'user') {
                let seq = parseInt(h.sequence.split('-')[1]);
                if (seq >= gChatHistoryLog.nextUserSequence) {
                    gChatHistoryLog.nextUserSequence = seq + 1;
                }
            } else if (h.type === 'system') {
                let seq = parseInt(h.sequence);
                if (seq > gChatHistoryLog.lastSystemSequence) {
                    gChatHistoryLog.lastSystemSequence = seq;
                }
            }
        }

        console.log("[history log]", JSON.parse(JSON.stringify(gChatHistoryLog)));
        console.log("[history log]", JSON.parse(JSON.stringify(gChatHistoryLog.history)));

        await gChatHistoryLog.render();
    }
}

async function display_chat_card() {
    const isAuthenticated = await gSession.isAuthenticated();
    if (!isAuthenticated) {
        gSession.login("/chat");
        return;
    }

    updateModelPickerSelection();

    document.getElementById("power-chat").classList.remove("hidden");

    setTimeout(() => {
        gChatHistoryLog.container.scrollTop = gChatHistoryLog.container.scrollHeight;
        void gChatHistoryDiv.offsetHeight; // Force a reflow        
        gChatInputText.focus();
    }, 0);

}

function hide_chat_card() {
    document.getElementById("power-chat").classList.add("hidden");
    resetChatDisplay();
}

async function buildChatModelPicker(container) {
    let availableModels = await gSession.getAvailableModels();
    let modelTable = availableModels["chatCompletion"];
    if (!modelTable) throw new Error(`No models for chatCompletion!`);

    let select = document.createElement("md-outlined-select");
    select.classList.add("chat-model-picker");
    select.id = "chat-model-picker";

    for (let model of modelTable.models) {
        let option = document.createElement("md-select-option");
        option.value = `${model.provider}?model=${model.model}`;
        option.displayText = await getChatModelName(option.value);

        let headline = document.createElement("div");
        headline.slot = "headline";
        let icon = await getChatProviderIcon(option.value);
        headline.innerHTML = `
    <span class="chat-model-option-container">
        <span class="icon"><img src="${icon}" class="chat-model-leading-icon"/></span>
        <div class="model">
            <span class="name">${model.name}</span>
            <span class="description">${model.description ?? ""}</span>
        </div>
        <span class="energy">${getModelEnergyRating("chatCompletion", model.model)}</span>
    </span>`;
        option.appendChild(headline);

        if (gChatCapability && (model.model === gChatCapability.split(";")[1])) {
            option.selected = true;
        }
        select.appendChild(option);
    }

    let localLabel = document.createElement("md-select-option");
    localLabel.innerText = "Local Models...";
    localLabel.disabled = true;
    select.appendChild(localLabel);

    select.addEventListener("change", async (e) => {
        let capability = e.target.value;
        if (!capability) return;
        let modelName = await getChatModelName(capability);
        let providerIcon = await getChatProviderIcon(capability);

        gChatCapability = capability;
        gChatHistoryLog.addInfoText("You are now chatting with ![image](" + providerIcon + ") **" + modelName + "**.");
        document.getElementById("chat-input-text").placeholder = "Message " + modelName;

        setTimeout(() => {
            gChatHistoryDiv.scrollTop = gChatHistoryDiv.scrollHeight;
            void gChatHistoryDiv.offsetHeight; // Force a reflow
            gChatInputText.focus();
        }, 100);

        if (gChatCapability.startsWith("local-")) {
            if (gChatConnection instanceof ExtensionProvidedChatConnection) {
                // No change
                return;
            }
            gChatConnection.close();
            gChatConnection = new ExtensionProvidedChatConnection(gChatHistoryLog);
        } else {
            if (gChatConnection instanceof WebSocketChatConnection) {
                // No change
                return;
            }
            gChatConnection = new WebSocketChatConnection(gChatHistoryLog);
        }
        updateModelPickerSelection();

    });

    container.appendChild(select);
    //    updateModelPickerSelection();
}

function updateModelPickerSelection() {
    let select = document.getElementById("chat-model-picker");
    if (!select) { console.error("not found"); return; }
    select.value = gChatCapability.replace(";", "?model=");
}

function getModelEnergyRating(capability, model) {
    if (gSession && gSession.availableModels && gSession.availableModels[capability]) {
        const modelInfo = gSession.availableModels[capability].models.find(m => m.model === model);
        if (modelInfo) {
            let energyRating = modelInfo.energyRating || 0;
            let energyIcons = "";
            for (let i = 0; i < energyRating; i++) {
                energyIcons += "<i class='material-icons model-selector-energy_rating_icon'>bolt</i>";
            }
            return energyIcons;
        }
    }
}
/******************************************************************************
function analyzeDrop(file) {
    document.getElementById('chat-input-text').classList.remove('drag-active');
    console.log("Analyzing file: " + file.name);
    gChatHistoryLog.dropFile(file);
}

function handleDrop(e) {
    e.preventDefault();
    var file = e.dataTransfer.files[0];
    analyzeDrop(file);
}
function handleDragOver(e) {
    e.preventDefault();
}
function handleDragEnter(e) {
    e.preventDefault();
    e.stopPropagation();
    document.getElementById('chat-input-text').classList.add('drag-active');
}
function handleDragLeave(e) {
    e.preventDefault();
    e.stopPropagation();
    document.getElementById('chat-input-text').classList.remove('drag-active');
}
******************************************************************************/

function resetChatDisplay() {
    gChatHistoryDiv.innerHTML = "";
    gChatInputText.value = "";
}

async function submitUserInputText() {

    let text = DOMPurify.sanitize(gChatInputText.value.trim());
    if (!text) return;

    gChatHistoryLog.addUserText(text);
    gChatHistoryLog.show_pending();

    gChatInputText.value = '';
    gChatInputText.focus();

    await sendUserInput(text);

    await gSession.saveChatPowerInvocation(gCurrentPower, gChatHistoryLog);

    console.log("[history log]", JSON.parse(JSON.stringify(gChatHistoryLog)));
    console.log("[history log]", JSON.parse(JSON.stringify(gChatHistoryLog.history)));
}

function buildUsertextMessage(text) {
    return {
        type: 'usertext',
        timestamp: Date.now(),
        data: {
            "text": text,
            "capability": gChatCapability ? "chatCompletion;" + gChatCapability.replace(";", "?model=") : undefined,
            // "has_document": chatHistory.fileContents ? true : false,
            // "image": gChatHistoryLog.getCurrentImage(), // NB this only gets ONE image
            // "retrievers": retrievers,
        }
    };
}

function buildInfoTextMessage(text) {
    return {
        type: 'infotext',
        timestamp: new Date(),
        data: {
            "text": text
        }
    };
}

async function sendUserInput(text) {
    console.log("[chat] Sending user input");
    /*
    if (currentlyReceiving()) {
        // if we are currently receiving input we need to cut the server off
        await sendInterrupt();
    }*/
    await gChatConnection.sendText(text);
}

/******************************************************************************
class DroppedFile {
    constructor(data) {
        if (!data) throw new Error("DroppedFile constructor requires data");
        this.type = data.type;
        this.text = data.text;
        this.image = data.image;
        this.mediaType = data.mediaType;
    }

    renderPreview(container) {
        let d = document.createElement("div");
        d.classList.add("dropped-file-preview");
        let mouseoverBox = document.createElement("div");
        mouseoverBox.classList.add("dropped-file-mouseover");
        mouseoverBox.innerText = this.filename;

        if (this.type == "text") {
            d.classList.add("text-file");
            mouseoverBox.innerText = this.filename + " (" + this.size + " bytes)";
        } else if (this.type == "html") {
            d.classList.add("html-file");
            mouseoverBox.innerText = this.filename + " (" + this.size + " bytes)";
        } else if (this.type == "pdf") {
            d.classList.add("pdf-file");
            mouseoverBox.innerText = this.filename + " (" + this.size + " bytes)";
        } else if (this.type == "image") {
            d.classList.add("image-file");
            d.style.backgroundImage = `url(${this.image})`;
        }

        // TODO attach a close box and remove myself from the list
        d.appendChild(mouseoverBox);

        container.appendChild(d);
    }

    imageBase64() {
        if (this.image) {
            if (this.image.startsWith("data:")) {
                let parts = this.image.split(",");
                if (parts.length > 1) return parts[1];
            }
        }
        throw new Error("Unable to generate base64 data for image");
    }

    extraIgnoreMe() {
        self.fileContents = contentResult;
        let labelParts = [
            "Current file: ",
            file.name,
            " (" + file.size + " bytes",
        ];

        if (contentProcessor.contentType() == "text") {
            self.tokenCount = null;
            try {
                let tokens = tokenizerEncode(contentResult);
                self.tokenCount = tokens.length;
            } catch (error) {
                console.log("Error while tokenizing file", error);
            }
            console.log("Analyzed file ", file.name, " with ", self.tokenCount, " tokens");
            if (self.tokenCount) {
                labelParts.push(", " + self.tokenCount + " tokens, ");
            }
        } else if (contentProcessor.contentType() == "image") {
            console.log("Processed image data");
            self.currentImageData = contentResult;
        }
        labelParts = labelParts.concat([
            contentProcessor.type(),
            ")"
        ]);
        self.dropzone.innerText = labelParts.join('');
        self.dropzone.classList.add("loaded");
    }
}
******************************************************************************/

class ChatHistory {
    constructor(container) {
        if (!container) throw new Error("Missing required ChatHistory container");
        //if (!dropzone) throw new Error("Missing required Dropzone container");
        this.nextUserSequence = 0;
        this.lastSystemSequence = 0;
        this.history = [];
        this.container = container;
        //this.dropzone = dropzone;
        //this.droppedFiles = [];
        this.id = Date.now();
        this.lastUpdate = Date.now();
        this.title = `Chat with AI invoked on ${new Date().toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}`;

        this.spinner = document.createElement("div");
        this.spinner.id = "chat-pending";
        this.spinner.classList.add("chatmessage");
        this.spinner.classList.add("chat-loading");

        this.currentDate = null;

    }

    /* Returns a vector of {role, content} objects such as the chat server expects */
    toMessageVector() {
        let result = [];
        this.history.forEach((h) => {
            if (h.type === 'user') {
                result.push({ role: 'user', content: h.text });
            } else if (h.type === 'system') {
                result.push({ role: 'assistant', content: h.text });
            }
        });
        return result;
    }

    async addUserText(text) {
        let seq = "u-" + this.nextUserSequence++;
        this.history.push({ type: 'user', text: text, sequence: seq, timestamp: Date.now(), capability: gChatCapability ?? null });
        await this.render();
    }
    async addErrorText(text) {
        let seq = "e-" + this.nextUserSequence++;
        this.history.push({ type: 'error', text: text, sequence: seq, timestamp: Date.now(), capability: gChatCapability ?? null });
        await this.render();
    }
    async addInfoText(text) {
        let seq = "i-" + this.nextUserSequence++;
        this.history.push({ type: 'info', text: text, sequence: seq, timestamp: Date.now(), capability: gChatCapability ?? null });
        await this.render();
    }
    getLastUser() {
        for (let i = this.history.length - 1; i >= 0; i--) {
            if (this.history[i].type === 'user') {
                return this.history[i];
            }
        }
        return null;
    }

    getOrCreateSystem(sequence) {
        console.log("[getOrCreateSystem]", this.history, sequence);
        for (let i = this.history.length - 1; i >= 0; i--) {
            if (this.history[i].type === 'system' && this.history[i].sequence === sequence) {
                return this.history[i];
            }
        }
        let entry = { type: 'system', text: "", sequence: sequence, capability: null, timestamp: null }
        this.history.push(entry);
        return entry;
    }
    async addSystemText(text, sequence, timestamp = null) {
        console.log("[addSystemText] params = ", text, sequence, timestamp, "called from ", new Error().stack.split("\n")[2]);

        let item = this.getOrCreateSystem(sequence);
        if (sequence > this.lastSystemSequence) {
            this.lastSystemSequence = sequence;
        }
        item.text += text;
        item.timestamp = timestamp;
        await this.render();
    }
    async addContextRequest(request, sequence) {
        let item = this.getOrCreateSystem(sequence);
        if (!item.contextRequests) item.contextRequests = [];
        item.contextRequests.push(request);
        await this.render();
    }
    async reissueLastUserRequest(extraText) {
        throw new Error("This function is broken and needs to be reimplemented");
        /*
        console.log("Reissuing last user request")
        let userMessage = this.getLastUser();
        if (!userMessage) {
            throw new Error("No user message found");
        }
        if (!userMessage.extraText) userMessage.extraText = "";
        else userMessage.extraText += "\n\n";
        userMessage.extraText += extraText;
        let msg = {
            "text": userMessage.text + "\n\n" + extraText,
            "sequence": this.history[this.history.length - 1].sequence, // last system message
            // "retrievers": retrievers,
        };

        let msgText = JSON.stringify({type: "usertext", data: msg});
        if (!gChatConnection.connected()) {
            await gChatConnection.reconnect();
            gPendingChatText = msgText;
        } else {
            gChatConnection.send(msgText);
        }*/

    }

    /* Returns a single image, if one exists, from the current droppedFiles 
    getCurrentImage() {
        if (!this.droppedFiles) return null;
        let imageFiles = this.droppedFiles.filter((f) => f.type === "image");
        if (imageFiles.length > 0) {
            return imageFiles[0].image;
        }
        return null;
    }

    augmentMessageWithFiles(message) {
        if (!this.droppedFiles) return;

        console.log("Augmenting message with dropped file data", this.droppedFiles);
        let imageFiles = this.droppedFiles.filter((f) => f.type === "image");
        if (imageFiles.length > 0) {
            message.images = imageFiles.map((f) => {
                // Convert to base64
                return f.imageBase64();
            });
        }

        let documentPart;

        let textFiles = this.droppedFiles.filter((f) => f.text);
        if (textFiles.length > 0) {
            textFiles.forEach((f) => {
                console.log("Augmenting message with text file ", f.filename);

                let text = f.text;
                if (text.length > 8000) {
                    console.log("Truncating long file");
                    text = text.substring(0, 8000) + " ... (truncated)";
                }
                documentPart += `\n\nThe contents of the file ${f.filename} are:\n${text}\n\n`;
            });
            message.content = documentPart + message.content;
        }

        console.log("Finished augmenting message", message);
    }
        */

    show_pending() {
        gChatHistoryDiv.appendChild(this.spinner);
        setTimeout(() => {
            this.spinner.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }, 0);
    }

    hide_pending() {
        if (this.container.contains(this.spinner)) {
            this.container.removeChild(this.spinner);
        }
    }

    async render() {
        console.log("rendered called by", new Error().stack.split("\n")[2]);

        this.history.forEach((h, index) => {
            if (!h.sequence) {
                console.error("No sequence number for ", h);
                return;
            }

            /*
            // Add a date header for each calendar day of messages
            if (h.timestamp && (h.type === 'user' || h.type === 'system')) {
                let date = new Date(h.timestamp);
                let formattedDate = date.toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
                let today = new Date().toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
            
                if (this.currentDate !== formattedDate  && formattedDate !== today ) {
                    let dateElem = document.createElement('div');
                    dateElem.innerText = formattedDate;
                    dateElem.classList.add('chat-date-header');
                    this.container.appendChild(dateElem);
                    this.currentDate = formattedDate;
                }
            } 
            */


            let prefix = "";
            switch (h.type) {
                case "user": prefix = "u-"; break;
                case "system": prefix = ""; break;
                case "error": prefix = "e-"; break;
                case "info": prefix = "i-"; break;
                default: prefix = "x-"; break;
            }

            let elem = this.container.querySelector(`#chat-${prefix}${h.sequence}`);

            /* let addContexts = () => {
                if (!h.contextRequests) return;
                let ctxt = elem.querySelector('.context');
                if (!ctxt) {
                    ctxt = document.createElement('div');
                    ctxt.classList.add('context');
                    elem.appendChild(ctxt);
                }
                let txts = h.contextRequests.map((c) => {
                    if (c.args) {
                        return c.op + " for " + c.args;
                    } else {
                        return c.op;
                    }
                });
                // Make sure it flows last
                elem.removeChild(ctxt);
                elem.appendChild(ctxt);
                ctxt.innerText = txts.join(', ');
            }; */
            /*
                        if (h.sequence === 2 && h.type === 'system' && gChatHistoryLog.lastSystemSequence > 2) {
                            // This is a special case where we are re-rendering the first system message
                            // after a reconnect. We don't want to re-render it.
                            console.error("###", h, index, h.sequence, h.type, gChatHistoryLog.lastSystemSequence);
                           
                        }
            */
            if (elem) {
                // Already exists: reuse it
                let txt = elem.querySelector('.text')
                let resultRenderedText = DOMPurify.sanitize(marked.parse(h.text));
                if (resultRenderedText !== txt.innerHTML) {
                    //console.log("updating txt", elem.id);
                    txt.innerHTML = resultRenderedText;
                    Prism.highlightAllUnder(txt);
                    gChatHistoryDiv.scrollTop = gChatHistoryDiv.scrollHeight;
                    void gChatHistoryDiv.offsetHeight; // Force a reflow
                }

                //if (h.contextRequests) addContexts(txt);
                return;
            }
            elem = document.createElement('div');
            elem.classList.add('chatmessage');
            elem.classList.add('chat-' + h.type);
            elem.id = `chat-${prefix}${h.sequence}`;

            let badge = document.createElement('div');
            badge.classList.add("chatbadge");
            badge.classList.add("chatbadge-" + h.type);
            elem.appendChild(badge);

            elem.setAttribute('data-timestamp', h.timestamp);

            let txt = document.createElement('div');
            txt.classList.add("text");
            let resultRenderedText = DOMPurify.sanitize(marked.parse(h.text));
            txt.innerHTML = resultRenderedText;

            Prism.highlightAllUnder(txt);
            elem.appendChild(txt);

            if (h.type == 'system') {
                let chatActions = document.createElement('button');
                chatActions.classList.add('chat-actions');
                chatActions.innerHTML = "<i class='material-icons'>more_vert</i>";
                elem.appendChild(chatActions);

                let chatActionsMenu = document.createElement('div');
                chatActionsMenu.classList.add('chat-actions-menu');
                chatActionsMenu.style.display = 'none';

                let topLevelFocusedElement = document.activeElement;
                function openChatActionsMenu() {
                    chatActionsMenu.style.display = 'flex';
                    chatActionsMenu.style.opacity = 1;
                    chatActionsMenu.style.transform = 'scale(1)';
                    chatActionsMenu.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
                    topLevelFocusedElement.focus();
                }
                function closeChatActionsMenu() {
                    chatActionsMenu.style.display = 'none';
                    chatActionsMenu.style.opacity = 0;
                    chatActionsMenu.style.transform = 'scale(0.9)';
                    topLevelFocusedElement.focus();
                }

                let reportDialog = null; // Dialog for reporting a message

                const chatActionsHandler = (e) => {
                    e.preventDefault();

                    // Store the currently focused element (to prevent a keyboard bounce on mobile)
                    let rect = chatActions.getBoundingClientRect();
                    let viewport = document.getElementById('chat-history').getBoundingClientRect();

                    // Position the menu above or below the chat actions button
                    let menuHeight;
                    if (chatActionsMenu.style.display === 'none') {
                        chatActionsMenu.style.display = 'flex';
                        menuHeight = chatActionsMenu.offsetHeight;
                        chatActionsMenu.style.display = 'none';
                    } else {
                        menuHeight = chatActionsMenu.offsetHeight;
                    }
                    if (rect.bottom + menuHeight > viewport.bottom) {
                        chatActionsMenu.style.bottom = '13px';
                        chatActionsMenu.style.top = 'auto';
                    } else {
                        chatActionsMenu.style.top = '0px';
                        chatActionsMenu.style.bottom = 'auto';
                    }

                    // Toggle menu open state on click
                    if (chatActionsMenu.style.display === 'none') {
                        openChatActionsMenu();
                    } else {
                        closeChatActionsMenu();
                    }
                };
                chatActions.addEventListener('mousedown', chatActionsHandler);
                chatActions.addEventListener('touchstart', chatActionsHandler);

                const copyAction = document.createElement('button');
                copyAction.classList.add('chat-action');
                copyAction.innerHTML = "<i class='material-icons'>content_copy</i> Copy";
                const copyActionHandler = (e) => {
                    e.preventDefault();

                    let text = txt.innerText;
                    navigator.clipboard.writeText(text).then(() => {
                        gSession.reportInfo("Copied message to clipboard");
                    }).catch((error) => {
                        gSession.reportInfo("Failed to copy");
                    });
                    closeChatActionsMenu();
                };
                copyAction.addEventListener('mousedown', copyActionHandler);
                copyAction.addEventListener('touchstart', copyActionHandler);
                chatActionsMenu.appendChild(copyAction);

                // Add Report action 
                const reportAction = document.createElement('button');
                reportAction.classList.add('chat-action');
                reportAction.innerHTML = "<i class='material-icons'>flag</i> Report";
                const reportActionHandler = (e) => {
                    e.preventDefault();

                    closeChatActionsMenu();

                    reportDialog = elem.querySelector('#report-dialog-instance') ?? document.getElementById('report-dialog').cloneNode(true);
                    reportDialog.id = 'report-dialog-instance';
                    elem.appendChild(reportDialog);

                    function openReportDialog() {
                        reportDialog.style.display = 'flex';
                        reportDialog.style.opacity = 1;
                        reportDialog.style.transform = 'scale(1)';
                        reportDialog.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
                    }

                    function closeReportDialog() {
                        reportDialog.style.display = 'none';
                        reportDialog.style.opacity = 0;
                        reportDialog.style.transform = 'scale(0.8)';
                    }

                    // Position within the viewport
                    let rect = chatActions.getBoundingClientRect();
                    let viewport = document.getElementById('chat-history').getBoundingClientRect();
                    reportDialog.style.display = 'flex';
                    let dialogHeight = reportDialog.offsetHeight;
                    reportDialog.style.display = 'none';
                    if (rect.bottom + dialogHeight > viewport.bottom) {
                        reportDialog.style.bottom = '13px';
                        reportDialog.style.top = 'auto';
                    } else {
                        reportDialog.style.bottom = 'auto';
                        reportDialog.style.top = '0px';
                    }
                    reportDialog.style.left = 'auto';
                    reportDialog.style.right = '22px';

                    openReportDialog();
                    setTimeout(() => {
                        reportDialog.querySelector('.details').focus();
                    }, 0); // Delay to ensure the dialog is fully rendered before focusing

                    // Report Dialog Cancel Handler
                    const dialogCancelListener = (e) => {
                        closeReportDialog();
                        gSession.reportInfo("Report cancelled");
                        reportDialog.querySelector('.report-dialog-cancel').removeEventListener('click', dialogCancelListener);
                        reportDialog.querySelector('.report-dialog-close').removeEventListener('click', dialogCancelListener);
                        reportDialog.querySelector('.report-dialog-submit').removeEventListener('click', dialogSubmitListener);
                    };
                    reportDialog.querySelector('.report-dialog-cancel').addEventListener('click', dialogCancelListener);
                    reportDialog.querySelector('.report-dialog-close').addEventListener('click', dialogCancelListener);

                    // Report Dialog Submit Handler
                    const dialogSubmitListener = async (e) => {
                        e.stopPropagation();

                        let details = DOMPurify.sanitize(reportDialog.querySelector('.details').value) || '';
                        let feedback = {
                            details: details,
                            message: h.text,
                            capability: h.capability,
                            timestamp: h.timestamp,
                            prompt: this.history[index - 1]?.text ?? '',
                        };
                        try {
                            await gSession.submitFeedback(feedback);
                            gSession.reportInfo("Report submitted");
                        } catch (error) {
                            gSession.reportError(error);
                        }
                        closeReportDialog();
                        reportDialog.querySelector('.report-dialog-submit').removeEventListener('click', dialogSubmitListener);
                        reportDialog.querySelector('.report-dialog-cancel').removeEventListener('click', dialogCancelListener);
                        reportDialog.querySelector('.report-dialog-close').removeEventListener('click', dialogCancelListener);
                    };
                    reportDialog.querySelector('.report-dialog-submit').addEventListener('click', dialogSubmitListener);
                };
                reportAction.addEventListener('mousedown', reportActionHandler);
                reportAction.addEventListener('touchstart', reportActionHandler);

                chatActionsMenu.appendChild(reportAction);

                elem.appendChild(chatActionsMenu);

                // Any click outside the chatActionsMenu should close it, if it's open
                const outsideClickListener = (e) => {
                    if (chatActions && chatActionsMenu && !chatActions.contains(e.target) && !chatActionsMenu.contains(e.target)) {
                        if (chatActionsMenu.style.display === 'flex') {
                            e.preventDefault();
                            closeChatActionsMenu();
                            document.removeEventListener('touchstart', outsideClickListener);
                            document.removeEventListener('mousedown', outsideClickListener);
                        }
                        if (reportDialog && reportDialog.style.display === 'flex' && !reportDialog.contains(e.target)) {
                            e.preventDefault();
                            reportDialog.remove();
                            reportDialog = null;
                        }
                    }
                };
                document.addEventListener('touchstart', outsideClickListener);
                document.addEventListener('mousedown', outsideClickListener);
            }

            if (h.timestamp && (h.type === 'user' || h.type === 'system')) {
                let timestampElem = document.createElement('div');
                let date = new Date(h.timestamp);
                let formattedTime = date.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true });
                timestampElem.innerText = formattedTime;
                timestampElem.classList.add('chat-timestamp');
                elem.appendChild(timestampElem);
            }

            this.container.appendChild(elem);

            //if (h.contextRequests) addContexts(txt);

            // Scroll the new message into view
            elem.scrollIntoView({ behavior: 'smooth', block: 'end' });
        });
    }

    /*
    async dropFile(file) {
        let self = this;
        this.fileContents = null;
        try {
            self.dropzone.classList.remove("loaded");
            var reader = new FileReader();
            var contentProcessor = null;
            reader.onload = async function (e) {
                console.log("Reader onLoad; got ", e.target.result.length, "bytes");

                if (contentProcessor && e.target.result) {
                    try {
                        let droppedFile = await contentProcessor.process(e.target.result);
                        console.log("Got dropped file");
                        droppedFile.filename = file.name;
                        droppedFile.size = file.size;
                        self.droppedFiles.push(droppedFile);
                        console.log("Constructing preview for ", droppedFile);
                        let previewArea = document.getElementById("chat-dropped-files-preview");
                        droppedFile.renderPreview(previewArea);
                        self.addInfoText("Dropped file: " + file.name);
                        send.sendUserText("I dropped a file: " + file.name);
                    } catch (error) {
                        console.error(error);
                        self.addErrorText("Error processing dropped file data");
                    }
                } else {
                    self.addErrorText("No content processor found for " + file.name);
                }
            };
            let lowerName = file.name.toLowerCase();
            if (lowerName.endsWith(".pdf")) {
                console.log("Constructing PDFProcessor for ", file.name);
                if (typeof pdfjsLib === 'undefined') {
                    this.addErrorText('pdf.js is not loaded');
                    return;
                }
                contentProcessor = new PDFProcessor();
                await reader.readAsArrayBuffer(file);
                console.log("###", file);
                sendUserInput("I dropped a PDF file: " + file.name + " Which contains: ", file.text);
            } else if (lowerName.endsWith(".htm") || lowerName.endsWith(".html")) {
                console.log("Constructing HTMLProcessor for ", file.name);
                contentProcessor = new HTMLProcessor();
                reader.readAsText(file);
            } else if (lowerName.endsWith(".jpg") || lowerName.endsWith(".jpeg") || lowerName.endsWith(".png") || lowerName.endsWith(".gif") ||
                lowerName.endsWith(".bmp") || lowerName.endsWith(".tif") || lowerName.endsWith(".tiff") || lowerName.endsWith(".svg") ||
                lowerName.endsWith(".webp") || lowerName.endsWith(".ico") || lowerName.endsWith(".heic")) {
                // process image
                console.log("Constructing ImageProcessor for ", file.name);
                contentProcessor = new ImageProcessor(file.type);
                reader.readAsDataURL(file);
            } else {
                // process unknown; treat as text
                console.log("Constructing TextProcessor for ", file.name);
                contentProcessor = new TextProcessor();
                reader.readAsText(file);
            }
        } catch (err) {
            this.addErrorText(err);
            console.error(err);
        }
    } */
}


/*
class PDFProcessor {
    constructor() {

    }
    type() {
        return "PDF"
    }
    contentType() {
        return "text"
    }
    async process(data) {
        try {
            const typedArray = new Uint8Array(data);
            const pdfDoc = await pdfjsLib.getDocument(typedArray).promise;

            let text = '';
            for (let pageNum = 1; pageNum <= pdfDoc.numPages; pageNum++) {
                const page = await pdfDoc.getPage(pageNum);
                const textContent = await page.getTextContent();
                text += textContent.items.map(item => item.str).join(' ');
            }
            return new DroppedFile({
                text: text,
                type: "pdf"
            });
        } catch (error) {
            throw error;
        }
    }
}
class HTMLProcessor {
    constructor() {

    }
    type() {
        return "HTML"
    }
    contentType() {
        return "text"
    }

    async process(data) {
        try {
            // Parse the HTML string into a Document object
            var parser = new DOMParser();
            var doc = parser.parseFromString(data, 'text/html');

            // Use the tree walker to traverse all text nodes
            var walker = document.createTreeWalker(doc.body, NodeFilter.SHOW_TEXT, null, false);
            var text = '';
            var node;
            while (node = walker.nextNode()) {
                text += node.nodeValue + '\n'; // Add each text node's content to the string
            }
            return new DroppedFile({
                text: text.trim(), // Trim any leading or trailing whitespace
                type: "html",
            })
        } catch (error) {
            throw error;
        }
    }
}

class ImageProcessor {
    constructor(mediaType) {
        this.mediaType = mediaType;
    }
    type() {
        return "image"
    }
    contentType() {
        return "image"
    }

    async process(data) {
        try {
            return new DroppedFile({
                mediaType: this.mediaType,
                image: data.trim(),
                type: "image"
            });
        } catch (error) {
            throw error;
        }
    }
}

class TextProcessor {
    constructor() {

    }
    type() {
        return "text"
    }
    contentType() {
        return "text"
    }

    async process(data) {
        try {
            return new DroppedFile({
                text: data.trim(),
                type: "text"
            });
        } catch (error) {
            throw error;
        }
    }
}
    */

/******************************************************************************/

async function getChatModelName(capability) {
    let models = await gSession.getAvailableModels();
    let modelTable = models["chatCompletion"];
    if (!modelTable) throw new Error(`No models for chatCompletion!`);

    let model = modelTable.models.find((m) => {
        let haystack = m.provider + ";" + m.model;
        let needle = capability ? capability.replace('?model=', ';') : "unknown";
        return needle === haystack;
    });

    if (!model) {
        console.error('No matching model found', capability);
        throw new Error(`Unknown model ${capability}`);
    }

    return model.name;
}

async function getChatProviderIcon(capability) {
    let provider = capability.split(";")[0];
    provider = provider.split("?")[0];

    switch (provider) {
        case "openai":
            return "/assets/models/icon-openai.png";
        case "llama2":
        case "meta":
            return "/assets/models/icon-meta.png";
        case "mistral":
            return "/assets/models/icon-mistral.png";
        case "anthropic":
            return "/assets/models/icon-anthropic.png";
        case "playground":
            return "/assets/models/icon-playground.png";
        case "gemini":
        case "google":
            return "/assets/models/icon-google.png";
        case "huggingface":
            return "/assets/models/icon-hugginface.png";
        default:
            console.error("No matching provider found");
            return "/assets/models/icon-default.png";
    }
}