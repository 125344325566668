import { display_app_loading_progress, hide_app_loading_progress, update_app_loading_progress } from './progress.js';

import '../css/login.css';

export function init_login_panel(session) {
    const loginCloseButton = document.getElementById("login-close-button");
    loginCloseButton.addEventListener("click", (e) => {
        e.preventDefault();
        hide_login_panel();
    });

    const loginSignUpButton = document.getElementById("login-signup-button");
    loginSignUpButton.addEventListener("click", async (e) => {
        e.preventDefault();
        hide_login_panel();
        display_app_loading_progress();
        update_app_loading_progress("Starting 7-day free trial...");
        await session.signup();
        setTimeout(() => {
            hide_app_loading_progress();
        }, 5000);
    });

    const loginSignInButton = document.getElementById("login-signin-button");
    loginSignInButton.addEventListener("click", async (e) => {
        e.preventDefault();
        hide_login_panel();
        display_app_loading_progress();
        update_app_loading_progress("Signing in...");
        await session.login();
        setTimeout(() => {
            hide_app_loading_progress();
        }, 5000);
    });
}

export function display_login_panel() {
    document.getElementById('wispy-pwa-backdrop').style.display = 'block';
    document.getElementById('login-panel').style.display = 'flex';
    document.getElementById('login-panel').style.opacity = 1;
    document.getElementById('login-panel').transform = 'scale(1)';
}   
export function hide_login_panel() {
    document.getElementById('wispy-pwa-backdrop').style.display = 'none';
    document.getElementById('login-panel').style.display = 'none';
    document.getElementById('login-panel').style.opacity = 0;
    document.getElementById('login-panel').transform = 'scale(0)';
}